import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import "./assets/global.css"
import axios from 'axios'
import router from './router';
import '@/utils/axios.config.js';
import Toasted from 'vue-toasted'
import { Message } from 'element-ui';

Vue.prototype.$message = Message;
Vue.prototype.$axios = axios;
Vue.use(ElementUI)
Vue.use(Toasted, {
  position: 'top-right',
  duration: 5000
})
Vue.config.productionTip = false

// Vue.config.warnHandler = function(msg, vm, trace) {
//   if (msg.includes("Duplicate keys detected")) {
//     return;
//   }
//   console.error(`[Vue warn]: ${msg}\n${trace}`);
// }

new Vue({
  router, // 确保这里添加了 router
  render: h => h(App),
}).$mount('#app')