<template>
    <div class="registerBody">
      <div class="registerDiv">
        <div class="register-content">
          <h1 class="register-title">管理员注册</h1>
          <el-form :model="registerForm" label-width="100px" :rules="rules" ref="registerForm">
            <el-form-item label="用户名" prop="user_name">
              <el-input style="width: 200px" type="text" v-model="registerForm.user_name" autocomplete="off" size="small"></el-input>
            </el-form-item>
            <el-form-item label="名" prop="first_name">
              <el-input style="width: 200px" type="text" v-model="registerForm.first_name" autocomplete="off" size="small"></el-input>
            </el-form-item>
            <el-form-item label="姓" prop="last_name">
              <el-input style="width: 200px" type="text" v-model="registerForm.last_name" autocomplete="off" size="small"></el-input>
            </el-form-item>
            <el-form-item label="职位" prop="role">
              <el-select v-model="registerForm.role" placeholder="请选择职位">
                <el-option label="客服" value="100"></el-option>
                <el-option label="经理" value="200"></el-option>
                <el-option label="管理员" value="300"></el-option>
                <el-option label="超级管理员" value="400"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号码" prop="mobile_phone">
              <el-input style="width: 200px" type="text" v-model="registerForm.mobile_phone" autocomplete="off" size="small"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input style="width: 200px" type="text" v-model="registerForm.email" autocomplete="off" size="small"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" class="form-item">
                <el-input style="width: 200px" type="password" v-model="registerForm.password" autocomplete="off" size="small"></el-input>
            </el-form-item>
            <el-form-item class="register-button">
                <el-button type="primary" @click="register" :disabled="register_disabled">注册</el-button>
                <el-button type="text" @click="goBack">返回</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import axios from 'axios';
import instance from '@/utils/axios.config';
    
      
  
  export default {
    name: "AdminRegister",
    data() {
      return {
        register_disabled: false,
        registerForm: {
          user_name: '',
          first_name: '',
          last_name: '',
          mobile_phone: '',
          email: '',
          password: '',
          role: ''
        },
        
        rules: {
          user_name: [
              { required: true, message: '请输入用户名', trigger: 'blur' }
          ],
          first_name: [
              { required: true, message: '请输入名', trigger: 'blur' }
          ],
          last_name: [
              { required: true, message: '请输入姓', trigger: 'blur' }
          ],
          email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { type: 'email', message: '无效的邮箱', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[\s\r\n])[a-zA-Z\d!$,. _]{6,20}$/, message: '密码必须至少6位，最长20位，包含大小写字母和数字，只能包含!$,._特殊字符，不能包含空格或回车', trigger: 'blur' }
          ],
          mobile_phone: [
              { required: true, message: '请输入手机号码', trigger: 'blur' },
              { pattern: /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[8,9])\d{8}$/, message: '无效的电话号码, 正确的格式应为13、14、15、16、17、18、19开头的11位数字', trigger: 'blur' }
          ],
          role: [
              { required: true, message: '请选择职位', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
        goBack() {
                this.$router.push('/login');
        },
        async register() {
          this.$refs.registerForm.validate(async (valid) => {
            if (valid) {
              try {
                // If the form is valid, make the POST request
                const response = await instance.post(`/auth/sign-up`, this.registerForm);
                console.log(response.data);
                if (response.status === 200 || response.status === 201) {
                  // Handle successful registration here
                  this.$message.success('注册成功，请验证邮箱后登录...');
                  setTimeout(() => {
                    this.$router.push('/login');
                  }, 3000);
                } else {
                  // Handle other status codes here
                  this.$message.error('注册失败，请重新注册...');
                }
              } catch (error) {
                // Handle request errors here
                console.error('Error during registration:', error);
                this.$message.error(`注册失败，请重新注册... ${error.response.data.error}`);
              }
            } else {
              // If the form is not valid, show a message
              this.$message.error('请填写所有字段');
            }
          });
        }
      }
  }
</script>

<style scoped>
.registerBody {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #B3C0D1;
}
.registerDiv {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-top: -200px;
  margin-left: -250px;
  width: 450px;
  height: 600px;
  background: #fff;
  border-radius: 5%;
}
.register-title {
  margin: 20px 0;
  text-align: center;
}
.register-content {
  width: 400px;
  height: 250px;
  position: absolute;
  top: 25px;
  left: 25px;
}

.register-button {
  position: relative;
  top: 200%;
  left: 18%;
}
.form-item {
    margin-bottom: 30px; /* Adjust this value as needed */
}

.button-group {
    display: flex;
    justify-content: space-between;
}
</style>