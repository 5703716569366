import axios from 'axios';

// Create an axios instance
const instance = axios.create({
    baseURL: `${process.env.VUE_APP_HOST_BECKEND}:${process.env.VUE_APP_PORT_BECKEND}/api/v1`

  });
  
// Add a request interceptor
instance.interceptors.request.use((config) => {
    // Get the access token from local storage
    const token = localStorage.getItem('token');
    const refresh_token = localStorage.getItem('refresh_token');

    // If the token is present, set it in the Authorization header
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers['x-refresh-token'] = refresh_token;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use((response) => {
    
    if (response.status >= 200 && response.status < 300) {
   
        const token = response.headers['Authorization'] || response.headers['authorization'];
        const refresh_token = response.headers['x-refresh-token'];
        
        token && localStorage.setItem('token', token);
        refresh_token && localStorage.setItem('refresh_token', refresh_token);

        return response;
    } else {
        return Promise.reject(new Error(`Response returned with status code ${response.status}`));
    }
}, (error) => {
    const {status} = error.response;
    if (status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/login';
    }
    return Promise.reject(error);
});

export default instance;