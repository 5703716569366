<template>
  <div>
    <!-- 插入物品对话框 -->
    <el-form :model="newItem" ref="newItemForm" :rules="rules" @submit.native.prevent="insertItem" >
        <el-row :gutter="20">
            <el-col :span="4" >
                <el-form-item v-if="users.length > 0" label="选择用户" prop="user_id">
                    <el-select v-model="newItem.user_id" filterable clearable placeholder="请选择一个用户">
                        <el-option
                            v-for="user in users"
                            :key="user.user_id"
                            :label="user.user_id.toString()"
                            :value="user.user_id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="4" >
                <el-form-item prop="tracking_id">
                    <el-input v-model="newItem.tracking_id" clearable placeholder="请输入手动申报的跟踪ID(或快递单号)"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="4">
                <el-form-item label="物品类目" prop="item_category">
                    <el-select v-model="newItem.item_category" multiple placeholder="请选择">
                        <el-option label="生活家居" value="生活家居"></el-option>
                        <el-option label="时尚服饰" value="时尚服饰"></el-option>
                        <el-option label="鞋靴箱包" value="鞋靴箱包"></el-option>
                        <el-option label="宠物用品" value="宠物用品"></el-option>
                        <el-option label="精美饰品" value="精美饰品"></el-option>
                        <el-option label="个护彩妆" value="个护彩妆"></el-option>
                        <el-option label="营养保健" value="营养保健"></el-option>
                        <el-option label="母婴用品" value="母婴用品"></el-option>
                        <el-option label="电脑/办公" value="电脑/办公"></el-option>
                        <el-option label="手机/数码" value="手机/数码"></el-option>
                        <el-option label="食品" value="食品"></el-option>
                        <el-option label="工业/原料/设备" value="工业/原料/设备"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="4" >
                <el-form-item >
                    <el-input 
                        type="text"
                        v-model="newItem.item_description" 
                        clearable 
                        placeholder="请输入物品描述"
                        maxlength="50"
                        show-word-limit>
                    </el-input>
                </el-form-item>
            </el-col>  
            <el-col :span="4" >
                <el-form-item label="" prop="declared_value">
                    <el-input 
                        v-model="newItem.declared_value" 
                        clearable 
                        placeholder="请输入物品申报价值">
                    </el-input>
                </el-form-item>
            </el-col> 
            <el-col :span="2">
            <el-form-item>
                <el-checkbox v-model="newItem.is_sensitive_good">敏感货物</el-checkbox>
            </el-form-item>
            <!-- <el-form-item>
                <el-checkbox v-model="newItem.is_pickup_required">上门收件</el-checkbox>
            </el-form-item> -->
            <el-form-item>
                <el-checkbox v-model="newItem.is_pickup_required">是否网购</el-checkbox>
            </el-form-item>
            </el-col>     
            <el-col :span="2">
                <el-form-item>
                    <el-button type="success" icon="el-icon-edit" @click.native.prevent="showConfirmDialog">申报物品</el-button>
            </el-form-item>
            </el-col>
        </el-row>
    </el-form>

    <!-- 插入物品对话框 -->
    <el-dialog title="确认" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
        <span>确定要插入这个物品吗？</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="insertItem">确定</el-button>
        </span>
    </el-dialog>


      <!-- 输入和搜索区域 -->
    <div class="search-row">
        <el-button type="danger" icon="el-icon-delete" @click="clearSearchResult">清除</el-button>
        <el-button type="primary" icon="el-icon-search" @click="searchUserInputItems">搜索</el-button>

        <el-input v-model.lazy="search.user_id" placeholder="搜索用户ID" clearable></el-input>
        <el-input v-model.lazy="search.tracking_id" placeholder="搜索快递单号" clearable></el-input>
        <!-- <el-input v-model.lazy="search.user_input_items_id" placeholder="搜索用户输入物品ID" clearable></el-input> -->
        <el-select v-model.lazy="search.item_category" placeholder="选择物品类目" class="item-category-select" clearable>
            <el-option label="生活家居" value="生活家居"></el-option>
            <el-option label="时尚服饰" value="时尚服饰"></el-option>
            <el-option label="鞋靴箱包" value="鞋靴箱包"></el-option>
            <el-option label="宠物用品" value="宠物用品"></el-option>
            <el-option label="精美饰品" value="精美饰品"></el-option>
            <el-option label="个护彩妆" value="个护彩妆"></el-option>
            <el-option label="营养保健" value="营养保健"></el-option>
            <el-option label="母婴用品" value="母婴用品"></el-option>
            <el-option label="电脑/办公" value="电脑/办公"></el-option>
            <el-option label="手机/数码" value="手机/数码"></el-option>
            <el-option label="食品" value="食品"></el-option>
            <el-option label="工业/原料/设备" value="工业/原料/设备"></el-option>
        </el-select>
        <el-select v-model.lazy="search.is_sensitive_good" clearable placeholder="敏感物品" class="item-category-select" >
            <el-option label="是" value="true"></el-option>
            <el-option label="否" value="false"></el-option>
        </el-select>
        <el-select v-model.lazy="search.is_pickup_required" clearable placeholder="上门收件" class="item-category-select" >
            <el-option label="是" value="true"></el-option>
            <el-option label="否" value="false"></el-option>
        </el-select>
    </div>
    <div class="table-container">
        <!-- 表格展示数据 -->
        <el-table ref="multipleTableRef"  @selection-change="handleSelectionChange" :data="filteredDatatable" :header-cell-style="{background:'#f2f5fc', color:'#555'}" height="70vh" border>
            <!-- 列定义 -->
            <el-table-column type="selection" width="55" />
            <el-table-column type="index" label="序号" fixed/>
            <el-table-column prop="user_id" label="用户ID" fixed></el-table-column>
            <el-table-column prop="tracking_id" label="快递单号" fixed></el-table-column>
            <!-- <el-table-column prop="user_input_items_id" label="物品ID" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.user_input_items_id }}
                </template>
            </el-table-column> -->
            <el-table-column prop="item_category" label="物品类目" flex="1"></el-table-column>
            <el-table-column prop="item_description" label="物品描述" flex="1"></el-table-column>
            <el-table-column prop="is_sensitive_good" label="是否敏感物品" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.is_sensitive_good === true ? '✅' : '❌' }}
                </template>
            </el-table-column>
            <el-table-column prop="is_online" label="是否网购" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.is_online === true ? '✅' : '❌' }}
                </template>
            </el-table-column>
            <el-table-column prop="is_pickup_required" label="是否上门收件" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.is_pickup_required === true ? '✅' : '❌' }}
                </template>
            </el-table-column>
            <el-table-column prop="declared_value" label="申报价值" flex="1"></el-table-column>
            <el-table-column prop="item_status" label="物品状态" flex="1"></el-table-column>
            <el-table-column prop="pickup_address" label="上门收件地址" flex="1"></el-table-column>
            <el-table-column prop="sender_address_id" label="上门收件收件id" flex="1"></el-table-column>
            <el-table-column prop="updated_at" label="更新时间" flex="1">
                <template slot-scope="scope">
                    {{ (scope.row.updated_at) }}
                </template>
            </el-table-column>
            <el-table-column prop="operate" label="操作">
                <template slot-scope="scope">
                    <el-button size='small' type='warning' icon="el-icon-box" @click="insertNewItem(scope.row)">入库</el-button>
                    <el-button size='small' type='success' icon="el-icon-edit" @click="updateItem(scope.row)">编辑</el-button>
                    <el-button size='small' type='danger' icon="el-icon-delete" @click="deleteItem(scope.row.user_input_items_id)" :disabled="role < 200">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div style="margin-top: 20px">
        <el-button @click="scanAllItems()">全部入库</el-button>
        <el-button @click="clearSelection()">取消全选</el-button>
    </div>
      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
      <!-- 弹窗编辑物品 -->
      <el-dialog title="编辑物品" :visible.sync="showEditDialog" :close-on-click-modal="false">
            <el-form :model="editingItem" ref="editingItemForm" :rules="editingRules">
                <el-form-item label="跟踪ID" prop="tracking_id" >
                    <el-input v-model="editingItem.tracking_id"></el-input>
                </el-form-item>
                <el-form-item label="物品类目" prop="item_category" >
                    <el-select v-model="editingItem.item_category" multiple placeholder="请选择">
                        <el-option label="生活家居" value="生活家居"></el-option>
                        <el-option label="时尚服饰" value="时尚服饰"></el-option>
                        <el-option label="鞋靴箱包" value="鞋靴箱包"></el-option>
                        <el-option label="宠物用品" value="宠物用品"></el-option>
                        <el-option label="精美饰品" value="精美饰品"></el-option>
                        <el-option label="个护彩妆" value="个护彩妆"></el-option>
                        <el-option label="营养保健" value="营养保健"></el-option>
                        <el-option label="母婴用品" value="母婴用品"></el-option>
                        <el-option label="电脑/办公" value="电脑/办公"></el-option>
                        <el-option label="手机/数码" value="手机/数码"></el-option>
                        <el-option label="食品" value="食品"></el-option>
                        <el-option label="工业/原料/设备" value="工业/原料/设备"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="item_description" label="物品描述">
                    <el-input v-model="editingItem.item_description"></el-input>
                </el-form-item>
                <el-form-item label="敏感物品">
                    <el-checkbox v-model="editingItem.is_sensitive_good"></el-checkbox>
                </el-form-item>
                <el-form-item label="是否网购">
                    <el-checkbox v-model="editingItem.is_online" ></el-checkbox>
                </el-form-item>
                <el-form-item prop="declared_value" label="申报价值">
                    <el-input v-model="editingItem.declared_value"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showEditDialog = false">取消</el-button>
                <el-button type="primary" @click="submitUpdate">保存</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import instance from '@/utils/axios.config';
import { formatLocalDate } from '@/utils/time_utils';
import {insertScannedItems} from '@/utils/scanned_items.js'
import { getRole } from '@/utils/auth';


export default {
  name: "UserInputItemsTable",
  data() {
      return {
        users: [],
        query: '', // 新增:用户输入的查询
        dialogVisible: false,
        originalDatatable: [],
       limit: 10,
        page: 1,
        total: 0,
        role: null,
        multipleSelection: [],
        search: {
            user_input_items_id: '',
            user_id: '',
            tracking_id: '',
            item_category: null,
            is_sensitive_good: null,
            is_pickup_required: null,
            is_online: null,
        },
        editingItem: {
            user_id: '',
            tracking_id: '',
            item_category: [],
            item_description: '',
            is_sensitive_good: '' ,
            is_pickup_required: '',
            is_online: '',
            sender_address_id: '',
        },
        newItem: {
            user_id: '',
            tracking_id: '',
            item_category: [],
            item_description: '',
            is_online: '',
            is_sensitive_good: '',
            is_pickup_required: false,
            declared_value: ''
        },
        rules: {
                user_id: [
                    { required: true, message: '请选择一个用户', trigger: 'change' }
                ],
                declared_value: [
                    { required: true, message: '请输入物品申报价值', trigger: 'blur' },
                    { validator: this.checkNumber, trigger: 'blur' }
                ],
                tracking_id: [
                    { required: true, message: '快递单号不能为空', trigger: 'blur' }
                ],
                item_category: [
                    { required: true, message: '请选择至少一个类目', trigger: 'change' }
                ]
        },
        editingRules: {
                declared_value: [
                    { required: true, message: '请输入物品申报价值', trigger: 'blur' },
                    { validator: this.checkNumber, trigger: 'blur' }
                ],
                tracking_id: [
                    { required: true, message: '快递单号不能为空', trigger: 'blur' }
                ],
                item_category: [
                    { required: true, message: '请选择至少一个类目', trigger: 'change' }
                ]
        },

        showEditDialog: false,
      };
    },
    computed: {
        filteredDatatable() {
            let result = this.originalDatatable;
            result = result.filter(item => item.item_status !== process.env.VUE_APP_ITEM_STATUS_SCANNED);
            console.log("搜索条件:", this.search);
            Object.keys(this.search).forEach(key => {
                if (this.search[key]) {
                    let searchString = this.search[key].replace('*', '.*'); // 将 * 替换为 .*
                    let regex;
                    if (key === 'item_category') {
                        regex = new RegExp(searchString, 'i'); // 对于 'item_category'，创建不从头开始的正则表达式
                    } else {
                        regex = new RegExp('^' + searchString, 'i'); // 对于其他字段，创建从头开始的正则表达式
                    }
                    result = result.filter(user => regex.test(String(user[key])));
                }
                else if (typeof this.search[key] === 'boolean') {
                    // Filter based on the checkbox value
                    result = result.filter(order => order[key] === this.search[key]);
                }
            });
            return result;
        }
    },
    watch: {
            search: {
                handler() {
                    // 检查 search 对象的所有属性是否都为空
                    const isEmpty = Object.values(this.search).every(value => value === '');
                    if (isEmpty) {
                        this.loadData(); // 如果所有属性都为空，调用 getload 方法
                    }
                },
                deep: true // 深度观察 search 对象的所有属性
            }
    },     
  methods: {
    formatLocalDate,
    getRole,
    clearSelection() {
        this.$refs.multipleTableRef.clearSelection();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    scanAllItems() {
        this.multipleSelection.forEach(row => {
            this.insertNewItem(row);
        });
    },

    async searchUserInputItems() {
        try {
            const searchCopy = JSON.parse(JSON.stringify(this.search));
            const response = await instance.get(`/items/user_input_items/items`, {
                params: {
                    user_input_items_id: searchCopy.user_input_items_id,
                    user_id: searchCopy.user_id,
                    tracking_id: searchCopy.tracking_id,
                    item_category: searchCopy.item_category,
                    is_sensitive_good: searchCopy.is_sensitive_good,
                    is_pickup_required: searchCopy.is_pickup_required
                }
            });
            if (response.status === 200) {
                this.$message.success(`搜索成功, 返回 ${response.data.data.length} 条结果`)
                this.originalDatatable = response.data.data;
                this.total = response.data.data.length === 0 ? 0 : parseInt(response.data.data[0].number_of_rows);
            }
            
        } catch (error) {
            this.$message({
                message: `搜索失败: ${error.response.data.error} 请清除重新再试`,
                type: 'error'
            });

            // 处理错误，例如提示用户重新登录
            this.originalDatatable = [];
        }
    },
    clearSearchResult() {
        this.search = {
            user_input_items_id: '',
            user_id: '',
            tracking_id: '',
            item_category: null,
            is_sensitive_good: null
        };
        this.page = 1;
        this.loadData();
    }, 
      

    async loadData() {
        try {
            const response = await instance.get(`/items/user_input_items/all`, {
                params: { limit: this.limit, page: this.page }
            });
            this.originalDatatable = response.data.data;
            this.total = response.data.total;
        } catch (error) {
            console.error("加载数据出错:", error);
        }
    },

    showConfirmDialog(e) {
        e.preventDefault();
        this.dialogVisible = true;
    },
    async insertItem() {
        // 验证表单
        this.$refs.newItemForm.validate(async (valid) => {
            if (!valid) {
                this.$message.error('请检查输入后重试');
                return;
            }

            // 复制一份 editingItem，因为下面要修改它
            let newItem = JSON.parse(JSON.stringify(this.newItem));
            newItem.item_category = JSON.stringify(newItem.item_category);
            this.dialogVisible = false;

            try {
                const response = await instance.post(`/items/user_input_items/insert`, newItem);
                console.log("插入物品成功:", response.status);
                if (response.status === 200) {
                    this.$message.success("物品插入成功");
                }
                this.newItem = { user_id: '', tracking_id: '', item_category: [] }; // 重置表单
                await this.loadData(); // 重新加载数
            } catch (error) {
                this.$message.error("插入物品出错:", error.response.data.error);
            }
        });
    },
    // 打开弹窗编辑物品
    updateItem(item) {
        this.editingItem = JSON.parse(JSON.stringify(item));
        // 将 item.item_category 转换为一个 JavaScript 数组
        this.editingItem.item_category = JSON.parse(item.item_category);
        this.editingItem.is_online = item.is_online;
        this.editingItem.sender_address_id = item.sender_address_id;
        this.showEditDialog = true;
    },
    // 提交更新
    async submitUpdate() {
        this.$refs.editingItemForm.validate(async (valid) => {
            if (!valid) {
                this.$message.error('请检查输入后重试');
                return;
            }
            // 复制一份 editingItem，因为下面要修改它
            let editingItem = JSON.parse(JSON.stringify(this.editingItem));
            editingItem.item_category = JSON.stringify(editingItem.item_category);
            console.log("更新物品:", editingItem);

            try {
                await instance.post(`/items/user_input_items/update/${editingItem.user_input_items_id}`, {
                    tracking_id: editingItem.tracking_id,
                    item_category: editingItem.item_category,
                    item_description: editingItem.item_description,
                    is_sensitive_good: editingItem.is_sensitive_good,
                    declared_value: editingItem.declared_value,
                    is_pickup_required: editingItem.is_pickup_required,
                    is_online: editingItem.is_online,
                    sender_address_id: editingItem.sender_address_id
                });
                this.$message.success("更新物品成功");
                // 重置表单
                this.editingItem = { user_id: '', tracking_id: '', item_category: [], item_description: '', is_sensitive_good: false}; 
                this.showEditDialog = false;
                // 重新加载数据
                this.loadData();   
            } catch (error) {
                this.$message.error("更新物品出错:", error.response.data.error);
            }
        });
    },

    async deleteItem(userInputItemsId) {
        this.$confirm('确定要删除这个物品吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(async () => {
            console.log("删除物品:", userInputItemsId);
            try {
                await instance.delete(`/items/user_input_items/delete/${userInputItemsId}`);
                this.$message.success(`删除物品${userInputItemsId}成功`);
                this.loadData(); // 重新加载数据
            } catch (error) {
                this.$message.error(`删除物品${userInputItemsId}出错: ${error.message}`);
            }
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '取消删除'
            });      
        });
    },

    async getAllUsers() {
        this.loading = true;
        try {
            const response = await instance.get(`/users/users/all`);
            if (Array.isArray(response.data.data)) {
                this.users = response.data.data;
            } else {
                console.error('Unexpected response format:', response.data);
            }
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }
        this.loading = false;
    },
    async insertNewItem(user_input_item) {

              // 复制一份 newItem 对象
              let item = {}
              // 插入搜索的 tracking_id, 和 user_id
              item.tracking_id = user_input_item.tracking_id;
              item.user_id = parseInt(user_input_item.user_id);
              // 检查所有必需字段是否已填写
              if (!item.tracking_id || !item.user_id ) {
                this.$message.error("tracking_id 和 user_id 不能为空");
                return;
              }
              // 准备请求体
              const body = {
                tracking_id: item.tracking_id, 
                user_id: item.user_id,
                weight: parseFloat(1.0),
                length: parseFloat(1.0),
                width: parseFloat(1.0),
                height: parseFloat(1.0),
                volume: parseFloat(1.0),
                whs_id: null,
                machine_id: null,
                item_status: process.env.VUE_APP_ITEM_STATUS_SCANNED
              };
              // 发送请求
              try {
                await insertScannedItems({itemData: body});
                this.$message.success("入库成功");
                this.loadData(); 

              } catch (error) {
                console.error("入库失败", error);
                this.$message.error(`入库失败:  ${error.message}`);
              }
        },
    handleSizeChange(val) {
        this.limit = val;
        this.page = 1; // Reset to first page when page size changes
        this.loadData();
    },
    handleCurrentChange(val) {
        this.page = val;
        this.loadData();
    },

    checkNumber(rule, value, callback) {
            if (isNaN(value)) {
                callback(new Error('物品申报价值必须为数字'));
            } else if (value <= 0) {
                callback(new Error('物品申报价值必须为大于0的数字'));
            } else {
                callback();
            }
    }
    
  },

    beforeMount() {
        this.loadData();
    },

    created(){
                // Get role from localStorage when the component is created
                const { role, currentUser } = getRole();
                this.role = role;
                this.currentUser = currentUser;
    },

    mounted() {
        this.getAllUsers();
    },
}
</script>

<style scoped>
.search-row {
    display: flex;
    flex-wrap: nowrap; /* 阻止元素换行 */
    align-items: center;
    gap: 10px; /* 为元素添加间隙 */
    margin-bottom: 20px;
    max-width: 100%; /* 限制宽度为屏幕的一半 */
}

.el-select, .el-date-picker {
  flex-grow: 1;
  width: 200px;
}
.item-category-select {
    width: 100%; /* 或者你想要的任何宽度 */
}

.table-container {
    max-height: 70vh;  /* 你可以根据需要调整这个值 */
    overflow-y: auto;
    overflow-x: auto; /* 显示横向滚动条 */
}

.el-table-column {
  text-align: center;
}

.el-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .search-row {
    flex-direction: column;
  }

  .el-input, .el-select, .el-date-picker {
    max-width: none;
  }
}

</style>