<template>
    <div>

     <div class = "search-row">
        <el-button type="success" clearable icon="el-icon-edit" @click="showEditDialog = true; is_editing = false" :disabled="role < 300">添加新图片</el-button>

      </div>

    
        <div class="table-container"> 
            <el-table :data="originalDatatable"
                :header-cell-style="{background:'#f2f5fc', color:'#555'}" height="70vh"
                border
            >
            <el-table-column type="index" label="序号" fixed/>
            <el-table-column prop="_id" label="记录id" flex="1" ></el-table-column>
            <el-table-column prop="image_name" label="图片名称" flex="1"></el-table-column>
            <el-table-column prop="url" label="URL" flex="1" ></el-table-column>
            <el-table-column prop="description" label="图片描述" flex="1"></el-table-column>
            <el-table-column prop="key" label="COS路径" flex="1"></el-table-column>
            <el-table-column prop="updated_at" label="更新时间" flex="1" :formatter="row => (row.updated_at)"></el-table-column>
            <el-table-column prop="created_at" label="创建时间" flex="1" :formatter="row => (row.created_at)"></el-table-column>

            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button size="small" type="success" icon="el-icon-edit" @click="editImages(scope.row)" :disabled="role < 300">编辑</el-button>
                    <el-button size="small" type="danger" icon="el-icon-delete" @click="confirmDeletion(scope.row)" :disabled="role < 300">删除</el-button>
                </template>
            </el-table-column>
            </el-table>
        </div>
        
        
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <el-dialog title="上传图片" :visible.sync="showEditDialog" :close-on-click-modal="false">
            <el-form :model="editingImage" :rules="rules" ref="editingImageForm">
                <el-form-item label="选择本地图片">
 
                    <el-upload
                        ref="upload"
                        :action="backendUrl + '/api/v1/documents/upload/images'"
                        accept="image/jpeg,mage/jpg,image/png,image/gif,image/bmp,image/webp,image/svg+xml"
                        name="image"   
                        :file-list="fileList" 
                        list-type="picture-card"
                        :headers="authHeaders"
                        :show-file-list="true"
                        :on-success="handleUploadSuccess"
                        :on-error="handleUploadError"
                        :before-upload="beforeImageUpload"
                        :on-change="handleFileChange"
                        :auto-upload="false" 
                        :limit="1"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :on-exceed="() => this.$message.warning('一次只能选择1个文件上传')">  <!-- 添加这一行 -->
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success" @click="uploadImage" :disabled="uploadDisabled">上传</el-button>
                        <div>已选择 {{ fileList.length }} / 1 </div>
                        <!-- 自定义文件列表 -->
                    </el-upload>
                </el-form-item>
                
                <el-form-item label="记录id (可选,如果不填则系统自动生成. 输入不能重复)" prop="_id">
                    <el-input v-model="editingImage._id" readonly v-if="is_editing"></el-input>
                    <el-input v-model="editingImage._id" v-else></el-input>
                </el-form-item>
                <el-form-item label="图片名称" prop="image_name">
                    <el-input v-model="editingImage.image_name" maxlength="50" show-word-limit readonly v-if="is_editing"></el-input>
                    <el-input v-model="editingImage.image_name" maxlength="50" show-word-limit v-else></el-input>
                </el-form-item>
                <el-form-item label="URL" prop="url">
                    <el-input v-model="editingImage.url"></el-input>
                </el-form-item>
                <el-form-item label="COS路径" prop="key">
                    <el-input v-model="editingImage.key"></el-input>
                </el-form-item>
                <el-form-item label="图片描述" prop="description">
                    <el-input v-model="editingImage.description" type="textarea" maxlength="100" show-word-limit></el-input>
                </el-form-item>


            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetEditingImage()">取消</el-button>
                <el-button type="primary" @click="is_editing ? updateImage() : insertNewImage()">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import { formatLocalDate } from '@/utils/time_utils';
    import { getCollections, updateRecord, deleteRecord, insertRecord } from '@/utils/cloudebase/images';
    import {getRole} from '@/utils/auth';
    export default{
        name:"ImagesTable",
        data() {

            return {
                originalDatatable: [],
                collection_name: process.env.VUE_APP_COLLECTION_NAME_IMAGES,
                backendUrl: `${process.env.VUE_APP_HOST_BECKEND}:${process.env.VUE_APP_PORT_BECKEND}`,
                limit: 10,
                page:1,
                total:0,
                users: [],
                role: null,
                maxRole: null,
                currentUser: null,     
                editingImage: {
                    _id: null,
                    created_at: null,
                    url: null,
                    key: null,
                    image_name: null,
                    description: null
                },
                search:{
                },
                roleMap: {
                    '超级管理员': 400, 
                    '管理员': 300,
                    '经理': 200,
                    '客服': 100,
                // 其他角色...
                },                
                showEditDialog: false,
                is_editing: false,
                isUploading: false,  // 添加这个状态变量
                uploadCount: 0,  // 用来保存上传的次数
                isFileSelected: false,  // 添加这个数据属性
                fileList: [],  // 添加这个数据属性
                rules: {
                    url: [
                        { required: true, message: 'URL不能为空', trigger: 'blur' },
                    ],
                    key: [
                        { required: true, message: 'COS路径不能为空', trigger: 'blur' },
                    ],
                    image_name: [
                        { required: true, message: '图片名称不能为空', trigger: 'blur' },
                    ],
                    description: [
                        { required: true, message: '图片描述不能为空', trigger: 'blur' },
                    ],
                }

            };
        },
        async created() {
            // Get role from localStorage when the component is created
            const { role, currentUser } = getRole();
            this.role = role;
            this.currentUser = currentUser;
            // Wait for a second before loading data
            setTimeout(() => {
                this.loadData();
                this.$message.info('加载数据中，请稍候...')
            }, 1000);
        },
    
  
        methods:{
            formatLocalDate,
            getCollections,
            getRole,
            async loadData() {
                try {
                    console.log(this.collection_name, this.limit, this.page)
                    const response = await getCollections({collection_name: this.collection_name, limit: this.limit, page: this.page});
                    
                    if (response.status === 200) {
                        this.originalDatatable = response.records.data;
                        this.$message.success(`成功加载数据，返回 ${response.records.data.length} 条信息`);
                        this.total = response.records.data.length;
                    }
                    
                } catch (error) {
                    console.error(error);
                    this.$message.error(`加载数据失败: ${error.message}`);
                }
            },
            editImages(Image){
                // 存储要编辑的物品信息
                this.editingImage = JSON.parse(JSON.stringify(Image));
                this.is_editing = true;
                // 打开模态窗口或导航到编辑页面
                this.showEditDialog = true;
            },
            async insertNewImage() {
                // 验证表单
                this.$refs['editingImageForm'].validate(async (valid) => {
                    if (!valid) {
                        this.$message.error('请检查输入是否正确');
                        return;
                    }
                    // 复制一个新的对象，避免直接修改原始对象
                    const Image = JSON.parse(JSON.stringify(this.editingImage));
                    try {
                        // 更新用户信息
                        const response = await insertRecord({ collection_name: this.collection_name, _id: Image._id, new_record: Image });
                        // 处理响应
                        if (response.status === 200) {
                            // 更新成功操作
                            this.$message({
                                message: `成功创建记录, _id: ${response.data.res.id}`,
                                type: 'success'
                            });
                            this.loadData();
                            this.showEditDialog = false;
                            this.resetEditingImage();
                        }
                    } catch (error) {
                        // 错误处理
                        console.error(error);
                        // 根据error.response.status显示相应的错误信息
                        this.$message({
                            message: `更新失败: ${error}: ${error.response.data.error}`,
                            type: 'error'
                        });
                    }
                });
            },
            async updateImage(){
                // 复制一个新的对象，避免直接修改原始对象
                const Image = JSON.parse(JSON.stringify(this.editingImage));
                try {
                    // 更新用户信息
                    const response = await updateRecord({ collection_name: this.collection_name, _id: Image._id, new_record: Image});
                    // 处理响应
                    if (response.status === 200) {
                        // 更新成功操作
                        this.$message({
                            message: `成功更新图片${this.editingImage._id}`,
                            type: 'success'
                        });
                        this.loadData();
                        this.showEditDialog = false;
                        this.resetEditingImage();
                    }
                } catch (error) {
                    // 错误处理
                    console.error(error);
                    // 根据error.response.status显示相应的错误信息
                    this.$message({
                        message: `更新失败: ${error}: ${error.response.data.error}`,
                        type: 'error'
                    });
                }
            },
          
            confirmDeletion(image) {
                // Show a confirmation dialog
                this.$confirm('确定要删除这个用户吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    // If the user confirmed the deletion, delete the order
                    this.deleteImage({image_id:image._id, key:image.key});
                })
                .catch(() => {
                    // If the user cancelled the deletion, do nothing
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });
            },

            async deleteImage({image_id, key}){
                try {
                    const response = await deleteRecord({collection_name: this.collection_name, _id: image_id, key: key});

                    // 处理响应
                    if (response.data.status === 200) {
                        // 更新成功操作
                        this.$message({
                            message: `成功删除图片${image_id}`,
                            type: 'success'
                        });
                        this.loadData();
                    }
                } catch (error) {
                    // 错误处理
                    console.error(error);
                    // 根据error.response.status显示相应的错误信息
                    this.$message({
                        message: `删除失败: ${error}: ${error.response.data.error}`,
                        type: 'error'
                    });
                }
            },

            handleSizeChange(val) {
                this.limit = val;
                this.page = 1; // Reset to first page when page size changes
                this.loadData();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.loadData();
            },
            resetEditingImage() {
                this.editingImage = {
                    _id: null,
                    created_at: null,
                    image_name: null,
                    key: null,
                    url: null,
                    description: null
                };
                this.$refs.upload.clearFiles();
                // Close the editing window
                this.showEditDialog = false;
                this.fileList = [];
                this.$message.info('已重置编辑框');
            },
            noNumberValidator(rule, value, callback) {
                if (/\d/.test(value)) {
                    callback(new Error('不能包含数字'));
                } else {
                    callback();
                }
            },
            beforeImageUpload(file) {
                this.isFileSelected = true;  // 用户已经选择了文件
                console.log('beforeImageUpload file:', file);
                console.log(`file: ${file}, type: ${typeof file}`);
                console.log(`file.raw: ${file.raw}, type: ${typeof file.raw}`);
                const isImage = file.type.startsWith('image/');
                const isAcceptedType = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml'].includes(file.type);
                const isNameNotEmpty = file.name.trim() !== '';

                if (!isImage) {
                    this.$message.error('上传的文件不是图片');
                } else if (!isAcceptedType) {
                    this.$message.error('上传的图片格式不符合要求');
                } else if (!isNameNotEmpty) {
                    this.$message.error('文件名不能为空');
                } 

                return isImage && isAcceptedType && isNameNotEmpty;
            },
            handleFileChange(file, fileList) {
                if (fileList.length > 1){
                    fileList.splice(0, 1);
                }
                this.fileList = fileList;
            },
            handleUploadSuccess(response) {
                this.$message.success(`上传图片成功, url: ${response.url}`);
                // this.$refs.upload.clearFiles();
                this.isUploading = false;  // 上传成功后，将 isUploading 设置为 false
                this.isFileSelected = false;  // 上传成功后，将 isFileSelected 设置为 false
                this.editingImage.url = response.url;
                this.editingImage.key = response.key
                
                // Get the file name from the URL
                const fileNameWithExtension = response.url.split('/').pop();
                // Remove the extension
                const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.');
                // Set the image name
                if (this.is_editing === false){
                    this.editingImage.image_name = fileName;
                }         

            },
            handleUploadError(err) {
                this.$message.error(`上传失败: ${err.message}`);
                // this.$refs.upload.clearFiles();
                this.isUploading = false;  // 上传失败后，将 isUploading 设置为 false
                this.fileList = []
            },
            handleRemove() {
                this.fileList = []
                this.isUploading = false;  // 上传失败后，将 isUploading 设置为 false
            },
            handlePreview(file) {
                window.open(file.url);
            },


            uploadImage() {
                this.$refs.upload.submit();  // 手动开始上传
                this.isUploading = true;  // 开始上传，将 isUploading 设置为 true
            }  
        },

        computed: {
            authHeaders() {
                return {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'x-refresh-token': localStorage.getItem('refresh_token')
                };
            },
            uploadDisabled() {
                return this.isUploading || this.fileList.length === 0;  // 如果正在上传，或者没有选择文件，那么上传按钮将被禁用
            }
        },
        
        
    }

</script>

<style scoped>

.search-row {
    display: flex;
    flex-wrap: nowrap; /* 阻止元素换行 */
    align-items: center;
    gap: 10px; /* 为元素添加间隙 */
    margin-bottom: 20px;
    max-width: 100%; /* 限制宽度为屏幕的一半 */
}

.el-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.table-container {
    max-height: 80vh;  /* 你可以根据需要调整这个值 */
    overflow-x: auto; /* 显示横向滚动条 */
    overflow-y: auto; 
}

.el-input__inner[readonly] {
    background-color: #f5f7fa;
    color: #c0c4cc;
}
</style>