<template>
    <div class="loginBody">
        <div class="loginDiv">
            <div class="login-content">
                <h1 class="login-title">用户登录</h1>
                <el-form :model="loginForm" label-width="100px"
                         :rules="rules" ref="loginForm">
                    <el-form-item label="账号" prop="user_name">
                        <el-input style="width: 200px" type="text" v-model="loginForm.user_name"
                                  autocomplete="off" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input style="width: 200px" type="password" v-model="loginForm.password"
                                  show-password autocomplete="off" size="small" @keyup.enter.native="confirm"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click.prevent="confirm" :disabled="confirm_disabled">确定</el-button>
                        <el-button type="text" @click="goToRegister">注册</el-button>
                        <el-button type="text" @click="goToForgetPassword">忘记密码</el-button>
                        
                        
                    </el-form-item>

                </el-form>
            </div>
        </div>
    </div>
</template>


<script>
// // import axios from 'axios';
import instance from '@/utils/axios.config';
  
const HOST_FRONTEND = process.env.VUE_APP_HOST_FRONTEND;
const HOST_BECKEND = process.env.VUE_APP_HOST_BECKEND; 

console.log('HOST_BECKEND', HOST_BECKEND);
console.log('HOST_FRONTEND', HOST_FRONTEND);

    export default {
        name: "AdminLogin",
        data(){
            return{
                confirm_disabled:false,
                loginForm:{
                    user_name:'',
                    password:''
                },
                rules:{
                    user_name: [
                        { required: true, message: '请输入账号', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输密码', trigger: 'blur' }
                    ],
                }
            }
        },
        methods: {
            goToRegister() {
                this.$router.push('/AdminRegister');
            },
            goToForgetPassword() {
                this.$router.push('/ForgetPassword');
            },
            async confirm() {
                this.$refs.loginForm.validate(async (valid) => {
                    if (!valid) {
                        this.$message.error('请检查您的输入');
                        return;
                    }
                    try {
                        const response = await instance.post(`/auth/login`, {
                            user_name: this.loginForm.user_name,
                            password: this.loginForm.password
                        }, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });

                        if (response.status === 200) {
                            localStorage.setItem('user_name', response.data.user_name);
                            localStorage.setItem('email', response.data.email);
                            localStorage.setItem('role', response.data.role);
                            console.log('Logged in as:', response.data.user_name, response.data.role);
                            this.$nextTick(() => {
                                this.$router.push('/home');
                            });
                        }  
                    } catch (error) {
                        if (error.response && error.response.status === 401) {
                            this.$message({ message: 'Incorrect credentials!', type: 'error' });
                            return;
                        } else if (error.message.includes('net::ERR_CONNECTION_REFUSED')) {
                            this.$message({ message: 'Cannot connect to the server', type: 'error'});
                            return;
                        } else {
                            this.$message({ message: error.response.data.error, type: 'error' });
                            return;
                        }
                    }
                });
            }
    }
}
</script>

<style scoped>
    .loginBody {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #B3C0D1;
    }
    .loginDiv {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -200px;
        margin-left: -250px;
        width: 450px;
        height: 330px;
        background: #fff;
        border-radius: 5%;

    }
    .login-title {
        margin: 20px 0;
        text-align: center;
    }
    .login-content {
        width: 400px;
        height: 250px;
        position: absolute;
        top: 25px;
        left: 25px;
    }
</style>