<template>
    <div>
      <!-- 输入和搜索区域 -->
      <div class="search-fields">
        <el-button type="danger" icon="el-icon-delete" @click="clearSearchResult">清除搜索</el-button>
        <el-button type="primary" icon="el-icon-search" @click="searchOrder">搜索</el-button>
        <el-input v-model.lazy="search.order_id" placeholder="搜索订单ID" clearable></el-input>
        <el-input v-model.lazy="search.alias_order_id" placeholder="搜索运单号" clearable></el-input>
        <el-input v-model.lazy="search.user_id" placeholder="搜索用户ID" clearable ></el-input>
        <el-date-picker v-model.lazy="search.created_from" placeholder="创建日期从" type="date"  class="date-picker-wide" clearable></el-date-picker>
        <el-date-picker v-model.lazy="search.created_to" placeholder="创建日期到" type="date" class="date-picker-wide" clearable></el-date-picker>
    </div>  
    <div class="table-container">
      <!-- 表格展示数据 -->
      <el-table :data="filteredDatatable" :header-cell-style="{background:'#f2f5fc', color:'#555'}" height="70vh" border>
        <!-- 列定义 -->
        <el-table-column type="index" label="序号" fixed/>
        <el-table-column prop="order_id" label="订单ID" flex="1"></el-table-column>
        <el-table-column prop="alias_order_id" label="运单号" fixed></el-table-column>
        <el-table-column prop="user_id" label="用户ID" fixed></el-table-column>
        <el-table-column prop="length" label="长(m)" flex="1"></el-table-column>
        <el-table-column prop="width" label="宽(m)" flex="1"></el-table-column>
        <el-table-column prop="height" label="高(m)" flex="1"></el-table-column>
        <el-table-column prop="total_weight" label="总重量(kg)" flex="1"></el-table-column>
        <el-table-column prop="total_volume" label="总体积(m3)" flex="1"></el-table-column>
        <el-table-column prop="number_of_boxes" label="合箱数" flex="1"></el-table-column>
        <el-table-column prop="price" label="价格" flex="1"></el-table-column>
        <el-table-column prop="order_status" label="订单状态" flex="1"></el-table-column>
        <el-table-column prop="updated_at" label="更新时间" flex="1" :formatter="row => (row.updated_at)"></el-table-column>
        <el-table-column prop="created_at" label="创建时间" flex="1" :formatter="row => (row.created_at)"></el-table-column>
        <el-table-column prop="sender_address" label="寄件人地址" flex="1"></el-table-column>
        <el-table-column prop="sender_mobile" label="寄件人电话" flex="1"></el-table-column>        
        <el-table-column prop="receiver" label="收件人" flex="1"></el-table-column>
        <el-table-column prop="receiver_address" label="收件人地址" flex="1"></el-table-column>
        <el-table-column prop="receiver_state" label="收件人州" flex="1"></el-table-column>
        <el-table-column prop="receiver_postcode" label="收件人邮编" flex="1"></el-table-column>
        <el-table-column prop="receiver_mobile" label="收件人手机" flex="1"></el-table-column>
        <el-table-column prop="receiver_email" label="收件人邮箱" flex="1"></el-table-column>
        <el-table-column prop="shipping_method" label="运输方式" flex="1"></el-table-column>
        <el-table-column prop="delivery_method" label="送货方式" flex="1"></el-table-column>
      </el-table>
    </div>  
      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>

    </div>
  </template>
  
  <script>

  import { formatLocalDate, convertToMelbourneTime } from '@/utils/time_utils';
  import { searchOrders } from '../../utils/orders';


  export default {
    name: "CancelledOrdersTable",
    data() {
      return {
        search: {
          order_id:  null,
          alias_order_id:  null,
          user_id:  null,
          created_from:  null,
          created_to:  null,
          limit: this.limit,
          page: this.page
        },
        ordersData: [],
       limit: 10,
        page: 1,
        total: 0,
        order_status: process.env.VUE_APP_ORDER_STATUS_CANCELLED,
      };
    },
    async created() {
      this.loadData();
    },

    methods: {
      formatLocalDate,
      convertToMelbourneTime,
      async loadData() {
          try{
              const response = await searchOrders({ page: this.page, limit: this.limit, order_status: this.order_status});
            //   console.log("response",response)    
              if (response.status === 200) {
                  this.ordersData = response.data;
                  this.total = response.data.length === 0 ? 0 : parseInt(response.data[0].number_of_rows);
              }

          }catch (error) {
              this.$message.error(`获取订单失败! ${error.response.data.error}`);
          }   
      },
      async searchOrder() {
          try {
              // 创建一个搜索参数对象，包含所有可能的搜索参数
              let searchParams = JSON.parse(JSON.stringify(this.search));

              // 移除空的搜索参数
              Object.keys(searchParams).forEach(key => {
                  if (searchParams[key] === '') {
                      delete searchParams[key];
                  }
              });
              console.log("searchParams",searchParams)

              // 使用 searchOrders 函数发送请求
              const response = await searchOrders({
                    ... searchParams,                    
                    limit : this.limit,
                    page: this.page
                });
                if (response.status === 200) {
                    this.ordersData = response.data;
                    this.total = response.data.length === 0 ? 0 : parseInt(response.data[0].number_of_rows);
                    this.$message.success(`搜索订单成功! 返回${this.total} 条结果`);
                    if (this.total === 0) {
                        this.page = 1; // 如果没有数据，重置页码为 1
                    }
                }
          } catch (error) {
              this.$message.error(`搜索订单失败! ${error.response.data.error}`);
          }
      },

      clearSearchResult(){
        this.search = {
            order_id:  null,
            user_id:  null,
            order_status: process.env.VUE_APP_ORDER_STATUS_CANCELLED,
            created_from:  null,
            created_to:  null,
            limit: this.limit,
            page: this.page
        };
        this.page = 1;
        this.loadData();
      },     
      
      handleSizeChange(val) {
        this.limit = val;
        this.page = 1; // Reset to first page when page size changes
        this.loadData();
      },
      handleCurrentChange(val) {
        this.page = val;
        this.loadData();
      }
    },
    watch: {
      search: {
          handler() {
              // 检查 search 对象的所有属性是否都为空
              const isEmpty = Object.values(this.search).every(value => value === '');
              if (isEmpty) {
              this.loadData(); // 如果所有属性都为空，调用 getload 方法
              }
          },
          deep: true // 深度观察 search 对象的所有属性
    }
  },  
  computed: {
      filteredDatatable() {
          let result = this.ordersData;
          // First, filter by search strings
          Object.keys(this.search).forEach(key => {
              if (this.search[key] && typeof this.search[key] === 'string') {
                  let searchString = this.search[key].replace('*', '.*');
                  let regex = new RegExp('^' + searchString, 'i');
                  result = result.filter(order => regex.test(String(order[key])));
              }
          });

          // Then, filter by date range
          if (this.search.created_from || this.search.created_to) {
              result = result.filter(order => {
                  let created_from = this.search.created_from ? convertToMelbourneTime(this.search.created_from) : null;
                  let created_to = this.search.created_to ? convertToMelbourneTime(this.search.created_to) : null;
                  let orderCreateFrom = convertToMelbourneTime(order.created_at);
                  let orderCreateTo = convertToMelbourneTime(order.created_at);

                  if (created_from && orderCreateFrom < created_from) return false;
                  if (created_to && orderCreateTo > created_to) return false;

                  return true;
              });
          }

        return result;
      }
  }    
  }
  </script>
  
  <style scoped>
  .search-fields {
      display: flex;
      flex-wrap: nowrap; /* 阻止元素换行 */
      align-items: center;
      gap: 10px; /* 为元素添加间隙 */
      margin-bottom: 20px;
      max-width: 100%; /* 限制宽度为屏幕的一半 */
  }

  .el-select, .date-picker-wide {
      width: 50%; /* 限制最大宽度 */
  }


  .table-container{
        max-height: 80vh; /* Adjust this value according to your needs */
        overflow-x: auto; /* 显示横向滚动条 */
        overflow-y: auto; 
    }


.el-table-column {
  text-align: center;
}

.el-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .search-row {
    flex-direction: column;
  }

  .el-input, .el-select, .el-date-picker {
    max-width: none;
  }
}
</style>