 <template>
    <div class="verifyBody">
      <div class="verifyDiv">
        <div class="verify-content">
          <el-icon class="icon" name="loading"></el-icon>
          <h1>正在重置密码</h1>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import axios from 'axios';
  import instance from '@/utils/axios.config';

  export default {
    name: 'VerifyResetPassword',
    mounted() {

        const token = this.$route.params.token;
        const user_name = this.$route.query.user_name;
        const email = this.$route.query.email;
  
      if (token) {
        instance.post(`/auth/reset-password-verification/${token}?user_name=${user_name}&email=${email}`)

          .then(response => {
            if (response.status === 200) {
              this.$router.push('/ResetPassword'); // 导航至密码重置成功页面
            }
          })
          .catch(error => {
            if (error.response && error.response.status === 400) {
              this.$router.push('/DefaultFailure'); // 导航至密码重置失败页面
            } else {
              this.$router.push('/DefaultFailure'); // 或其他错误处理逻辑
            }
          });
      } else {
        this.$router.push('/DefaultFailure'); // 或其他错误处理逻辑
      }
    },
  };
  </script>
  
  <style scoped>
  .verifyBody {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #B3C0D1;
  }
  .verifyDiv {

    position: absolute;
    top: 40%;
    left: 50%;
    margin-top: -200px;
    margin-left: -250px;
    width: 450px;
    height: 600px;
    background: #fff;
    border-radius: 5%;
  }
  .verify-content {

    width: 400px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px;
    text-align: center;
  }
  .icon {

    color: grey;
    font-size: 50px;
  }
  </style> 