<template>
        <el-select :value="value" @input="$emit('input', $event)" placeholder="请选择国家" filterable>
            <el-option
                v-for="country in countries"
                :key="country.code"
                :label="`${country.emoji}${country.dial_code} / ${country.en} / ${country.zh}`"
                :value="country.en"
            >
            {{country.emoji}} {{country.dial_code}} / {{ country.en }} / {{ country.zh }}
            </el-option>
        </el-select>
</template>
  <script>
  export default {
    props: ['value'],
    data() {
      return {
        countries : [
    {
        "en": "Afghanistan",
        "zh": "阿富汗",
        "dial_code": "+93",
        "emoji": "🇦🇫",
        "code": "AF",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Aland Islands",
        "zh": "奥兰群岛",
        "dial_code": "+358",
        "emoji": "🇦🇽",
        "code": "AX",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Albania",
        "zh": "阿尔巴尼亚",
        "dial_code": "+355",
        "emoji": "🇦🇱",
        "code": "AL",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Algeria",
        "zh": "阿尔及利亚",
        "dial_code": "+213",
        "emoji": "🇩🇿",
        "code": "DZ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "American Samoa",
        "zh": "美属萨摩亚",
        "dial_code": "+1684",
        "emoji": "🇦🇸",
        "code": "AS",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Andorra",
        "zh": "安道尔",
        "dial_code": "+376",
        "emoji": "🇦🇩",
        "code": "AD",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Angola",
        "zh": "安哥拉",
        "dial_code": "+244",
        "emoji": "🇦🇴",
        "code": "AO",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Anguilla",
        "zh": "安圭拉",
        "dial_code": "+1264",
        "emoji": "🇦🇮",
        "code": "AI",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Antarctica",
        "zh": "南极洲",
        "dial_code": "+672",
        "emoji": "🇦🇶",
        "code": "AQ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Antigua and Barbuda",
        "zh": "安提瓜和巴布达",
        "dial_code": "+1268",
        "emoji": "🇦🇬",
        "code": "AG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Argentina",
        "zh": "阿根廷",
        "dial_code": "+54",
        "emoji": "🇦🇷",
        "code": "AR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Armenia",
        "zh": "亚美尼亚",
        "dial_code": "+374",
        "emoji": "🇦🇲",
        "code": "AM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Aruba",
        "zh": "阿鲁巴",
        "dial_code": "+297",
        "emoji": "🇦🇼",
        "code": "AW",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Australia",
        "zh": "澳大利亚",
        "dial_code": "+61",
        "emoji": "🇦🇺",
        "code": "AU",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Austria",
        "zh": "奥地利",
        "dial_code": "+43",
        "emoji": "🇦🇹",
        "code": "AT",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Azerbaijan",
        "zh": "阿塞拜疆",
        "dial_code": "+994",
        "emoji": "🇦🇿",
        "code": "AZ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Bahamas",
        "zh": "巴哈马",
        "dial_code": "+1242",
        "emoji": "🇧🇸",
        "code": "BS",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Bahrain",
        "zh": "巴林",
        "dial_code": "+973",
        "emoji": "🇧🇭",
        "code": "BH",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Bangladesh",
        "zh": "孟加拉国",
        "dial_code": "+880",
        "emoji": "🇧🇩",
        "code": "BD",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Barbados",
        "zh": "巴巴多斯",
        "dial_code": "+1246",
        "emoji": "🇧🇧",
        "code": "BB",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Belarus",
        "zh": "白俄罗斯",
        "dial_code": "+375",
        "emoji": "🇧🇾",
        "code": "BY",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Belgium",
        "zh": "比利时",
        "dial_code": "+32",
        "emoji": "🇧🇪",
        "code": "BE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Belize",
        "zh": "伯利兹",
        "dial_code": "+501",
        "emoji": "🇧🇿",
        "code": "BZ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Benin",
        "zh": "贝宁",
        "dial_code": "+229",
        "emoji": "🇧🇯",
        "code": "BJ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Bermuda",
        "zh": "百慕大",
        "dial_code": "+1441",
        "emoji": "🇧🇲",
        "code": "BM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Bhutan",
        "zh": "不丹",
        "dial_code": "+975",
        "emoji": "🇧🇹",
        "code": "BT",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Bolivia, Plurinational State of",
        "zh": "玻利维亚",
        "dial_code": "+591",
        "emoji": "🇧🇴",
        "code": "BO",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Bosnia and Herzegovina",
        "zh": "波斯尼亚和黑塞哥维那",
        "dial_code": "+387",
        "emoji": "🇧🇦",
        "code": "BA",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Botswana",
        "zh": "博茨瓦纳",
        "dial_code": "+267",
        "emoji": "🇧🇼",
        "code": "BW",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Brazil",
        "zh": "巴西",
        "dial_code": "+55",
        "emoji": "🇧🇷",
        "code": "BR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "British Indian Ocean Territory",
        "zh": "英属印度洋领地",
        "dial_code": "+246",
        "emoji": "🇮🇴",
        "code": "IO",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Brunei Darussalam",
        "zh": "文莱",
        "dial_code": "+673",
        "emoji": "🇧🇳",
        "code": "BN",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Bulgaria",
        "zh": "保加利亚",
        "dial_code": "+359",
        "emoji": "🇧🇬",
        "code": "BG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Burkina Faso",
        "zh": "布基纳法索",
        "dial_code": "+226",
        "emoji": "🇧🇫",
        "code": "BF",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Burundi",
        "zh": "布隆迪",
        "dial_code": "+257",
        "emoji": "🇧🇮",
        "code": "BI",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Cambodia",
        "zh": "柬埔寨",
        "dial_code": "+855",
        "emoji": "🇰🇭",
        "code": "KH",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Cameroon",
        "zh": "喀麦隆",
        "dial_code": "+237",
        "emoji": "🇨🇲",
        "code": "CM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Canada",
        "zh": "加拿大",
        "dial_code": "+1",
        "emoji": "🇨🇦",
        "code": "CA",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Cape Verde",
        "zh": "佛得角",
        "dial_code": "+238",
        "emoji": "🇨🇻",
        "code": "CV",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Cayman Islands",
        "zh": "开曼群岛",
        "dial_code": "+345",
        "emoji": "🇰🇾",
        "code": "KY",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Central African Republic",
        "zh": "中非共和国",
        "dial_code": "+236",
        "emoji": "🇨🇫",
        "code": "CF",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Chad",
        "zh": "乍得",
        "dial_code": "+235",
        "emoji": "🇹🇩",
        "code": "TD",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Chile",
        "zh": "智利",
        "dial_code": "+56",
        "emoji": "🇨🇱",
        "code": "CL",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "China",
        "zh": "中国",
        "dial_code": "+86",
        "emoji": "🇨🇳",
        "code": "CN",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Christmas Island",
        "zh": "圣诞岛",
        "dial_code": "+61",
        "emoji": "🇨🇽",
        "code": "CX",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Cocos (Keeling) Islands",
        "zh": "科科斯（基林）群岛",
        "dial_code": "+61",
        "emoji": "🇨🇨",
        "code": "CC",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Colombia",
        "zh": "哥伦比亚",
        "dial_code": "+57",
        "emoji": "🇨🇴",
        "code": "CO",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Comoros",
        "zh": "科摩罗",
        "dial_code": "+269",
        "emoji": "🇰🇲",
        "code": "KM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Congo",
        "zh": "刚果（布）",
        "dial_code": "+242",
        "emoji": "🇨🇬",
        "code": "CG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Congo, The Democratic Republic of the Congo",
        "zh": "刚果（金）",
        "dial_code": "+243",
        "emoji": "🇨🇩",
        "code": "CD",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Cook Islands",
        "zh": "库克群岛",
        "dial_code": "+682",
        "emoji": "🇨🇰",
        "code": "CK",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Costa Rica",
        "zh": "哥斯达黎加",
        "dial_code": "+506",
        "emoji": "🇨🇷",
        "code": "CR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Cote d'Ivoire",
        "zh": "科特迪瓦",
        "dial_code": "+225",
        "emoji": "🇨🇮",
        "code": "CI",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Croatia",
        "zh": "克罗地亚",
        "dial_code": "+385",
        "emoji": "🇭🇷",
        "code": "HR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Cuba",
        "zh": "古巴",
        "dial_code": "+53",
        "emoji": "🇨🇺",
        "code": "CU",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Cyprus",
        "zh": "塞浦路斯",
        "dial_code": "+357",
        "emoji": "🇨🇾",
        "code": "CY",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Czech Republic",
        "zh": "捷克共和国",
        "dial_code": "+420",
        "emoji": "🇨🇿",
        "code": "CZ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Denmark",
        "zh": "丹麦",
        "dial_code": "+45",
        "emoji": "🇩🇰",
        "code": "DK",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Djibouti",
        "zh": "吉布提",
        "dial_code": "+253",
        "emoji": "🇩🇯",
        "code": "DJ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Dominica",
        "zh": "多米尼加",
        "dial_code": "+1767",
        "emoji": "🇩🇲",
        "code": "DM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Dominican Republic",
        "zh": "多米尼加共和国",
        "dial_code": "+1849",
        "emoji": "🇩🇴",
        "code": "DO",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Ecuador",
        "zh": "厄瓜多尔",
        "dial_code": "+593",
        "emoji": "🇪🇨",
        "code": "EC",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Egypt",
        "zh": "埃及",
        "dial_code": "+20",
        "emoji": "🇪🇬",
        "code": "EG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "El Salvador",
        "zh": "萨尔瓦多",
        "dial_code": "+503",
        "emoji": "🇸🇻",
        "code": "SV",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Equatorial Guinea",
        "zh": "赤道几内亚",
        "dial_code": "+240",
        "emoji": "🇬🇶",
        "code": "GQ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Eritrea",
        "zh": "厄立特里亚",
        "dial_code": "+291",
        "emoji": "🇪🇷",
        "code": "ER",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Estonia",
        "zh": "爱沙尼亚",
        "dial_code": "+372",
        "emoji": "🇪🇪",
        "code": "EE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Ethiopia",
        "zh": "埃塞俄比亚",
        "dial_code": "+251",
        "emoji": "🇪🇹",
        "code": "ET",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Falkland Islands (Malvinas)",
        "zh": "福克兰群岛",
        "dial_code": "+500",
        "emoji": "🇫🇰",
        "code": "FK",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Faroe Islands",
        "zh": "法罗群岛",
        "dial_code": "+298",
        "emoji": "🇫🇴",
        "code": "FO",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Fiji",
        "zh": "斐济",
        "dial_code": "+679",
        "emoji": "🇫🇯",
        "code": "FJ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Finland",
        "zh": "芬兰",
        "dial_code": "+358",
        "emoji": "🇫🇮",
        "code": "FI",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "France",
        "zh": "法国",
        "dial_code": "+33",
        "emoji": "🇫🇷",
        "code": "FR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "French Guiana",
        "zh": "法属圭亚那",
        "dial_code": "+594",
        "emoji": "🇬🇫",
        "code": "GF",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "French Polynesia",
        "zh": "法属波利尼西亚",
        "dial_code": "+689",
        "emoji": "🇵🇫",
        "code": "PF",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Gabon",
        "zh": "加蓬",
        "dial_code": "+241",
        "emoji": "🇬🇦",
        "code": "GA",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Gambia",
        "zh": "冈比亚",
        "dial_code": "+220",
        "emoji": "🇬🇲",
        "code": "GM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Georgia",
        "zh": "格鲁吉亚",
        "dial_code": "+995",
        "emoji": "🇬🇪",
        "code": "GE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Germany",
        "zh": "德国",
        "dial_code": "+49",
        "emoji": "🇩🇪",
        "code": "DE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Ghana",
        "zh": "加纳",
        "dial_code": "+233",
        "emoji": "🇬🇭",
        "code": "GH",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Gibraltar",
        "zh": "直布罗陀",
        "dial_code": "+350",
        "emoji": "🇬🇮",
        "code": "GI",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Greece",
        "zh": "希腊",
        "dial_code": "+30",
        "emoji": "🇬🇷",
        "code": "GR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Greenland",
        "zh": "格陵兰",
        "dial_code": "+299",
        "emoji": "🇬🇱",
        "code": "GL",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Grenada",
        "zh": "格林纳达",
        "dial_code": "+1473",
        "emoji": "🇬🇩",
        "code": "GD",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Guadeloupe",
        "zh": "瓜德罗普",
        "dial_code": "+590",
        "emoji": "🇬🇵",
        "code": "GP",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Guam",
        "zh": "关岛",
        "dial_code": "+1671",
        "emoji": "🇬🇺",
        "code": "GU",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Guatemala",
        "zh": "危地马拉",
        "dial_code": "+502",
        "emoji": "🇬🇹",
        "code": "GT",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Guernsey",
        "zh": "根西岛",
        "dial_code": "+44",
        "emoji": "🇬🇬",
        "code": "GG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Guinea",
        "zh": "几内亚",
        "dial_code": "+224",
        "emoji": "🇬🇳",
        "code": "GN",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Guinea-Bissau",
        "zh": "几内亚比绍",
        "dial_code": "+245",
        "emoji": "🇬🇼",
        "code": "GW",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Guyana",
        "zh": "圭亚那",
        "dial_code": "+592",
        "emoji": "🇬🇾",
        "code": "GY",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Haiti",
        "zh": "海地",
        "dial_code": "+509",
        "emoji": "🇭🇹",
        "code": "HT",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Heard Island and Mcdonald Islands",
        "zh": "赫德岛和麦克唐纳群岛",
        "dial_code": "+0",
        "emoji": "🇭🇲",
        "code": "HM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Holy See (Vatican City State)",
        "zh": "梵蒂冈",
        "dial_code": "+379",
        "emoji": "🇻🇦",
        "code": "VA",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Honduras",
        "zh": "洪都拉斯",
        "dial_code": "+504",
        "emoji": "🇭🇳",
        "code": "HN",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Hong Kong",
        "zh": "中国香港",
        "dial_code": "+852",
        "emoji": "🇭🇰",
        "code": "HK",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Hungary",
        "zh": "匈牙利",
        "dial_code": "+36",
        "emoji": "🇭🇺",
        "code": "HU",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Iceland",
        "zh": "冰岛",
        "dial_code": "+354",
        "emoji": "🇮🇸",
        "code": "IS",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "India",
        "zh": "印度",
        "dial_code": "+91",
        "emoji": "🇮🇳",
        "code": "IN",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Indonesia",
        "zh": "印度尼西亚",
        "dial_code": "+62",
        "emoji": "🇮🇩",
        "code": "ID",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Iran, Islamic Republic of Persian Gulf",
        "zh": "伊朗",
        "dial_code": "+98",
        "emoji": "🇮🇷",
        "code": "IR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Iraq",
        "zh": "伊拉克",
        "dial_code": "+964",
        "emoji": "🇮🇶",
        "code": "IQ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Ireland",
        "zh": "爱尔兰",
        "dial_code": "+353",
        "emoji": "🇮🇪",
        "code": "IE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Isle of Man",
        "zh": "马恩岛",
        "dial_code": "+44",
        "emoji": "🇮🇲",
        "code": "IM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Israel",
        "zh": "以色列",
        "dial_code": "+972",
        "emoji": "🇮🇱",
        "code": "IL",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Italy",
        "zh": "意大利",
        "dial_code": "+39",
        "emoji": "🇮🇹",
        "code": "IT",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Jamaica",
        "zh": "牙买加",
        "dial_code": "+1876",
        "emoji": "🇯🇲",
        "code": "JM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Japan",
        "zh": "日本",
        "dial_code": "+81",
        "emoji": "🇯🇵",
        "code": "JP",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Jersey",
        "zh": "泽西",
        "dial_code": "+44",
        "emoji": "🇯🇪",
        "code": "JE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Jordan",
        "zh": "约旦",
        "dial_code": "+962",
        "emoji": "🇯🇴",
        "code": "JO",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Kazakhstan",
        "zh": "哈萨克斯坦",
        "dial_code": "+77",
        "emoji": "🇰🇿",
        "code": "KZ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Kenya",
        "zh": "肯尼亚",
        "dial_code": "+254",
        "emoji": "🇰🇪",
        "code": "KE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Kiribati",
        "zh": "基里巴斯",
        "dial_code": "+686",
        "emoji": "🇰🇮",
        "code": "KI",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Korea, Democratic People's Republic of Korea",
        "zh": "朝鲜",
        "dial_code": "+850",
        "emoji": "🇰🇵",
        "code": "KP",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Korea, Republic of South Korea",
        "zh": "韩国",
        "dial_code": "+82",
        "emoji": "🇰🇷",
        "code": "KR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Kuwait",
        "zh": "科威特",
        "dial_code": "+965",
        "emoji": "🇰🇼",
        "code": "KW",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Kyrgyzstan",
        "zh": "吉尔吉斯斯坦",
        "dial_code": "+996",
        "emoji": "🇰🇬",
        "code": "KG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Laos",
        "zh": "老挝",
        "dial_code": "+856",
        "emoji": "🇱🇦",
        "code": "LA",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Latvia",
        "zh": "拉脱维亚",
        "dial_code": "+371",
        "emoji": "🇱🇻",
        "code": "LV",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Lebanon",
        "zh": "黎巴嫩",
        "dial_code": "+961",
        "emoji": "🇱🇧",
        "code": "LB",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Lesotho",
        "zh": "莱索托",
        "dial_code": "+266",
        "emoji": "🇱🇸",
        "code": "LS",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Liberia",
        "zh": "利比里亚",
        "dial_code": "+231",
        "emoji": "🇱🇷",
        "code": "LR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Libyan Arab Jamahiriya",
        "zh": "利比亚",
        "dial_code": "+218",
        "emoji": "🇱🇾",
        "code": "LY",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Liechtenstein",
        "zh": "列支敦士登",
        "dial_code": "+423",
        "emoji": "🇱🇮",
        "code": "LI",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Lithuania",
        "zh": "立陶宛",
        "dial_code": "+370",
        "emoji": "🇱🇹",
        "code": "LT",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Luxembourg",
        "zh": "卢森堡",
        "dial_code": "+352",
        "emoji": "🇱🇺",
        "code": "LU",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Macao",
        "zh": "中国澳门",
        "dial_code": "+853",
        "emoji": "🇲🇴",
        "code": "MO",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Macedonia",
        "zh": "马其顿",
        "dial_code": "+389",
        "emoji": "🇲🇰",
        "code": "MK",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Madagascar",
        "zh": "马达加斯加",
        "dial_code": "+261",
        "emoji": "🇲🇬",
        "code": "MG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Malawi",
        "zh": "马拉维",
        "dial_code": "+265",
        "emoji": "🇲🇼",
        "code": "MW",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Malaysia",
        "zh": "马来西亚",
        "dial_code": "+60",
        "emoji": "🇲🇾",
        "code": "MY",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Maldives",
        "zh": "马尔代夫",
        "dial_code": "+960",
        "emoji": "🇲🇻",
        "code": "MV",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Mali",
        "zh": "马里",
        "dial_code": "+223",
        "emoji": "🇲🇱",
        "code": "ML",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Malta",
        "zh": "马耳他",
        "dial_code": "+356",
        "emoji": "🇲🇹",
        "code": "MT",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Marshall Islands",
        "zh": "马绍尔群岛",
        "dial_code": "+692",
        "emoji": "🇲🇭",
        "code": "MH",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Martinique",
        "zh": "马提尼克",
        "dial_code": "+596",
        "emoji": "🇲🇶",
        "code": "MQ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Mauritania",
        "zh": "毛里塔尼亚",
        "dial_code": "+222",
        "emoji": "🇲🇷",
        "code": "MR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Mauritius",
        "zh": "毛里求斯",
        "dial_code": "+230",
        "emoji": "🇲🇺",
        "code": "MU",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Mayotte",
        "zh": "马约特",
        "dial_code": "+262",
        "emoji": "🇾🇹",
        "code": "YT",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Mexico",
        "zh": "墨西哥",
        "dial_code": "+52",
        "emoji": "🇲🇽",
        "code": "MX",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Micronesia, Federated States of Micronesia",
        "zh": "密克罗尼西亚",
        "dial_code": "+691",
        "emoji": "🇫🇲",
        "code": "FM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Moldova",
        "zh": "摩尔多瓦",
        "dial_code": "+373",
        "emoji": "🇲🇩",
        "code": "MD",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Monaco",
        "zh": "摩纳哥",
        "dial_code": "+377",
        "emoji": "🇲🇨",
        "code": "MC",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Mongolia",
        "zh": "蒙古",
        "dial_code": "+976",
        "emoji": "🇲🇳",
        "code": "MN",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Montenegro",
        "zh": "黑山",
        "dial_code": "+382",
        "emoji": "🇲🇪",
        "code": "ME",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Montserrat",
        "zh": "蒙塞拉特",
        "dial_code": "+1664",
        "emoji": "🇲🇸",
        "code": "MS",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Morocco",
        "zh": "摩洛哥",
        "dial_code": "+212",
        "emoji": "🇲🇦",
        "code": "MA",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Mozambique",
        "zh": "莫桑比克",
        "dial_code": "+258",
        "emoji": "🇲🇿",
        "code": "MZ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Myanmar",
        "zh": "缅甸",
        "dial_code": "+95",
        "emoji": "🇲🇲",
        "code": "MM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Namibia",
        "zh": "纳米比亚",
        "dial_code": "+264",
        "emoji": "🇳🇦",
        "code": "NA",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Nauru",
        "zh": "瑙鲁",
        "dial_code": "+674",
        "emoji": "🇳🇷",
        "code": "NR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Nepal",
        "zh": "尼泊尔",
        "dial_code": "+977",
        "emoji": "🇳🇵",
        "code": "NP",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Netherlands",
        "zh": "荷兰",
        "dial_code": "+31",
        "emoji": "🇳🇱",
        "code": "NL",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Netherlands Antilles",
        "zh": "荷属安的列斯",
        "dial_code": "+599",
        "emoji": "🇦🇳",
        "code": "AN",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "New Caledonia",
        "zh": "新喀里多尼亚",
        "dial_code": "+687",
        "emoji": "🇳🇨",
        "code": "NC",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "New Zealand",
        "zh": "新西兰",
        "dial_code": "+64",
        "emoji": "🇳🇿",
        "code": "NZ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Nicaragua",
        "zh": "尼加拉瓜",
        "dial_code": "+505",
        "emoji": "🇳🇮",
        "code": "NI",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Niger",
        "zh": "尼日尔",
        "dial_code": "+227",
        "emoji": "🇳🇪",
        "code": "NE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Nigeria",
        "zh": "尼日利亚",
        "dial_code": "+234",
        "emoji": "🇳🇬",
        "code": "NG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Niue",
        "zh": "纽埃",
        "dial_code": "+683",
        "emoji": "🇳🇺",
        "code": "NU",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Norfolk Island",
        "zh": "诺福克岛",
        "dial_code": "+672",
        "emoji": "🇳🇫",
        "code": "NF",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Northern Mariana Islands",
        "zh": "北马里亚纳群岛",
        "dial_code": "+1670",
        "emoji": "🇲🇵",
        "code": "MP",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Norway",
        "zh": "挪威",
        "dial_code": "+47",
        "emoji": "🇳🇴",
        "code": "NO",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Oman",
        "zh": "阿曼",
        "dial_code": "+968",
        "emoji": "🇴🇲",
        "code": "OM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Pakistan",
        "zh": "巴基斯坦",
        "dial_code": "+92",
        "emoji": "🇵🇰",
        "code": "PK",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Palau",
        "zh": "帕劳",
        "dial_code": "+680",
        "emoji": "🇵🇼",
        "code": "PW",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Palestinian Territory, Occupied",
        "zh": "巴勒斯坦",
        "dial_code": "+970",
        "emoji": "🇵🇸",
        "code": "PS",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Panama",
        "zh": "巴拿马",
        "dial_code": "+507",
        "emoji": "🇵🇦",
        "code": "PA",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Papua New Guinea",
        "zh": "巴布亚新几内亚",
        "dial_code": "+675",
        "emoji": "🇵🇬",
        "code": "PG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Paraguay",
        "zh": "巴拉圭",
        "dial_code": "+595",
        "emoji": "🇵🇾",
        "code": "PY",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Peru",
        "zh": "秘鲁",
        "dial_code": "+51",
        "emoji": "🇵🇪",
        "code": "PE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Philippines",
        "zh": "菲律宾",
        "dial_code": "+63",
        "emoji": "🇵🇭",
        "code": "PH",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Pitcairn",
        "zh": "皮特凯恩",
        "dial_code": "+64",
        "emoji": "🇵🇳",
        "code": "PN",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Poland",
        "zh": "波兰",
        "dial_code": "+48",
        "emoji": "🇵🇱",
        "code": "PL",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Portugal",
        "zh": "葡萄牙",
        "dial_code": "+351",
        "emoji": "🇵🇹",
        "code": "PT",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Puerto Rico",
        "zh": "波多黎各",
        "dial_code": "+1939",
        "emoji": "🇵🇷",
        "code": "PR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Qatar",
        "zh": "卡塔尔",
        "dial_code": "+974",
        "emoji": "🇶🇦",
        "code": "QA",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Romania",
        "zh": "罗马尼亚",
        "dial_code": "+40",
        "emoji": "🇷🇴",
        "code": "RO",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Russia",
        "zh": "俄罗斯",
        "dial_code": "+7",
        "emoji": "🇷🇺",
        "code": "RU",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Rwanda",
        "zh": "卢旺达",
        "dial_code": "+250",
        "emoji": "🇷🇼",
        "code": "RW",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Reunion",
        "zh": "留尼汪",
        "dial_code": "+262",
        "emoji": "🇷🇪",
        "code": "RE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Saint Barthelemy",
        "zh": "圣巴泰勒米",
        "dial_code": "+590",
        "emoji": "🇧🇱",
        "code": "BL",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Saint Helena, Ascension and Tristan Da Cunha",
        "zh": "圣赫勒拿、阿森松和特里斯坦达库尼亚",
        "dial_code": "+290",
        "emoji": "🇸🇭",
        "code": "SH",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Saint Kitts and Nevis",
        "zh": "圣基茨和尼维斯",
        "dial_code": "+1869",
        "emoji": "🇰🇳",
        "code": "KN",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Saint Lucia",
        "zh": "圣卢西亚",
        "dial_code": "+1758",
        "emoji": "🇱🇨",
        "code": "LC",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Saint Martin",
        "zh": "圣马丁",
        "dial_code": "+590",
        "emoji": "🇲🇫",
        "code": "MF",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Saint Pierre and Miquelon",
        "zh": "圣皮埃尔和密克隆",
        "dial_code": "+508",
        "emoji": "🇵🇲",
        "code": "PM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Saint Vincent and the Grenadines",
        "zh": "圣文森特和格林纳丁斯",
        "dial_code": "+1784",
        "emoji": "🇻🇨",
        "code": "VC",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Samoa",
        "zh": "萨摩亚",
        "dial_code": "+685",
        "emoji": "🇼🇸",
        "code": "WS",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "San Marino",
        "zh": "圣马力诺",
        "dial_code": "+378",
        "emoji": "🇸🇲",
        "code": "SM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Sao Tome and Principe",
        "zh": "圣多美和普林西比",
        "dial_code": "+239",
        "emoji": "🇸🇹",
        "code": "ST",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Saudi Arabia",
        "zh": "沙特阿拉伯",
        "dial_code": "+966",
        "emoji": "🇸🇦",
        "code": "SA",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Senegal",
        "zh": "塞内加尔",
        "dial_code": "+221",
        "emoji": "🇸🇳",
        "code": "SN",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Serbia",
        "zh": "塞尔维亚",
        "dial_code": "+381",
        "emoji": "🇷🇸",
        "code": "RS",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Seychelles",
        "zh": "塞舌尔",
        "dial_code": "+248",
        "emoji": "🇸🇨",
        "code": "SC",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Sierra Leone",
        "zh": "塞拉利昂",
        "dial_code": "+232",
        "emoji": "🇸🇱",
        "code": "SL",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Singapore",
        "zh": "新加坡",
        "dial_code": "+65",
        "emoji": "🇸🇬",
        "code": "SG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Slovakia",
        "zh": "斯洛伐克",
        "dial_code": "+421",
        "emoji": "🇸🇰",
        "code": "SK",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Slovenia",
        "zh": "斯洛文尼亚",
        "dial_code": "+386",
        "emoji": "🇸🇮",
        "code": "SI",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Solomon Islands",
        "zh": "所罗门群岛",
        "dial_code": "+677",
        "emoji": "🇸🇧",
        "code": "SB",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Somalia",
        "zh": "索马里",
        "dial_code": "+252",
        "emoji": "🇸🇴",
        "code": "SO",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "South Africa",
        "zh": "南非",
        "dial_code": "+27",
        "emoji": "🇿🇦",
        "code": "ZA",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "South Georgia and the South Sandwich Islands",
        "zh": "南乔治亚和南桑威奇群岛",
        "dial_code": "+500",
        "emoji": "🇬🇸",
        "code": "GS",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Spain",
        "zh": "西班牙",
        "dial_code": "+34",
        "emoji": "🇪🇸",
        "code": "ES",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Sri Lanka",
        "zh": "斯里兰卡",
        "dial_code": "+94",
        "emoji": "🇱🇰",
        "code": "LK",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Sudan",
        "zh": "苏丹",
        "dial_code": "+249",
        "emoji": "🇸🇩",
        "code": "SD",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Suriname",
        "zh": "苏里南",
        "dial_code": "+597",
        "emoji": "🇸🇷",
        "code": "SR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Svalbard and Jan Mayen",
        "zh": "斯瓦尔巴特和扬马延",
        "dial_code": "+47",
        "emoji": "🇸🇯",
        "code": "SJ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Swaziland",
        "zh": "斯威士兰",
        "dial_code": "+268",
        "emoji": "🇸🇿",
        "code": "SZ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Sweden",
        "zh": "瑞典",
        "dial_code": "+46",
        "emoji": "🇸🇪",
        "code": "SE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Switzerland",
        "zh": "瑞士",
        "dial_code": "+41",
        "emoji": "🇨🇭",
        "code": "CH",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Syrian Arab Republic",
        "zh": "叙利亚",
        "dial_code": "+963",
        "emoji": "🇸🇾",
        "code": "SY",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Taiwan",
        "zh": "中国台湾",
        "dial_code": "+886",
        "emoji": "🇹🇼",
        "code": "TW",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Tajikistan",
        "zh": "塔吉克斯坦",
        "dial_code": "+992",
        "emoji": "🇹🇯",
        "code": "TJ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Tanzania, United Republic of Tanzania",
        "zh": "坦桑尼亚",
        "dial_code": "+255",
        "emoji": "🇹🇿",
        "code": "TZ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Thailand",
        "zh": "泰国",
        "dial_code": "+66",
        "emoji": "🇹🇭",
        "code": "TH",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Timor-Leste",
        "zh": "东帝汶",
        "dial_code": "+670",
        "emoji": "🇹🇱",
        "code": "TL",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Togo",
        "zh": "多哥",
        "dial_code": "+228",
        "emoji": "🇹🇬",
        "code": "TG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Tokelau",
        "zh": "托克劳",
        "dial_code": "+690",
        "emoji": "🇹🇰",
        "code": "TK",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Tonga",
        "zh": "汤加",
        "dial_code": "+676",
        "emoji": "🇹🇴",
        "code": "TO",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Trinidad and Tobago",
        "zh": "特立尼达和多巴哥",
        "dial_code": "+1868",
        "emoji": "🇹🇹",
        "code": "TT",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Tunisia",
        "zh": "突尼斯",
        "dial_code": "+216",
        "emoji": "🇹🇳",
        "code": "TN",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Turkey",
        "zh": "土耳其",
        "dial_code": "+90",
        "emoji": "🇹🇷",
        "code": "TR",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Turkmenistan",
        "zh": "土库曼斯坦",
        "dial_code": "+993",
        "emoji": "🇹🇲",
        "code": "TM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Turks and Caicos Islands",
        "zh": "特克斯和凯科斯群岛",
        "dial_code": "+1649",
        "emoji": "🇹🇨",
        "code": "TC",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Tuvalu",
        "zh": "图瓦卢",
        "dial_code": "+688",
        "emoji": "🇹🇻",
        "code": "TV",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Uganda",
        "zh": "乌干达",
        "dial_code": "+256",
        "emoji": "🇺🇬",
        "code": "UG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Ukraine",
        "zh": "乌克兰",
        "dial_code": "+380",
        "emoji": "🇺🇦",
        "code": "UA",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "United Arab Emirates",
        "zh": "阿联酋",
        "dial_code": "+971",
        "emoji": "🇦🇪",
        "code": "AE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "United Kingdom",
        "zh": "英国",
        "dial_code": "+44",
        "emoji": "🇬🇧",
        "code": "GB",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "United States",
        "zh": "美国",
        "dial_code": "+1",
        "emoji": "🇺🇸",
        "code": "US",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Uruguay",
        "zh": "乌拉圭",
        "dial_code": "+598",
        "emoji": "🇺🇾",
        "code": "UY",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Uzbekistan",
        "zh": "乌兹别克斯坦",
        "dial_code": "+998",
        "emoji": "🇺🇿",
        "code": "UZ",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Vanuatu",
        "zh": "瓦努阿图",
        "dial_code": "+678",
        "emoji": "🇻🇺",
        "code": "VU",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Venezuela, Bolivarian Republic of Venezuela",
        "zh": "委内瑞拉",
        "dial_code": "+58",
        "emoji": "🇻🇪",
        "code": "VE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Vietnam",
        "zh": "越南",
        "dial_code": "+84",
        "emoji": "🇻🇳",
        "code": "VN",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Virgin Islands, British",
        "zh": "英属维尔京群岛",
        "dial_code": "+1284",
        "emoji": "🇻🇬",
        "code": "VG",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Virgin Islands, U.S.",
        "zh": "美属维尔京群岛",
        "dial_code": "+1340",
        "emoji": "🇻🇮",
        "code": "VI",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Wallis and Futuna",
        "zh": "瓦利斯和富图纳",
        "dial_code": "+681",
        "emoji": "🇼🇫",
        "code": "WF",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Yemen",
        "zh": "也门",
        "dial_code": "+967",
        "emoji": "🇾🇪",
        "code": "YE",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Zambia",
        "zh": "赞比亚",
        "dial_code": "+260",
        "emoji": "🇿🇲",
        "code": "ZM",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    },
    {
        "en": "Zimbabwe",
        "zh": "津巴布韦",
        "dial_code": "+263",
        "emoji": "🇿🇼",
        "code": "ZW",
        "air_unit_price": "100",
        "ship_unit_price": "100",
        "air_sensitive_unit_price": "100",
        "ship_sensitive_unit_price": "100",
        "density":"0.0045"
    }
]    
    

      };
    }
  };
  </script>