// data.js
export const columns_mapping = {
    // user_input_items
    "user_input_items_id": "用户申报物品ID",
    "item_category": "物品类目",
    "item_description": "物品描述",

    // scanned_items
    "item_id": "物品ID",
    "tracking_id": "跟踪ID",
    "weight": "重量",
    "volume": "体积",
    "length": "长度",
    "width": "宽度",
    "height": "高度",
    "whs_id": "仓库ID",
    "machine_id": "机器ID",
    "scanned_at": "扫描时间",
    "item_status": "物品状态",
    "is_pickup_required": "是否需要取件",
    "sender_address_id": "寄件人地址ID",
    "pickup_address": "上门取件地址",
    "is_online": "是否网购",
    "check_sum": "校验和",

    // orders
    "order_id": "订单ID",
    "user_id": "用户ID",
    "alias_order_id": "订单号",
    "order_status": "订单状态",
    "receiver": "收件人",
    "receiver_address": "收件人地址",
    // "receiver_suburb": "收件人郊区",
    "receiver_state": "收件人州",
    "receiver_postcode": "收件人邮政编码",
    "receiver_mobile": "收件人手机",
    "receiver_email": "收件人电子邮件",
    "sender": "寄件人",
    "sender_address": "寄件人地址",
    // "sender_suburb": "寄件人郊区",
    // "sender_city": "寄件人城市",
    "sender_state": "寄件人州",
    "sender_postcode": "寄件人邮政编码",
    "sender_mobile": "寄件人手机",
    "sender_email": "寄件人电子邮件",
    "total_weight": "总重量",
    "total_volume": "总体积",
    "price": "价格",
    "full_price": "全价",
    "discounted_value": "折扣值",
    "total_declared_value": "申报总价值",
    "number_of_boxes": "合箱数",
    "shipping_method": "运输方式",
    "delivery_method": "送货方式",
    "payment_status": "付款状态",
    "payment_method": "付款方式",
    "is_insurance_required": "是否需要保险",
    "is_unpacked_required": "是否需要拆包",
    "updated_at": "更新时间",
    "created_at": "创建时间",
    "signature_timestamp": "签名时间戳",
    "additional_services": "附加服务",
    "shipment_batch": "航运批次",
    "shipment_deadline": "截单日期",

    // order_items
    "record_id": "记录ID",
    "is_sensitive_good": "是否敏感物品",
    "declared_value": "申报价值",

    // coupons
    "new_customer_only": "新客户专享",
    "coupon_id": "优惠券ID",
    "coupon_name": "优惠券名称",
    "valid_from": "有效期开始",
    "valid_to": "有效期结束",
    "repeated_usage_allowed": "允许重复使用",
    "min_spend_requirement": "最低消费要求",
    "max_discount": "最大折扣",
    "discount_type": "折扣类型",
    "discount_value": "折扣值",


    // admin_users
    "user_name": "用户名",
    "first_name": "名字",
    "last_name": "姓",
    "email": "电子邮件",
    "mobile_phone": "手机",
    "role": "权限",
    "is_disabled": "是否禁用",

    // v_order_summary
    "payable_volume": "价格体积",
    "number_of_items": "物品数量",
    "is_delivered": "是否送达",

    // users
    "open_id": "小程序用户ID",
    "union_id": "公众号用户ID",
    "avatar_url": "头像url"
};