<template>
    <el-container style="height: 100%; border: 1px solid #eee">
    <el-aside :width="aside_width" style="background-color: rgb(238, 241, 246);height: 100%;margin-left: -1px;">
      <AsideA :isCollapse = "isCollapse"></AsideA>
    </el-aside>
  
    <el-container style="height:100%;">
      <el-header style="text-align: right; font-size: 12px; height: 100%; border-bottom: rgba(168,168,168,0.3) 1px solid;">
        <MainHeader @doCollapse="doCollapse" :icon="icon" :username="username"></MainHeader>
      </el-header>
    
      <el-main style="height:100%;">
        <ImagesTable></ImagesTable>
      </el-main>

    </el-container>
  </el-container>
</template>

<script>

import AsideA from '../AsideA.vue';
import MainHeader from '../MainHeader.vue';
import ImagesTable from './ImagesTable.vue';


    export default{
    name: "AdminHome",
    components:{AsideA,MainHeader,ImagesTable},
    data(){
      return{
        isCollapse:false,
        aside_width:'200px',
        icon:"el-icon-s-fold",
        username: localStorage.getItem('user_name') || '未登陆',
      }
    },
    methods:{
      doCollapse(){
        this.isCollapse = !this.isCollapse
        if(!this.isCollapse){
          this.aside_width = "200px"
          this.icon="el-icon-s-fold"   
        }else{
          this.aside_width = "65px"
          this.icon="el-icon-s-unfold"

        }
      }
    }
    
} 
</script>

<style>
  .el-header {
      
      color: #333;
      line-height: 60px;
    }
  .el-main{
    padding: 5px;
  }
  .el-aside {
    color: #333;
  }
</style>