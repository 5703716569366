<template>
    <div>
        <el-button type="primary" icon="el-icon-printer" @click="printPage"></el-button>
        <div ref="pdfContent" v-html="htmlContent"></div>
    </div>

</template>

<script>
import {getOrderPdf} from '@/utils/orders';

export default {
    props: ['orderId'],
    data() {
        return {
            htmlContent: ''
        };
    },
    methods: {
        async fetchHtmlContent() {
            const response = await getOrderPdf(this.orderId);
            this.htmlContent = response;
        },
        printPage() {
            window.print();
        }
    },
    created() {
        this.fetchHtmlContent();
    }
}
</script>