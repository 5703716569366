<template>
    <div>
        <div class="search-fields">
        <el-button type="danger" icon="el-icon-delete" @click="clearSearchResult">清除搜索</el-button>
        <el-button type="primary" icon="el-icon-search" @click="searchOrderStatusHistory">搜索</el-button>
        <el-select v-model.lazy="search.order_status" placeholder="搜索订单状态" clearable>
            <el-option v-for="(value, key) in orderStatuses" :key="key" :label="value" :value="value"></el-option>
        </el-select>
        <el-input v-model.lazy="search.order_id" placeholder="搜索订单ID" clearable></el-input>
        <el-input v-model.lazy="search.alias_order_id" placeholder="搜索运单号" clearable></el-input>
        <el-input v-model.lazy="search.user_id" placeholder="搜索用户ID" clearable ></el-input>
      </div>
        <!-- 表格展示数据 -->
        <div class="table-container">
            <el-table :data="filteredDatatable" :header-cell-style="{background:'#f2f5fc', color:'#555'}" height="70vh" border>
                <el-table-column type="index" label="序号" fixed/>
                <el-table-column prop="order_id" label="订单ID" flex="1"></el-table-column>
                <el-table-column prop="alias_order_id" label="运单号" fixed></el-table-column>
                <el-table-column prop="user_id" label="用户ID" fixed></el-table-column>
                <el-table-column prop="order_status" label="订单最新状态" flex="1"></el-table-column>
                <el-table-column prop="operator_name" label="最后操作员" flex="1"></el-table-column>
                <el-table-column prop="updated_at" label="最后更新时间" flex="1" :formatter="row => (row.updated_at)"></el-table-column>
                <el-table-column label="物流时间线" flex="1">
                    <template slot-scope="scope">
                        <el-button size="small" type="success" @click="checkOrderStatusTimeline(scope.row.order_id)">查看物流时间线</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="支付历史" flex="1">
                    <template slot-scope="scope">
                        <el-button size="small" type="success" @click="checkPaymentStatusTimeline(scope.row.order_id)">查看支付历史</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="订单详情" flex="1">
                    <template slot-scope="scope">
                        <el-button size="small" type="success" @click="openOrderStatusHistoryPage(scope.row.order_id)">查看订单详情</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="订单PDF" flex="1">
                    <template slot-scope="scope">
                        <el-button size="small" type="success" @click="openOrderPdfPage(scope.row.order_id)">查看订单PDF</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>

        <el-dialog title="查看物流时间线" :visible.sync="showOrderStatusHistoryDialog">
            <el-timeline>
                <el-timeline-item
                    v-for="(item, index) in sortedOrderStatusData"
                    :key="index"
                    :timestamp="(item.updated_at)"
                    :color="item.color"
                    :icon="item.icon"
                >
                    <h4>{{ item.order_status }}</h4>
                    <p>最后操作员: {{ item.operator_name }}</p>
                </el-timeline-item>
            </el-timeline>
        </el-dialog>

        <el-dialog title="查看支付时间线" :visible.sync="showPaymentStatusHistoryDialog">
            <el-timeline>
                <el-timeline-item
                    v-for="(item, index) in sortedPaymentStatusData"
                    :key="index"
                    :timestamp="(item.updated_at)"
                    :color="item.color"
                    :icon="item.icon"
                >
                    <h4>{{ item.payment_status }}</h4>
                    <p>最后操作员: {{ item.operator_name }}</p>
                    <p>支付方式: {{ item.payment_method }}</p>
                    <p>应收金额: {{ item.payable_amount }}</p>
                    <p>已付金额: {{ item.paid_amount }}</p>
                </el-timeline-item>
            </el-timeline>
        </el-dialog>

    </div>
</template>

<script>
import { getMostRecentOrderStatusHistory, getOrderStatusHistoryByOrderId, getPaymentStatusHistoryByOrderId } from '@/utils/orders'
import { formatLocalDate } from '@/utils/time_utils';
    export default{
        name:"OrderStatusHistoryTable",
        data() {

            return {
                search: {
                    order_id:  null,
                    alias_order_id:  null,
                    user_id:  null,
                    order_status: null
                },
                orderStatuses: {
                    VUE_APP_ORDER_STATUS_REVIEWING: process.env.VUE_APP_ORDER_STATUS_REVIEWING,
                    VUE_APP_ORDER_STATUS_CONFIRMED: process.env.VUE_APP_ORDER_STATUS_CONFIRMED,
                    VUE_APP_ORDER_STATUS_PACKED: process.env.VUE_APP_ORDER_STATUS_PACKED,
                    VUE_APP_ORDER_STATUS_SHIPPED: process.env.VUE_APP_ORDER_STATUS_SHIPPED,
                    VUE_APP_ORDER_STATUS_LOADED_TRUCK: process.env.VUE_APP_ORDER_STATUS_LOADED_TRUCK,
                    VUE_APP_ORDER_STATUS_AT_CN_PORT: process.env.VUE_APP_ORDER_STATUS_AT_CN_PORT,
                    VUE_APP_ORDER_STATUS_AT_CN_AIRPORT: process.env.VUE_APP_ORDER_STATUS_AT_CN_AIRPORT,
                    VUE_APP_ORDER_STATUS_LOADED_SHIP: process.env.VUE_APP_ORDER_STATUS_LOADED_SHIP,
                    VUE_APP_ORDER_STATUS_LOADED_PLANE: process.env.VUE_APP_ORDER_STATUS_LOADED_PLANE,
                    VUE_APP_ORDER_STATUS_AT_SEA: process.env.VUE_APP_ORDER_STATUS_AT_SEA,
                    VUE_APP_ORDER_STATUS_IN_AIR: process.env.VUE_APP_ORDER_STATUS_IN_AIR,
                    VUE_APP_ORDER_STATUS_AT_DESTINATION_PORT: process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_PORT,
                    VUE_APP_ORDER_STATUS_AT_DESTINATION_AIRPORT: process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_AIRPORT,
                    VUE_APP_ORDER_STATUS_AT_DESTINATION_CUSTOM: process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_CUSTOM,
                    VUE_APP_ORDER_STATUS_AT_DESTINATION_WAREHOUSE: process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_WAREHOUSE,
                    VUE_APP_ORDER_STATUS_DEALIVERING: process.env.VUE_APP_ORDER_STATUS_DEALIVERING,
                    VUE_APP_ORDER_STATUS_DELIVERED: process.env.VUE_APP_ORDER_STATUS_DELIVERED,
                    VUE_APP_ORDER_STATUS_CANCELLED: process.env.VUE_APP_ORDER_STATUS_CANCELLED
                },
                mostRecentOrderStatusData: [],
                orderStatusHistoryData: [],
                paymentStatusHistoryData: [],
                limit: 10,
                page:1,
                total:0,
                showOrderStatusHistoryDialog: false,
                showPaymentStatusHistoryDialog: false,
            };
        },

        async created() {
            await this.loadData();
        },
        methods:{
            formatLocalDate,
            handleSizeChange(val) {
                this.limit = val;
                this.page = 1; // Reset to first page when page size changes
                this.loadData();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.loadData();
            },
            async loadData() {
                try {
                    const response = await getMostRecentOrderStatusHistory({ order_id: this.order_id, user_id: this.user_id, order_status: this.order_status, page: this.page, limit: this.limit });
                    if (response.status === 200) {
                        this.mostRecentOrderStatusData = response.data;
                        this.total = response.data.length === 0 ? 0 : parseInt(response.data[0].number_of_rows);
                        this.$message.success(`获取订单状态历史成功! 返回${response.data.length}条数据`);
                    }
                } catch (error) {
                    console.error(error);
                    this.$message.error(`获取订单状态历史失败! ${error.message}`);
                }
            },
            async searchOrderStatusHistory(){
                const searchCopy = JSON.parse(JSON.stringify(this.search));
                try{
                    const response = await getMostRecentOrderStatusHistory({ ...searchCopy, page: this.page, limit: this.limit });
                    if (response.status === 200) {
                        this.mostRecentOrderStatusData = response.data;
                        this.total = response.data.length === 0 ? 0 : parseInt(response.data[0].number_of_rows);
                        this.$message.success(`搜索订单状态历史成功!, 返回${response.data.length}条数据`);
                    }
                }
               catch (error){
                    this.$message.error(`搜索订单状态历史失败! ${error.message}`);
                }
            },
            clearSearchResult(){
                this.search = {
                    order_id:  null,
                    alias_order_id:  null,
                    user_id:  null,
                    order_status: null,
                    limit: this.limit,
                    page: this.page
                };
                this.page = 1;
                this.loadData();
            },            
            async checkOrderStatusTimeline(orderId){
                // 获取完整历史
                console.log(orderId);
                try {
                    const response = await getOrderStatusHistoryByOrderId(orderId);
                    this.orderStatusHistoryData = response.data;

                } catch (error) {
                    console.error(error);
                    this.$message.error(`获取完整订单历史失败! ${error.message}`);
                }
                this.showOrderStatusHistoryDialog = true;
            },

            async checkPaymentStatusTimeline(orderId){
                // 获取完整历史
                console.log(orderId);
                try {
                    const response = await getPaymentStatusHistoryByOrderId(orderId);
                    this.paymentStatusHistoryData = response.data;

                } catch (error) {
                    this.$message.error(`获取完整支付历史失败! ${error.message}`);
                }
                this.showPaymentStatusHistoryDialog = true;
            },

            async openOrderStatusHistoryPage(orderId) {
                    this.$router.push({
                        name: 'OrderStatusHistoryPage',
                        params: { orderId },
                });
            },
            async openOrderPdfPage(orderId) {
                    this.$router.push({
                        name: 'OrderPdfPage',
                        params: { orderId },
                });
            }



        },
        computed: {
            filteredDatatable() {
            let result = this.mostRecentOrderStatusData;
            
            // First, filter by search strings
            Object.keys(this.search).forEach(key => {
                if (this.search[key] && typeof this.search[key] === 'string') {
                let searchString = this.search[key].replace('*', '.*');
                let regex = new RegExp('^' + searchString, 'i');
                result = result.filter(order => regex.test(String(order[key])));
                }
            });

            // Then, filter out cancelled orders
            // result = result.filter(order => order.order_status !== process.env.VUE_APP_ORDER_STATUS_CANCELLED);

            return result;
            },

            sortedOrderStatusData() {
                const data = [...this.orderStatusHistoryData].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
                data.forEach((item, index) => {
                    item.icon = index === 0 ? '' : 'el-icon-more';
                    item.color = item.order_status === '已取消' ? 'red' : (index === 0 ? 'green' : 'grey');
                });
                return data;
            },
            sortedPaymentStatusData() {
                const data = [...this.paymentStatusHistoryData].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
                data.forEach((item, index) => {
                    item.icon = index === 0 ? '' : 'el-icon-more';
                    item.color = item.payment_status === '已取消' ? 'red' : (index === 0 ? 'green' : 'grey');
                });
                return data;
            },
        },
        watch: {
            search: {
                    handler() {
                        // 检查 search 对象的所有属性是否都为空
                        const isEmpty = Object.values(this.search).every(value => value === null || value === '');
                        if (isEmpty) {
                            this.loadData(); // 如果所有属性都为空，调用 getload 方法
                        }
                    },
                    deep: true // 深度观察 search 对象的所有属性
                }
        },  

    }

</script>

<style scoped>

.search-fields {
      display: flex;
      flex-wrap: nowrap; /* 阻止元素换行 */
      align-items: center;
      gap: 10px; /* 为元素添加间隙 */
      margin-bottom: 20px;
      max-width: 100%; /* 限制宽度为屏幕的一半 */
  }


.el-select, .date-picker-wide {
    width: 50%; /* 限制最大宽度 */
}

.table-container{
    max-height: 80vh; /* Adjust this value according to your needs */
    overflow-x: auto; /* 显示横向滚动条 */
    overflow-y: auto; 
}

.el-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>