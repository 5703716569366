<template>
    <div class="resetPasswordBody">
        <div class="resetPasswordDiv">
            <div class="resetPassword-content">
                <h1 class="resetPassword-title">重置密码</h1>
                <el-form :model="resetPasswordForm" ref="resetPasswordForm" label-width="100px" :rules="rules">
                    <el-form-item class="form-item" label="新密码" prop="newPassword">
                        <el-input type="password" v-model="resetPasswordForm.newPassword" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item class="form-item" label="确认新密码" prop="confirmPassword">
                        <el-input type="password" v-model="resetPasswordForm.confirmPassword" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="submit-button" type="primary" @click="resetPassword">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
import instance from '@/utils/axios.config';
import { Message } from 'element-ui';
  
    

export default {
    name: "ResetPassword",
    data() {
        return {
            resetPasswordForm: {
                newPassword: '',
                confirmPassword: '',
                token: ''
            },
            rules: {
                newPassword: [
                { required: true, message: '请输入新密码', trigger: 'blur' },
                { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[\s\r\n])[a-zA-Z\d!$,. _]{6,20}$/, message: '密码必须至少6位，最长20位，包含大小写字母和数字，只能包含!$,._特殊字符，不能包含空格或回车', trigger: 'blur' }
                ],
                confirmPassword: [
                { required: true, message: '请确认新密码', trigger: 'blur' },
                { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[\s\r\n])[a-zA-Z\d!$,. _]{6,20}$/, message: '密码必须至少6位，最长20位，包含大小写字母和数字，只能包含!$,._特殊字符，不能包含空格或回车', trigger: 'blur' }
                ]
            },   
        };
    },
 
    created() {
        console.log("Token received:", this.$route.params.token);
        this.resetPasswordForm.token = this.$route.params.token; // 从 URL 中获取 token

    },
    mounted() {
    console.log('ResetPassword component has been mounted');
    },
    methods: {
    async resetPassword() {
        this.$refs.resetPasswordForm.validate(async (valid) => {
            if (!valid) return;
            if (this.resetPasswordForm.newPassword !== this.resetPasswordForm.confirmPassword) {
                Message.error('两次输入的密码不一致');
                return;
            }

            try {
                const response = await instance.post(`/auth/reset-password`, {
                    password_token: this.$route.params.token,
                    user_name: this.$route.query.user_name, // 从 URL 参数中获取
                    email: this.$route.query.email, // 从 URL 参数中获取
                    new_password: this.resetPasswordForm.newPassword,
                });

                if (response.status === 200) {
                    Message.success('密码重置成功！');
                    setTimeout(() => {
                        this.$router.push('/Login');
                    },500);
                }
            } catch (error) {
                Message.error('密码重置失败:' + error.response.data.error);
                setTimeout(() => {
                    this.$router.push('/DefaultFailure');
                },500);
            }
        });
    }
}
};
</script>

<style scoped>
    .resetPasswordBody {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #B3C0D1; /* 或者您可以选择不同的背景颜色 */
    }
    .resetPasswordDiv {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 450px;
        height: 330px;
        background: #fff;
        border-radius: 5%;
    }
    .resetPassword-title {
        margin: 20px 0;
        text-align: center;
    }
    .resetPassword-content {
        padding: 25px;
    }
    .form-item {
        margin-bottom: 30px; /* 调整为你需要的值 */
    }
    .submit-button {
        margin-top: 5px; /* 调整为你需要的值 */
    }
</style>