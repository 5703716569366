<template>
    <div>
        
        <div class="search-row">
            <el-button type="danger" icon="el-icon-delete" @click="clearSearchResult">清除搜索</el-button>
            <el-button type="primary" icon="el-icon-search" @click="searchAdminUsers">搜索</el-button>
            <el-input v-model="search.user_name" placeholder="用户名" clearable></el-input>
            <el-input v-model="search.role" placeholder="权限" clearable></el-input>
            <el-input v-model="search.first_name" placeholder="名" clearable></el-input>
            <el-input v-model="search.last_name" placeholder="姓" clearable></el-input>
            <el-input v-model="search.email" placeholder="邮箱" clearable></el-input>
        </div>

        <div class="table-container"> 
            <el-table :data="filteredDatatable"
                :header-cell-style="{background:'#f2f5fc', color:'#555'}" height="70vh"
                border
            >
            <el-table-column type="index" label="序号" fixed/>
            <el-table-column prop="user_name" label="用户名" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.user_name }}
                </template>
                </el-table-column>
                <el-table-column prop="role" label="权限" flex="1">
                <template slot-scope="scope">
                    {{ getRole(scope.row.role) }}
                </template>
                </el-table-column>            
            <el-table-column prop="first_name" label="名" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.first_name }}
            </template>
            </el-table-column>  
            <el-table-column prop="is_disabled" label="是否禁用" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.is_disabled === true ? '✅' : '❌' }}
            </template>
                </el-table-column>
            <el-table-column prop="last_name" label="姓" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.last_name }}
            </template>
            </el-table-column>
            <el-table-column prop="mobile_phone" label="电话" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.mobile_phone }}
                </template>
                </el-table-column>
                <el-table-column prop="email" label="邮箱" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.email }}
                </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="small" type="success" icon="el-icon-edit" @click="editUser(scope.row)" :disabled="!isAuthorisedToEdit(scope.row.role)">编辑</el-button>
                        <el-button size="small" type="danger" icon="el-icon-delete" @click="confirmDeletion(scope.row.user_name)" :disabled="!isAuthorisedToDelete(scope.row.role, scope.row.user_name)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        
        
        <el-pagination
        @size-blur="handleSizeblur"
        @current-blur="handleCurrentblur"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <el-dialog title="编辑用户" :visible.sync="showEditDialog" :close-on-click-modal="false">
            <el-form :model="editingUser" :rules="rules" ref="form">
                <el-form-item label="名" prop="first_name">
                    <el-input v-model="editingUser.first_name"></el-input>
                </el-form-item>
                <el-form-item label="姓" prop="last_name">
                    <el-input v-model="editingUser.last_name"></el-input>
                </el-form-item>
                <el-form-item label="权限" prop="role">
                    <el-radio-group v-model="editingUser.role" :disabled="role < editingUser.role || editingUser.user_name === currentUser">
                        <el-radio :label="400" :disabled="maxRole < 400">超级管理员</el-radio>
                        <el-radio :label="300" :disabled="maxRole < 300">管理员</el-radio>
                        <el-radio :label="200" :disabled="maxRole < 200">经理</el-radio>
                        <el-radio :label="100" :disabled="maxRole < 100">客服</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="电话" prop="mobile_phone">
                    <el-input v-model="editingUser.mobile_phone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="editingUser.email"></el-input>
                </el-form-item>
                <el-form-item label="禁用" prop="is_disabled">
                    <el-radio-group v-model="editingUser.is_disabled" :disabled="role < editingUser.role || editingUser.user_name === currentUser">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="showEditDialog = false">取消</el-button>
                <el-button type="primary" @click="updateUser">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import instance from '@/utils/axios.config';
      
    export default{
        name:"AdminUsersTable",
        data() {

            return {
                originalDatatable: [],
                limit: 10,
                page:1,
                total:0,
                users: [],
                role: null,
                maxRole: null,
                currentUser: null,     
                editingUser: {
                    first_name: '',
                    last_name: '',
                    mobile_phone: '',
                    email: '',
                    role: '',
                    is_disabled: ''
                },
                search:{
                    user_name: '',
                    first_name: '',
                    last_name: '',
                    mobile_phone: '',
                    email: '',
                    role: ''
                },
                roleMap: {
                    '超级管理员': 400,
                    '管理员': 300,
                    '经理': 200,
                    '客服': 100,
                // 其他角色...
                },                
                showEditDialog: false,
                rules: {
                    first_name: [
                        { required: true, message: '请输入名', trigger: 'blur' },
                        // { pattern: /^[A-Za-z0-9]+$/, message: '名只能包含字母和数字', trigger: 'blur' }
                    ],
                    last_name: [
                        { required: true, message: '请输入姓', trigger: 'blur' },
                        // { pattern: /^[A-Za-z0-9]+$/, message: '姓只能包含字母和数字', trigger: 'blur' }
                    ],
                    mobile_phone: [
                        { required: true, message: '请输入电话号码', trigger: 'blur' },
                        // { pattern: /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[8,9])\d{8}$/, message: '无效的电话号码, 正确的格式应为13、14、15、16、17、18、19开头的11位数字', trigger: 'blur' }
                    ],
                    email: [
                        { required: true, message: '请输入邮箱', trigger: 'blur' },
                        { type: 'email', message: '无效的邮箱', trigger: 'blur' }
                    ]
                }

            };
        },
        created() {
            // Get role from localStorage when the component is created
            this.role = localStorage.getItem('role');
            this.currentUser = localStorage.getItem('user_name');
        },
        computed: {
            filteredDatatable() {
                let result = this.originalDatatable;
                Object.keys(this.search).forEach(key => {
                    if (this.search[key]) {
                        if (key === 'role') {
                            // 将角色名称转换为对应的权限值
                            let roleValue = this.roleMap[this.search[key]];
                            let regex = new RegExp('^' + roleValue, 'i'); // 创建正则表达式，'i' 表示不区分大小写
                            result = result.filter(user => regex.test(String(user[key])));
                        } else {
                            let searchString = this.search[key].replace('*', '.*'); // 将 * 替换为 .*
                            let regex = new RegExp('^' + searchString, 'i'); // 创建正则表达式，'i' 表示不区分大小写
                            result = result.filter(user => regex.test(String(user[key])));
                        }
                    }
                });
                return result;
            }
        },
        methods:{
            getRole(role) {
                switch (role) {
                    case 400:
                        return '超级管理员';
                    case 300:
                        return '管理员';
                    case 200:
                        return '经理';
                    case 100:
                        return '客服';
                    default:
                        return '未知';
                }
            },

            handleSizeChange(val) {
                this.limit = val;
                this.page = 1; // Reset to first page when page size changes
                this.loadData();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.loadData();
            },
            loadData() {
                instance.get(`/admins/admin_users/all`, {

                }).then(response => {
                    this.originalDatatable = response.data.data;
                    this.total = response.data.data.length === 0 ? 0 : parseInt(response.data.data[0].number_of_rows);
                }).catch(error => {
                    console.error(error);
                    // 处理令牌过期或其他错误，例如提示用户重新登录
                });
            },

            editUser(row) {
                this.editingUser = JSON.parse(JSON.stringify(row));
                this.maxRole = Math.max(this.role, row.role);
                this.showEditDialog = true;
            },

            updateUser() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        const user = this.editingUser;
                        const user_name = user.user_name

                        instance.post(`/admins/admin_users/update/user_name/${user_name}`, {
                            first_name: user.first_name,
                            last_name: user.last_name,
                            mobile_phone: user.mobile_phone,
                            email: user.email,
                            role: user.role,
                            is_disabled: user.is_disabled
                        }, 
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).then(response => {
                            // 处理响应
                            if (response.data.status === 200) {
                                // 更新成功操作
                                this.$message({
                                    message: `更新成功`,
                                    type: 'success'
                            });  
                                this.showEditDialog = false;
                                this.loadData();
                            }
                        }).catch(error => {
                            // 错误处理
                            console.error(error);
                            // 根据error.response.status显示相应的错误信息
                            this.$message({
                                message: `更新失败: ${error.response.data.error}`,
                                type: 'error'
                            });       
                        });
                    } else {
                        this.$message({
                            message: '请检查输入',
                            type: 'warning'
                        });
                        return false;
                    }
                });
            },

            confirmDeletion(user_name) {
                // Show a confirmation dialog
                this.$confirm('确定要删除这个用户吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    // If the user confirmed the deletion, delete the order
                    this.deleteUser(user_name);
                })
                .catch(() => {
                    // If the user cancelled the deletion, do nothing
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });
            },

            deleteUser(user_name){
                instance.delete(`/admins/admin_users/delete/user_name/${user_name}`, {
                }).then(response => {
                    // 处理响应
                    if (response.data.status === 200) {
                        // 更新成功操作
                        this.loadData();
                        this.$message({
                            message: `成功删除用户${user_name}`,
                            type: 'success'
                        });
                    }
                }).catch(error => {
                    // 错误处理
                    console.error(error);
                    // 根据error.response.status显示相应的错误信息
                    this.$message({
                        message: `删除失败: ${error}: ${error.response.data.error}`,
                        type: 'error'
                    });
                });
            },
            searchAdminUsers() {

                const searchCopy = Object.assign({}, this.search);
                // 将角色名称转换为对应的整数值
                let roleValue = this.roleMap[searchCopy.role];
                if (roleValue !== undefined) {
                    searchCopy.role = roleValue;
                } 
                // else {
                // // 如果用户输入的角色名称不在映射中，你可以显示一个错误消息，或者使用默认值...
                //     roleValue = 100;
                //     searchCopy.role = roleValue;
                //     this.$message({
                //         message: `无效的角色: 使用默认权限 ${this.getRole(searchCopy.role)}`,
                //         type: 'error'
                //     });
                //         // return;
                // }

                instance.get(`/admins/admin_users`,  {
                    params:{
                    user_name: searchCopy.user_name,
                    first_name: searchCopy.first_name,
                    last_name: searchCopy.last_name,
                    mobile_phone: searchCopy.mobile_phone,
                    role: roleValue
                }
            })
                .then(response => {
                    this.$message.success(`搜索成功, 返回 ${response.data.data.length} 条结果`)
                    this.originalDatatable = response.data.data;
                    this.total = response.data.data.length === 0 ? 0 : parseInt(response.data.data[0].number_of_rows);
                })
                .catch(error => {
                    this.$message({
                        message: `搜索失败: ${error.response.data.error} 请清除重新再试`,
                        type: 'error'
                    });
                // 处理错误，例如提示用户重新登录
                this.originalDatatable = [];
                });
            },
            clearSearchResult() {
                this.search = {
                    user_name: '',
                    mobile_phone: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    role: ''
                };
                this.page = 1;
                this.loadData();
            },            

            isAuthorisedToEdit(role) {
                return (this.role >= role && this.role >= 300) 
            },

            isAuthorisedToDelete(role, user_name) {
                return (this.role >= role && this.role >= 300 && this.currentUser !== user_name);
            }
        },

        beforeMount(){
            this.loadData();
        },
        watch: {
            search: {
                handler() {
                    // 检查 search 对象的所有属性是否都为空
                    const isEmpty = Object.values(this.search).every(value => value === '');
                    if (isEmpty) {
                        this.loadData(); // 如果所有属性都为空，调用 getload 方法
                    }
                },
                deep: true // 深度观察 search 对象的所有属性
            }
        },        
    }

</script>

<style scoped>

.search-row {
    display: flex;
    flex-wrap: nowrap; /* 阻止元素换行 */
    align-items: center;
    gap: 10px; /* 为元素添加间隙 */
    margin-bottom: 20px;
    max-width: 100%; /* 限制宽度为屏幕的一半 */
}

.el-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.table-container {
    max-height: 80vh;  /* 你可以根据需要调整这个值 */
    overflow-x: auto; /* 显示横向滚动条 */
    overflow-y: auto; 
}
</style>