<template>
  <div>
    <!-- 输入和搜索区域 -->
    <div class="search-fields">
      <el-button type="danger" icon="el-icon-delete" @click="clearSearchResult">清除搜索</el-button>
        <el-button type="primary" icon="el-icon-search" @click="searchOrder">搜索</el-button>
        <el-input v-model.lazy="search.order_id" placeholder="搜索订单ID" clearable></el-input>
        <el-input v-model.lazy="search.alias_order_id" placeholder="搜索运单号" clearable></el-input>
        <el-input v-model.lazy="search.user_id" placeholder="搜索用户ID" clearable ></el-input>
        <el-select v-model.lazy="search.payment_status" placeholder="搜索支付状态" clearable>
          <el-option label="全部" value=""></el-option>
          <el-option label="待支付" value="待支付"></el-option>
          <el-option label="已支付" value="已支付"></el-option>
        </el-select>

      <el-date-picker v-model.lazy="search.created_from" placeholder="创建日期从" type="date"  class="date-picker-wide" clearable></el-date-picker>
      <el-date-picker v-model.lazy="search.created_to" placeholder="创建日期到" type="date" class="date-picker-wide" clearable></el-date-picker>

    </div>

    <el-select v-model="order_status" clearable placeholder="选择订单状态" class="el-select">
        <el-option v-for="(status, index) in orderStatuses" :key="index" :label="status" :value="status"></el-option>
        <!-- Add more options as needed -->
      </el-select>

  <div class="table-container">
    <!-- 表格展示数据 -->
    <el-table :data="filteredDatatable" :header-cell-style="{background:'#f2f5fc', color:'#555'}" height="70vh" border>
      <!-- 列定义 -->
      <el-table-column type="index" label="序号" fixed/>
      <el-table-column prop="order_id" label="订单ID" flex="1"></el-table-column>
      <el-table-column prop="alias_order_id" label="运单号" fixed></el-table-column>
      <el-table-column prop="user_id" label="用户ID" fixed></el-table-column>
      <el-table-column prop="length" label="长(m)" flex="1"></el-table-column>
      <el-table-column prop="width" label="宽(m)" flex="1"></el-table-column>
      <el-table-column prop="height" label="高(m)" flex="1"></el-table-column>
      <el-table-column prop="total_weight" label="总重量(kg)" flex="1"></el-table-column>
      <el-table-column prop="total_volume" label="总体积(m3)" flex="1"></el-table-column>
      <el-table-column prop="price" label="价格" flex="1"></el-table-column>
      <el-table-column prop="total_declared_value" label="申报总价值" flex="1"></el-table-column>
      <el-table-column prop="order_status" label="订单状态" flex="1"></el-table-column>
      <el-table-column prop="payment_status" label="支付状态" flex="1"></el-table-column>
      <el-table-column prop="payment_method" label="付款方式" flex="1"></el-table-column>
      <el-table-column prop="updated_at" label="更新时间" flex="1" :formatter="row => (row.updated_at)"></el-table-column>
      <el-table-column prop="created_at" label="创建时间" flex="1" :formatter="row => (row.created_at)"></el-table-column>
      <el-table-column prop="sender_address" label="寄件人地址" flex="1"></el-table-column>
      <el-table-column prop="sender_mobile" label="寄件人电话" flex="1"></el-table-column>
      <el-table-column prop="receiver" label="收件人" flex="1"></el-table-column>
      <el-table-column prop="receiver_address" label="收件人地址" flex="1"></el-table-column>
      
      <el-table-column prop="receiver_state" label="收件人州" flex="1"></el-table-column>
      <el-table-column prop="receiver_postcode" label="收件人邮编" flex="1"></el-table-column>
      <el-table-column prop="receiver_mobile" label="收件人手机" flex="1"></el-table-column>
      <el-table-column prop="receiver_email" label="收件人邮箱" flex="1"></el-table-column>
      <el-table-column prop="shipping_method" label="运输方式" flex="1"></el-table-column>
      <el-table-column prop="delivery_method" label="送货方式" flex="1"></el-table-column>
      <el-table-column prop="确认收款" label="确认收款">
        <template slot-scope="scope">
          <el-button round size="small" icon="el-icon-check" @click="confirmPayments(scope.row.order_id, scope.row.user_id)"></el-button>
        </template>
      </el-table-column>
      <el-table-column label="转移运输状态" flex="1">
        <template slot-scope="scope">
          <el-select 
            v-model="selectedStatuses[scope.row.order_id]"  
            placeholder="更改状态" 
            clearable 
            @change="(value) => showDialog(value, scope.row.order_id, scope.row.user_id, scope.row.shipping_method, scope.row.order_status, scope.row.alias_order_id)" 
            @click.native="selectedStatuses[scope.row.order_id] = null"
            v-if="scope.row.shipping_method"
          >
            <el-option
              v-for="(status, index) in filteredStatuses(scope.row.shipping_method)"
              :key="index"
              :label="status"
              :value="status"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <!-- 到了送货中状态可以转移到已送达 -->
      <el-table-column v-if="order_status === '送货中'" prop="confirmReceived" label="确认收货">
        <template slot-scope="scope">
          <el-button round size="small"  type="success" icon="el-icon-check" @click="completeOrders({ orderId:scope.row.order_id, userId:scope.row.user_id, oldStatus: scope.row.order_status ,aliasOrderId: scope.row.alias_order_id })"></el-button>
        </template>
      </el-table-column>
    </el-table>  
  </div>  
    <!-- 分页组件 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <el-dialog
      title="Confirm transition"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <span>确认转移状态吗? 转移将不可逆</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog()">Cancel</el-button>
        <el-button type="primary" @click="() => updateInTransitOrders()">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
  import { formatLocalDate, convertToMelbourneTime } from '@/utils/time_utils';
  import { updateInTransitOrder, confirmPayment, searchOrders, changeOrderStatus } from '../../utils/orders';

  export default {
    name: "ShippedOrdersTable",
    data() {
      return {
        search: {
                  order_id:  null,
                  alias_order_id:  null,
                  user_id:  null,
                  created_from:  null,
                  payment_status: null,
                  created_to:  null,
                  limit: this.limit,
                  page: this.page
        },
        ordersData: [],
       limit: 10,
        page: 1,
        total: 0,
        // 随着需求变化，这里的状态可能会变化
        order_status: process.env.VUE_APP_ORDER_STATUS_SHIPPED,
        orderStatuses: [
          process.env.VUE_APP_ORDER_STATUS_SHIPPED,
          process.env.VUE_APP_ORDER_STATUS_LOADED_TRUCK,
          process.env.VUE_APP_ORDER_STATUS_AT_CN_PORT,
          process.env.VUE_APP_ORDER_STATUS_AT_CN_AIRPORT,
          process.env.VUE_APP_ORDER_STATUS_LOADED_SHIP,
          process.env.VUE_APP_ORDER_STATUS_LOADED_PLANE,
          process.env.VUE_APP_ORDER_STATUS_AT_SEA,
          process.env.VUE_APP_ORDER_STATUS_IN_AIR,
          process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_PORT,
          process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_AIRPORT,
          process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_CUSTOM,
          process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_WAREHOUSE,
          process.env.VUE_APP_ORDER_STATUS_DEALIVERING
      ],
        orderStatusAir: [
          process.env.VUE_APP_ORDER_STATUS_SHIPPED,
          process.env.VUE_APP_ORDER_STATUS_LOADED_TRUCK,
          process.env.VUE_APP_ORDER_STATUS_AT_CN_AIRPORT,
          process.env.VUE_APP_ORDER_STATUS_LOADED_PLANE,
          process.env.VUE_APP_ORDER_STATUS_IN_AIR,
          process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_AIRPORT,
          process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_CUSTOM,
          process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_WAREHOUSE,
          process.env.VUE_APP_ORDER_STATUS_DEALIVERING
        ],
        orderStatusSea: [
          process.env.VUE_APP_ORDER_STATUS_SHIPPED,
          process.env.VUE_APP_ORDER_STATUS_LOADED_TRUCK,
          process.env.VUE_APP_ORDER_STATUS_AT_CN_PORT,
          process.env.VUE_APP_ORDER_STATUS_LOADED_SHIP,
          process.env.VUE_APP_ORDER_STATUS_AT_SEA,
          process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_PORT,
          process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_CUSTOM,
          process.env.VUE_APP_ORDER_STATUS_AT_DESTINATION_WAREHOUSE,
          process.env.VUE_APP_ORDER_STATUS_DEALIVERING
        ],
      
        popoverVisible: false,
        dialogVisible: false,
        // 修改订单状态的可选状态
        selectedStatuses: {},
        selectedStatus: null,
        currentOrderId: null,
        currentOrderStatus: null,
        currentAliasOrderId: null,
        currentUserId: null,
        currentShippingMethod: null,
        previousStatus: null,

      };
    },
    async created() {
        this.loadData();
    },  

    methods: {  
      formatLocalDate,
      convertToMelbourneTime,
      async loadData() {
          try{
              const response = await searchOrders({ page: this.page, limit: this.limit, order_status: this.order_status});
              if (response.status === 200) {
                  this.ordersData = response.data;
                  this.total = response.data.length === 0 ? 0 : parseInt(response.data[0].number_of_rows);
              }

          }catch (error) {
              this.$message.error(`获取订单失败! ${error.response.data.error}`);
          }   
      },      
      async searchOrder() {
          try {
              // 创建一个搜索参数对象，包含所有可能的搜索参数
              let searchParams = JSON.parse(JSON.stringify(this.search));

              // 移除空的搜索参数
              Object.keys(searchParams).forEach(key => {
                  if (searchParams[key] === '') {
                      delete searchParams[key];
                  }
              });
              console.log("searchParams",searchParams)

              // 使用 searchOrders 函数发送请求
              const response = await searchOrders({
                    ... searchParams,                    
                    limit : this.limit,
                    page: this.page
                });
                if (response.status === 200) {
                    this.ordersData = response.data;
                    this.total = response.data.length === 0 ? 0 : parseInt(response.data[0].number_of_rows);
                    this.$message.success(`搜索订单成功! 返回${this.total} 条结果`);
                    if (this.total === 0) {
                        this.page = 1; // 如果没有数据，重置页码为 1
                    }
                }
          } catch (error) {
              this.$message.error(`搜索订单失败! ${error.response.data.error}`);
          }
      },      
      clearSearchResult(){
        this.search = {
            order_id:  null,
            alias_order_id:  null,
            user_id:  null,
            created_from:  null,
            payment_status: null,
            created_to:  null,
            limit: this.limit,
            page: this.page
        };
        this.page = 1;
        this.loadData();
      },

      cancelDialog() {
        this.dialogVisible = false;
        this.selectedStatuses[this.currentOrderId] = null;
        this.currentAliasOrderId = null;
        this.currentOrderStatus = null;
        console.log("cancel dialog", this.currentOrderId, this.currentUserId, this.selectedStatuses[this.currentOrderId], this.currentAliasOrderId, this.currentOrderStatus)
        // this.currentOrderId = null;
      },
      showDialog(value, orderId, userId, shipping_method, order_status, currentAliasOrderId) {
        if (value === null || value === undefined) {
          // The user cleared the selection, so don't show the dialog.
          return;
      }
          this.currentOrderId = orderId;
          this.currentUserId = userId;
          this.currentShippingMethod = shipping_method;
          this.selectedStatuses[orderId] = value;
          this.currentOrderStatus = order_status
          this.currentAliasOrderId = currentAliasOrderId;
          this.dialogVisible = true;
          
      },  

        filteredStatuses(shipping_method) {
          // 过滤不可以用的状态
          if (shipping_method === process.env.VUE_APP_SHIPPING_METHOD_SEA || shipping_method === process.env.VUE_APP_SHIPPING_METHOD_SEA_SENSITIVE) {
            return this.orderStatusSea.filter(status => status !== this.order_status) ;
          } else if (shipping_method === process.env.VUE_APP_SHIPPING_METHOD_AIR || shipping_method === process.env.VUE_APP_SHIPPING_METHOD_AIR_SENSITIVE) {
            return this.orderStatusAir.filter(status => status !== this.order_status) ;
          } else {
            return this.orderStatuses;
          }
        },
        // Your existing methods...

      async updateInTransitOrders() {
        try {
          const response = await updateInTransitOrder({
            orderId: this.currentOrderId, 
            alias_order_id: this.currentAliasOrderId,
            userId: this.currentUserId, 
            old_status: this.currentOrderStatus,
            new_status: this.selectedStatuses[this.currentOrderId]
          });
          if (response.status === 200) {
            this.$message({
              message: '订单状态转移成功',
              type: 'success'
            });
            this.dialogVisible = false;
            this.popoverVisible = false;
            this.loadData();
            this.selectedStatuses[this.currentOrderId] = null;

          } 
        } catch (error) {
          this.$message({
              message: `订单状态转移失败 ${error}`,
              type: 'error'
            });
            this.dialogVisible = false;
            this.popoverVisible = false;
            this.selectedStatuses[this.currentOrderId] = null;
        }
        
  
      },

      confirmPayments(orderId, userId) {
            // Show a confirmation dialog
            this.$confirm('确认转移这个订单为已支付吗?操作将不可逆', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            .then(async () => {
                try {
                  const res = await confirmPayment(orderId, userId);
                  if (res.status === 200){
                    this.$message.success('操作成功');
                    this.loadData();
                  }
                } catch (error) {
                  this.$message.error(`操作失败: ${error.message}`);
                }             
            })
            .catch(() => {
                // If the user cancelled the deletion, do nothing
                this.$message.info('操作取消');
            });
        },

      completeOrders({orderId, userId, oldStatus, aliasOrderId}) {
            // Show a confirmation dialog
            this.$confirm('完成这个订单吗?操作将不可逆', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            .then(async () => {
                try {
                  const response = await changeOrderStatus({orderId, userId, aliasOrderId, old_status: oldStatus, new_status: process.env.VUE_APP_ORDER_STATUS_DELIVERED});
                  if (response.status === 200){
                    this.$message.success('操作成功');
                    this.loadData();
                  }
                } catch (error) {
                  this.$message.error(`操作失败: ${error.message}`);
                }             
            })
            .catch(() => {
                // If the user cancelled the deletion, do nothing
                this.$message.info('操作取消');
            });
        },
      handleSizeChange(val) {
        this.limit = val;
        this.page = 1; // Reset to first page when page size changes
        this.loadData();
      },
      handleCurrentChange(val) {
        this.page = val;
        this.loadData();
      }
    },
    
    watch: {
      async order_status() {
        this.loadData();
        this.total = this.ordersData.length;
      },

      search: {
            handler() {
                // 检查 search 对象的所有属性是否都为空
                const isEmpty = Object.values(this.search).every(value => value === '');
                if (isEmpty) {
                this.loadData(); // 如果所有属性都为空，调用 getload 方法
                }
            },
            deep: true // 深度观察 search 对象的所有属性
        }

    },
    computed: {
        filteredDatatable() {
            let result = this.ordersData;
            // First, filter by search strings
            Object.keys(this.search).forEach(key => {
                if (this.search[key] && typeof this.search[key] === 'string') {
                    let searchString = this.search[key].replace('*', '.*');
                    let regex = new RegExp('^' + searchString, 'i');
                    result = result.filter(order => regex.test(String(order[key])));
                }
            });

            // Then, filter by date range
            if (this.search.created_from || this.search.created_to) {
                result = result.filter(order => {
                    let created_from = this.search.created_from ? convertToMelbourneTime(this.search.created_from) : null;
                    let created_to = this.search.created_to ? convertToMelbourneTime(this.search.created_to) : null;
                    let orderCreateFrom = convertToMelbourneTime(order.created_at);
                    let orderCreateTo = convertToMelbourneTime(order.created_at);

                    if (created_from && orderCreateFrom < created_from) return false;
                    if (created_to && orderCreateTo > created_to) return false;

                    return true;
                });
            }

            return result;
        }
    },    


  }
  </script>
  
  <style scoped>
  .search-fields {
      display: flex;
      flex-wrap: nowrap; /* 阻止元素换行 */
      align-items: center;
      gap: 10px; /* 为元素添加间隙 */
      margin-bottom: 20px;
      max-width: 100%; /* 限制宽度为屏幕的一半 */
  }

  
  .el-select, .date-picker-wide {
      width: 50%; /* 限制最大宽度 */
  }
  
  .table-container {
    margin-top: 20px;
    overflow-x: auto; /* 显示横向滚动条 */
    overflow-y: auto; 
  }
  
  .el-table-column {
    text-align: center; 
  }
  
  .el-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .search-row {
      flex-direction: column;
    }
  
    .el-input, .el-select, .el-date-picker {
      max-width: none;
    }
  }
  </style>