<template>
    <el-row type="flex" justify="center">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>个人页面</span>
          </div>
          <el-row type="flex" class="row-bg" justify="start">
            <el-col :span="24"><p>用户名: {{ userInfo.user_name }}</p></el-col>
            <el-col :span="24"><p>邮箱: {{ userInfo.email }}</p></el-col>
            <el-col :span="24"><p>电话: {{ userInfo.mobile_phone }}</p></el-col>
            <el-col :span="24"><p>角色: {{ roleMap[userInfo.role] }}</p></el-col>

          </el-row>
          <el-col :span="24"><p>姓: {{ userInfo.first_name }}</p></el-col>
            <el-col :span="24"><p>名: {{ userInfo.last_name }}</p></el-col>

          <el-button type="danger" @click="confirmResetPassword">重置密码</el-button>
        </el-card>
      </el-col>
    </el-row>
  </template>

<script>
import instance from '@/utils/axios.config'; // Adjust the path as necessary
import { MessageBox } from 'element-ui';

export default {
  data() {
    return {
        userInfo: [],
        roleMap: {
                    400: '超级管理员',
                    300:'管理员',
                    200: '经理',
                    100: '客服',
                // 其他角色...
            },  
    };
  },
  created() {
    this.loadData();
  },
  methods: {

     loadData() {
            instance.get(`/admins/admin_users?user_name=${localStorage.getItem('user_name')}&role=${localStorage.getItem('role')}`, {

            }).then(response => {
                this.userInfo = response.data.data[0];
            }).catch(error => {
                console.error(error);
                // 处理令牌过期或其他错误，例如提示用户重新登录
            });
        },

        confirmResetPassword() {
      MessageBox.confirm('确定要重置密码吗?', '重置密码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.sendResetPasswordEmail();
      }).catch(() => {
        // 用户取消操作
      });
    },
    sendResetPasswordEmail() {
      instance.post(`/auth/forget-password`, {
        user_name: this.userInfo.user_name,
        email: this.userInfo.email,
      })
      .then(response => {
        if (response.status === 200) {
          this.$message({
            type: 'success',
            message: '重置密码邮件已发送，请检查您的邮箱。',
          });
        }
      })
      .catch(error => {
        console.error('Error sending reset password email:', error);
        this.$message.error('发送重置密码邮件失败。');
      });
    },
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>