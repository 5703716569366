<template>
    <div>

     <div class = "search-row">
    <el-button type="success" clearable icon="el-icon-edit" @click="showEditDialog = true; is_editing = false" :disabled="role < 300">添加新公告</el-button>

      </div>

    
        <div class="table-container"> 
            <el-table :data="originalDatatable"
                :header-cell-style="{background:'#f2f5fc', color:'#555'}" height="70vh"
                border
            >
            <el-table-column type="index" label="序号" fixed/>
            <el-table-column prop="_id" label="记录id" flex="1" ></el-table-column>
            <el-table-column prop="text_field_name" label="文字栏名" flex="1" ></el-table-column>
            <el-table-column prop="text" label="文字" flex="1"></el-table-column>
            <el-table-column prop="updated_at" label="更新时间" flex="1" :formatter="row => (row.updated_at)"></el-table-column>
            <el-table-column prop="created_at" label="创建时间" flex="1" :formatter="row => (row.created_at)"></el-table-column>

            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button size="small" type="success" icon="el-icon-edit" @click="editDocs(scope.row)" :disabled="role < 300">编辑</el-button>
                    <el-button size="small" type="danger" icon="el-icon-delete" @click="confirmDeletion(scope.row._id)" :disabled="role < 300">删除</el-button>
                </template>
            </el-table-column>
            </el-table>
        </div>
        
        
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <el-dialog title="编辑公告" :visible.sync="showEditDialog" :close-on-click-modal="false">
            <el-form :model="editingDoc" :rules="rules" ref="editingDocForm">
                <el-form-item label="记录id" prop="_id">
                    <el-input v-model="editingDoc._id" readonly v-if="is_editing"></el-input>
                    <el-input v-model="editingDoc._id" v-else></el-input>
                </el-form-item>
                <el-form-item label="文字栏名" prop="text_field_name">
                    <el-input v-model="editingDoc.text_field_name" maxlength="20" show-word-limit readonly v-if="is_editing"></el-input>
                    <el-input v-model="editingDoc.text_field_name" maxlength="20" show-word-limit v-else></el-input>
                </el-form-item>
                <el-form-item label="文字" prop="text">
                    <el-input v-model="editingDoc.text" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetEditingDoc()">取消</el-button>
                <el-button type="primary" @click="is_editing ? updateDoc() : insertNewDoc()">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import { formatLocalDate } from '@/utils/time_utils';
    import { getCollections, updateRecord, deleteRecord, insertRecord } from '@/utils/cloudebase/docs';
    import { getRole } from '@/utils/auth';
    export default{
        name:"DocsTable",
        data() {

            return {
                originalDatatable: [],
                env: process.env.VUE_APP_ENV,
                collection_name: process.env.VUE_APP_COLLECTION_NAME_DOCS,
                limit: 10,
                page:1,
                total:0,
                users: [],
                role: null,
                maxRole: null,
                currentUser: null,     
                editingDoc: {
                    _id: null,
                    created_at: null,
                    text_field_name: null,
                    text: null
                },
                search:{
                },
                roleMap: {
                    '超级管理员': 400,
                    '管理员': 300,
                    '经理': 200,
                    '客服': 100,
                // 其他角色...
                },                
                showEditDialog: false,
                is_editing: false,
                rules: {
                    text_field_name: [
                        { required: true, message: '请输入文字栏名', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
                        { validator: this.noNumberValidator, trigger: 'blur' }
                    ]
                }

            };
        },
        async created() {
            // Get role from localStorage when the component is created
            const { role, currentUser } = getRole();
            this.role = role;
            this.currentUser = currentUser;
            // Wait for a second before loading data
            // this.loadData();
            setTimeout(() => {
                this.loadData();
                this.$message.info('加载数据中，请稍候...')
            }, 1000);
        },
    
  
        methods:{
            formatLocalDate,
            getCollections,
            getRole,

            async loadData() {
               
                try {
                    console.log(this.collection_name, this.limit, this.page)
                    const response = await getCollections({collection_name: this.collection_name, limit: this.limit, page: this.page});
                    
                    if (response.status === 200) {
                        console.log("loadData",response.records.data);
                        this.originalDatatable = response.records.data;
                        this.$message.success(`成功加载数据，返回 ${response.records.data.length} 条信息`);
                        this.total = response.records.data.length;
  
                    }
                    
                } catch (error) {
                    console.error(error);
                    this.$message.error(`加载数据失败: ${error.message}`);
                }
            },
            editDocs(doc){
                // 存储要编辑的物品信息
                this.editingDoc = JSON.parse(JSON.stringify(doc));
                this.is_editing = true;
                // 打开模态窗口或导航到编辑页面
                this.showEditDialog = true;
            },
            async insertNewDoc() {
                // 验证表单
                this.$refs['editingDocForm'].validate(async (valid) => {
                    if (!valid) {
                        this.$message.error('请检查输入是否正确');
                        return;
                    }
                    // 复制一个新的对象，避免直接修改原始对象
                    const doc = JSON.parse(JSON.stringify(this.editingDoc));
                    // console.log("updateDoc: ", doc);
                    try {
                        // 更新用户信息
                        const response = await insertRecord({ collection_name: this.collection_name, _id: doc._id, new_record: doc });
                        // 处理响应
                        if (response.status === 200) {
                            // 更新成功操作
                            this.$message({
                                message: `成功创建记录, _id: ${response.data.res.id}`,
                                type: 'success'
                            });
                            this.loadData();
                            this.showEditDialog = false;
                            this.resetEditingDoc();
                        }
                    } catch (error) {
                        // 错误处理
                        console.error(error);
                        // 根据error.response.status显示相应的错误信息
                        this.$message({
                            message: `更新失败: ${error}: ${error.response.data.error}`,
                            type: 'error'
                        });
                    }
                });
            },
            async updateDoc(){
                // 复制一个新的对象，避免直接修改原始对象
                const doc = JSON.parse(JSON.stringify(this.editingDoc));
                console.log("updateDoc: ",doc);
                try {
                    // 更新用户信息
                    const response = await updateRecord({ collection_name: this.collection_name, _id: doc._id, new_record: doc});
                    // 处理响应
                    if (response.status === 200) {
                        // 更新成功操作
                        this.$message({
                            message: `成功更新文档${this.editingDoc._id}`,
                            type: 'success'
                        });
                        this.loadData();
                        this.showEditDialog = false;
                        this.resetEditingDoc();
                    }
                } catch (error) {
                    // 错误处理
                    console.error(error);
                    // 根据error.response.status显示相应的错误信息
                    this.$message({
                        message: `更新失败: ${error}: ${error.response.data.error}`,
                        type: 'error'
                    });
                }
            },
          
            confirmDeletion(doc_id) {
                // Show a confirmation dialog
                this.$confirm('确定要删除这个文档条目吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    // If the user confirmed the deletion, delete the order
                    this.deleteRecords(doc_id);
                })
                .catch(() => {
                    // If the user cancelled the deletion, do nothing
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });
            },

            async deleteRecords(doc_id){
                try {
                    const response = await deleteRecord({collection_name: this.collection_name, _id: doc_id});

                    // 处理响应
                    if (response.data.status === 200) {
                        // 更新成功操作
                        this.$message({
                            message: `成功删除文档条目${doc_id}`,
                            type: 'success'
                        });
                        this.loadData();
                    }
                } catch (error) {
                    // 错误处理
                    console.error(error);
                    // 根据error.response.status显示相应的错误信息
                    this.$message({
                        message: `删除失败: ${error}: ${error.response.data.error}`,
                        type: 'error'
                    });
                }
            },


            handleSizeChange(val) {
                this.limit = val;
                this.page = 1; // Reset to first page when page size changes
                this.loadData();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.loadData();
            },
            resetEditingDoc() {
                this.editingDoc = {
                    _id: null,
                    created_at: null,
                    text_field_name: null,
                    text: null
                };
                // Close the editing window
                this.showEditDialog = false;
                this.$message.info('已取消编辑');
            },
            noNumberValidator(rule, value, callback) {
                if (/\d/.test(value)) {
                    callback(new Error('不能包含数字'));
                } else {
                    callback();
            }
            }                        
        },
        computed: {
            isProdEnvironment() {
                return process.env.VUE_APP_ENV === 'prod';
            }
        }
                
    }

</script>

<style scoped>

.search-row {
    display: flex;
    flex-wrap: nowrap; /* 阻止元素换行 */
    align-items: center;
    gap: 10px; /* 为元素添加间隙 */
    margin-bottom: 20px;
    max-width: 100%; /* 限制宽度为屏幕的一半 */
}

.el-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.table-container {
    max-height: 80vh;  /* 你可以根据需要调整这个值 */
    overflow-x: auto; /* 显示横向滚动条 */
    overflow-y: auto; 
}

.el-input__inner[readonly] {
    background-color: #f5f7fa;
    color: #c0c4cc;
}
</style>