<template>
  <div class="verifyBody">
    <div class="verifyDiv">
      <div class="verify-content">
        <el-icon class="icon" name="loading"></el-icon>
        <h1 v-if="!error">正在验证</h1>
        <p v-if="error" class="error">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import instance from '@/utils/axios.config';

export default {
  name: "VerifyRegister",
  data() {
    return {
      error: null,
    };
  },
  mounted() {
    const token = this.$route.query.sign_up_token;

    if (token) {
      instance.get(`/auth/sign-up-verification?sign_up_token=${token}`)
        .then(response => {
          if (response.status === 200) {
            this.$router.push('/DefaultSuccess');
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            this.error = '验证失败，请重试。';
          } else {
            this.error = '发生错误，请稍后再试。';
          }
        });
    } else {
      this.error = '无效的验证请求。';
    }
  },
};
</script>

<style scoped>
.verifyBody {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #B3C0D1;
}
.verifyDiv {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-top: -200px;
  margin-left: -250px;
  width: 450px;
  height: 600px;
  background: #fff;
  border-radius: 5%;
}
.verify-content {
  width: 400px;
  height: 250px;
  position: absolute;
  top: 25px;
  left: 25px;
  text-align: center;
}
.icon {
  color: grey;
  font-size: 50px;
}
.error {
  color: red;
}
</style>