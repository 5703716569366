<template>
  <div class="search-panel">
    
    <div class="search-fields">
        <!-- 表格选择 -->
        <el-select v-model="selectedTable" placeholder="请选择表格" class="select">
            <el-option v-for="table in tables" :key="table" :label="tableMapping[table]" :value="table"></el-option>
        </el-select>
    </div>


    <div class="search-fields">
      <!-- 动态搜索字段 -->
      <div v-for="param in tableQueryParams[selectedTable]" :key="param" >
        <el-input
            clearable
            class="search-input"
            v-if="!['created_from', 'created_to','scanned_from', 'scanned_to'].includes(param)"
            v-model.lazy="search[param]"
            :placeholder="`搜索${columns_mapping[param]}`">
        </el-input>
      </div>

    </div>

    <!-- 特定表格的日期选择器 -->
    <div class="search-fields" v-if="['orders', 'v_order_summary', 'coupons'].includes(selectedTable)">
      <el-date-picker
          class="date-picker-wide"
          v-model="search.created_from"
          type="date"
          placeholder="创建日期从">
      </el-date-picker>
      <el-date-picker
          class="date-picker-wide"
          v-model="search.created_to"
          type="date"
          placeholder="创建日期到">
      </el-date-picker>
    </div>

    <!-- 特定表格的价格选择器 -->
    <div class="search-fields" v-if="['orders','v_order_summary'].includes(selectedTable)">
    <!-- Price input fields -->
    <el-input
        clearable
        class="input-wide"
        v-model.lazy="search.price_from"
        placeholder="价格从">
    </el-input>
    <el-input
        clearable
        class="input-wide"
        v-model.lazy="search.price_to"
        placeholder="价格到">
    </el-input>
    </div>


    <!-- 特定表格的日期选择器 -->
    <div class="search-fields" v-if="['scanned_items'].includes(selectedTable)">
      <el-date-picker
          class="date-picker-wide"
          v-model="search.scanned_from"
          type="date"
          placeholder="入库时间从">
      </el-date-picker>
      <el-date-picker
          class="date-picker-wide"
          v-model="search.scanned_to"
          type="date"
          placeholder="入库时间到">
      </el-date-picker>
    </div>    

    <!-- 特定表格的日期选择器 -->
    <div class="search-fields" v-if="['coupons'].includes(selectedTable)">
      <el-date-picker
          class="date-picker-wide"
          v-model="search.valid_from"
          type="date"
          placeholder="有效时间从">
      </el-date-picker>
      <el-date-picker
          class="date-picker-wide"
          v-model="search.valid_to"
          type="date"
          placeholder="有效时间到">
      </el-date-picker>
    </div>  

    <div class="search-fields">
        <!-- 搜索按钮 -->
        <el-button type="primary" icon="el-icon-search" @click="searchTable">搜索</el-button>
        <el-button type="danger" icon="el-icon-delete" @click="clearSearchResult">清除搜索</el-button>
        <el-button @click="exportExcel">导出为Excel</el-button>
    </div>

    <!-- 数据表格 -->
    <div class="table-container">
      <el-table :data="filteredDatatable" :header-cell-style="{background:'#f2f5fc', color:'#555'}" height="70vh" border>
        <el-table-column type="index" label="序号" fixed/>
        <!-- 动态生成列 -->
        <el-table-column
          v-for="(value, name) in filteredDatatable[0]"
          :key="name"
          :prop="name"
          :label="columns_mapping[name]"
          :formatter="formatColumn">
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页组件 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[100, 1000, 10000, 1000000, 999999999]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
// import axios from 'axios';
import instance from '@/utils/axios.config';
import { formatLocalDate, convertToMelbourneTime} from '@/utils/time_utils';
// 添加新的表格时，需要在此处添加对应的列名
import { columns_mapping } from '@/utils/excel';

export default {
  name: "ExportExcelTable",
  data() {
  return {
    // 定义了每个表格的可搜索参数
    tableQueryParams: {
      'user_input_items': ['user_input_items_id', 'user_id', 'tracking_id'],
      'scanned_items': ['item_id', 'user_id', 'tracking_id', 'scanned_from', 'scanned_to'],
      'users': ['user_id', 'open_id', 'union_id'],
      'orders': ['user_id', 'alias_order_id', 'order_id', 'order_status', 'created_from', 'created_to', 'payment_method'],
      'order_items': ['order_id', 'user_id', 'item_id', 'order_status'],
      'admin_users': ['user_name', 'receiver_email', 'role'],
      'v_order_summary': ['user_id', 'order_id', 'order_status', 'created_from', 'created_to', 'payment_method'],
      // ...其他表格及其参数...
      'coupons': ['coupon_id'],
      'order_coupons': ['order_id', 'coupon_id'],
    },
    columns_mapping,
    search: {
              user_id: '',
              order_id: '',
              alias_order_id: '',
              order_status: '',
              payment_status: '',
              payment_method: '',
              created_from: '',
              created_to: '',
              price_from: 0,
              price_to: 9999,
              valid_from: '',
              valid_to: '',
              receiver_postcode: '',
              // Admins Users
              user_name: '',
              role: '',
              admin_email: '',
              // Order Items
              item_id: '',
              // User Input Items
              user_input_items_id: '',
              tracking_id: '',
              // Scanned Items
              scanned_from: '',
              scanned_to: '',
              // Users
              union_id: '',
              open_id: '',
              limit: this.limit,
              page: this.page

    },
    selectedTable: '',
    tables: [],
    tableMapping: {
                'orders': '订单',
                'order_items': '订单包含的物品',
                'coupons': '优惠券',
                'order_coupons': '订单使用的优惠券',
                'user_input_items': '用户申报物品',
                'scanned_items': '入库物品',
                'v_order_summary': '订单概况',
                'admin_users': '管理端用户',
                'users': '小程序用户'
            },   
    tableData: [],
    priceRange: [0, 10000],
    minPrice: null,
    maxPrice: null,
    limit: 100,
    page: 1,
  };
},
async created() {
  this.tables = Object.keys(this.tableQueryParams);
  this.loadData();
},

  methods: {
    formatLocalDate,
    convertToMelbourneTime,

    async loadData() {
          try{
            if (!this.selectedTable){
              this.$message.info("请选择表格!");
              return
            }
            const response = await instance.get(`/data-exports/excel/query/${this.selectedTable}`, {
              params: { search_params: this.search, limit: this.limit, page: this.page }
            });
            //   console.log("response",response)    
              if (response.status === 200) {
                  this.tableData = response.data;
                  this.$message.success(`搜索成功${this.selectedTable},返回${response.data.length}条结果!`);
              }

          }catch (error) {
              this.$message.error(`获取信息失败! ${error.response.data.error}`);
          }   
    },
    async searchTable() {
      try {
        const response = await instance.get(`/data-exports/excel/query/${this.selectedTable}`, {
          params: this.search
        });
        if (response.status === 200) {
          this.tableData = response.data;
          this.$message.success(`搜索成功${this.selectedTable},返回${this.total}条结果!`);
        }
      } catch (error) {
        this.$message.error(`获取信息失败! ${error.response.data.error}`);
      }
    },

    clearSearchResult() {
      this.search =  {
              user_id: '',
              order_id: '',
              order_status: '',
              payment_status: '',
              payment_method: '',
              created_from: '',
              created_to: '',
              price_from: '',
              price_to: '',
              receiver_postcode: '',
              // Admins Users
              user_name: '',
              role: '',
              admin_email: '',
              // Order Items
              item_id: '',
              // User Input Items
              user_input_items_id: '',
              tracking_id: '',
              // Scanned Items
      }
      this.page = 1;
      this.loadData();
    },
    

    updatePriceRange() {
      let minPrice = Infinity;
      let maxPrice = -Infinity;

      this.tableData.forEach((item) => {
        if (item.price < minPrice) {
          minPrice = item.price;
        }
        if (item.price > maxPrice) {
          maxPrice = item.price;
        }
      });

      if (minPrice !== Infinity && maxPrice !== -Infinity) {
        this.minPrice = Number(minPrice);
        this.maxPrice = Number(maxPrice);
        this.priceRange = [minPrice, maxPrice];
      } else {
        // 如果没有有效数据，则重置为默认范围
        this.minPrice = 0;
        this.maxPrice = 10000;
        this.priceRange = [0, 10000];
      }
    },

    formatColumn(row, column, cellValue) {
        const dateColumns = ['updated_at', 'scanned_at', 'created_at', 'valid_from', 'valid_to', 'signature_timestamp'];
        if (dateColumns.includes(column.property)) {
            return (cellValue);
        }
        if (typeof cellValue === 'boolean') {
        // 如果 cellValue 为 true，返回 "✅"，否则返回 "❌"
        return cellValue ? '✅' : '❌';
      }
        return cellValue;
    },

    async exportExcel() {
      try {
        const response = await instance({
          url: `/data-exports/excel/export/${this.selectedTable}`,
          method: 'GET',
          responseType: 'blob', // 处理二进制数据
          params: { search_params: this.search, limit: this.limit, page: this.page }
        });
        if(response.status === 200){
          this.$message.success(`导出${this.selectedTable}成功!`);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.selectedTable}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        this.$message.error(`导出失败! ${error.message}`);

      }
    },
    handleSizeChange(val) {
      this.limit = val;
      this.page = 1; // Reset to first page when page size changes
      this.loadData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.loadData();
    }
  },
  watch: {
    selectedTable(newTable) {
      this.selectedTable = newTable;
      this.clearSearchResult()
    },
    
    },
    computed :{
      filteredDatatable() {
            let result = this.tableData;
            // First, filter by search strings
            Object.keys(this.search).forEach(key => {
                if (this.search[key] && typeof this.search[key] === 'string') {
                    let searchString = this.search[key].replace('*', '.*');
                    let regex = new RegExp('^' + searchString, 'i');
                    result = result.filter(order => regex.test(String(order[key])));
                }
            });
            // filter out the number of rows
            if (result.length > 0 && result[0].number_of_rows) {
                result = result.slice(1);
            }

            // 价格搜索
            if(this.search.price_from || this.search.price_to){
                result = this.tableData.filter(order => {
                    let orderPrice = parseFloat(order.price);
                    let price_from = this.search.price_from ? parseFloat(this.search.price_from) : null;
                    let price_to = this.search.price_to ? parseFloat(this.search.price_to) : null;
                    if (price_from && orderPrice < price_from) return false;
                    if (price_to && orderPrice > price_to) return false;
                    return true;
                });
            }

            // 创建日期搜索
            if (this.search.created_from || this.search.created_to) {
                result = result.filter(order => {
                    let created_from = this.search.created_from ? convertToMelbourneTime(this.search.created_from) : null;
                    let created_to = this.search.created_to ? convertToMelbourneTime(this.search.created_to) : null;
                    let orderCreatedFrom = convertToMelbourneTime(order.created_at);
                    let orderCreatedTo = convertToMelbourneTime(order.created_at);
                    if (created_from && orderCreatedFrom < created_from) return false;
                    if (created_to && orderCreatedTo > created_to) return false;
                    return true;
                });
            }
            // 优惠券搜索
            if (this.search.valid_from || this.search.valid_to) {
                result = result.filter(coupon => {
                    let valid_from = this.search.valid_from ? convertToMelbourneTime(this.search.valid_from) : null;
                    let valid_to = this.search.valid_to ? convertToMelbourneTime(this.search.valid_to) : null;
                    let couponCreatedFrom = convertToMelbourneTime(coupon.created_at);
                    let couponCreatedTo = convertToMelbourneTime(coupon.created_at);

                    if (valid_from && couponCreatedFrom < valid_from) return false;
                    if (valid_to && couponCreatedTo > valid_to) return false;
                    return true;
                });
            }
              // 扫描记录搜索
            if (this.search.scanned_from || this.search.scanned_to) {
              console.log("scanned_from", this.search.scanned_from)
              console.log("scanned_to", this.search.scanned_to)
                result = result.filter(item => {
                    let scanned_from = this.search.scanned_from ? convertToMelbourneTime(this.search.scanned_from) : null;
                    let scanned_to = this.search.scanned_to ? convertToMelbourneTime(this.search.scanned_to) : null;
                    let itemScannedFrom = convertToMelbourneTime(item.scanned_at);
                    let itemScannedTo = convertToMelbourneTime(item.scanned_at);

                    if (scanned_from && itemScannedFrom < scanned_from) return false;
                    if (scanned_to && itemScannedTo > scanned_to) return false;
                    return true;
                });
            }
            return result;
        },
        total() {
            return this.filteredDatatable.length;
        }
    }
};


</script>

<style scoped>
.search-fields  {
  display: flex;
  flex-wrap: nowrap; /* 阻止元素换行 */
  align-items: center;
  gap: 10px; /* 为元素添加间隙 */
  margin-bottom: 20px;
  max-width: 100%; /* 限制宽度为屏幕的一半 */
}

.search-input {
  flex-grow: 1;
}

.el-select, .date-picker-wide {
      width: 50%; /* 限制最大宽度 */
  }

.table-container {
    max-height: 70vh;  /* 你可以根据需要调整这个值 */
    overflow-x: auto; /* 显示横向滚动条 */
    overflow-y: auto; 
}

.search-panel {
  padding: 20px;
  border-radius: 8px;
}




</style>