<template>
    <el-menu 
    class="hover-effect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"  
        :default-active="activeMenu"
        style="height: 120vh;"
        :collapse="isCollapse"
        :collapse-transition="false">
        
        <el-menu-item index="1" @click="goToHome">
            <i class="el-icon-s-home"></i>
            <span>首页</span>
        </el-menu-item>
  
        <el-menu-item index="2" @click="goToAdminUsers" v-if="isAuthorised">
          <i class="el-icon-user"></i>
          <span slot="title">管理端用户</span>
        </el-menu-item>      
  
        <el-menu-item index="3" @click="goToUsers">
          <i class="el-icon-s-custom"></i>
          <span slot="title">小程序用户</span>
        </el-menu-item>
  
        <el-menu-item index="4" @click="goToUserInputItems">
          <i class="el-icon-box"></i>
          <span slot="title">用户申报物品</span>
        </el-menu-item>
  
        <el-menu-item index="5" @click="goToInScannedItems">
            <i class="el-icon-box"></i>
            <span>入库物品</span>
        </el-menu-item>
  
        <el-menu-item index="6" @click="goToReviewOrders">
          <i class="el-icon-s-order"></i>
          <span slot="title">1.待审核订单 (客服制单)</span>
        </el-menu-item>
  
        <el-menu-item index="7" @click="goToPackedOrders">
          <i class="el-icon-s-order"></i>
          <span slot="title">2.已合箱订单 (客服修改)</span>
        </el-menu-item>
  
        <el-menu-item index="8" @click="goToConfirmedOrders">
          <i class="el-icon-question"></i>
          <span slot="title">3.已确认订单 (客服确认)</span>
        </el-menu-item>
  
        <el-menu-item index="9" @click="goToShippedOrders">
          <i class="el-icon-ship"></i>
          <span slot="title">4.已发货订单 (物流状态)</span>
        </el-menu-item>
  
        <el-menu-item index="10" @click="goToUnpaidOrders">
          <i class="el-icon-s-finance"></i>
          <span slot="title">5.待支付订单 (确认收款)</span>
        </el-menu-item>
  
        <el-menu-item index="11" @click="goToDeliveredOrders">
          <i class="el-icon-check"></i>
          <span slot="title">6.已送达订单 (确认收货)</span>
        </el-menu-item>
  
        <el-menu-item index="12" @click="goToPaidOrders">
          <i class="el-icon-s-check"></i>
          <span slot="title">已支付订单</span>
        </el-menu-item>
  
        <el-menu-item index="13" @click="goToCancelledOrders">
          <i class="el-icon-delete-solid"></i>
          <span slot="title">已取消订单</span>
        </el-menu-item>
  
        <el-menu-item index="14" @click="goToOrderStatusHistory">
          <i class="el-icon-location-information"></i>
          <span slot="title">订单历史 (信息汇总)</span>
        </el-menu-item>
  
        <el-menu-item index="15" @click="goToExportExcel" v-if="isAuthorised">
            <i class="el-icon-s-data"></i>
            <span>导出Excel</span>
        </el-menu-item>
  
        <el-menu-item index="16" @click="goToCoupons">
            <i class="el-icon-s-ticket"></i>
            <span>优惠券</span>
        </el-menu-item>   
  
        <el-menu-item index="17" @click="goToDocs">
            <i class="el-icon-edit-outline"></i>
            <span>编辑公告</span>
        </el-menu-item>   
  
        <el-menu-item index="18" @click="goToImages">
            <i class="el-icon-edit-outline"></i>
            <span>上传/编辑图片</span>
        </el-menu-item>   
  
        </el-menu>
  </template>

<script>

export default {
    name: "AsideA",
    data() {
        return {
            role: null,
            routeToMenuIndex: {
                '/home': '1',
                '/user/users': '3',
                '/admin/admin_users': '2',
                '/items/user-input-items': '4',
                '/items/scanned-items': '5',
                // Static keys are defined here
                '/orders/order_status_history': '14',
                '/excel/export_excel': '15',
                '/coupons': '16',
                '/docs': '17',
                '/images': '18',
            }
            
        }
    },

    props: {
        isCollapse: Boolean
    },
    created() {
        // Get role from localStorage when the component is created
        this.role = localStorage.getItem('role');
        // Dynamically add keys that depend on environment variables
        this.routeToMenuIndex[`/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_REVIEWING}`] = '6';
        this.routeToMenuIndex[`/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_CONFIRMED}`] = '8';
        this.routeToMenuIndex[`/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_PACKED}`] = '7';
        this.routeToMenuIndex[`/orders/order_status/${process.env.VUE_APP_PAYMENT_STATUS_UNPAID}`] = '10';
        this.routeToMenuIndex[`/orders/order_status/${process.env.VUE_APP_PAYMENT_STATUS_PAID}`] = '12';
        this.routeToMenuIndex[`/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_SHIPPED}`] = '9';
        this.routeToMenuIndex[`/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_DELIVERED}`] = '11';
        this.routeToMenuIndex[`/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_CANCELLED}`] = '13';

    },
    computed: {
        activeMenu() {
            // Use this.$route.path to get the current route's path
            // Then use the routeToMenuIndex mapping to find the corresponding menu index
            // If the current route's path is not found in the mapping, it returns an empty string
            return this.routeToMenuIndex[this.$route.path] || '';
        },
        isAuthorised() {
            return this.role >= 200;
        }
    },

    methods: {
        getPath(){
            console.log(this.$route.path);
        },
        goToHome() {
            if (this.$router.currentRoute.path !== '/home') {
                this.$router.push('/home');
            }
        },
        goToUsers() {
            if (this.$router.currentRoute.path !== '/user/users') {
                this.$router.push('/user/users');
            }
        },
        goToAdminUsers() {
            if (this.$router.currentRoute.path !== '/admin/admin_users') {
                this.$router.push('/admin/admin_users');
            }
        },
        goToUserInputItems() {
            if (this.$router.currentRoute.path !== '/items/user-input-items') {
                this.$router.push('/items/user-input-items');
            }
        },
        goToInScannedItems() {
            if (this.$router.currentRoute.path !== '/items/scanned-items') {
                this.$router.push('/items/scanned-items');
            }
        },
        goToReviewOrders() {
            if (this.$router.currentRoute.path !== `/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_REVIEWING}`) {
                this.$router.push(`/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_REVIEWING}`);
            }
        },
        goToConfirmedOrders() {
            if (this.$router.currentRoute.path !== `/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_CONFIRMED}`) {
                this.$router.push(`/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_CONFIRMED}`);
            }
        },
        goToPackedOrders() {
            if (this.$router.currentRoute.path !== `/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_PACKED}`) {
                this.$router.push(`/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_PACKED}`);
            }
        },
        goToUnpaidOrders() {
            if (this.$router.currentRoute.path !== `/orders/order_status/${process.env.VUE_APP_PAYMENT_STATUS_UNPAID}`) {
                this.$router.push(`/orders/order_status/${process.env.VUE_APP_PAYMENT_STATUS_UNPAID}`);
            }
        },
        goToPaidOrders() {
            if (this.$router.currentRoute.path !== `/orders/order_status/${process.env.VUE_APP_PAYMENT_STATUS_PAID}`) {
                this.$router.push(`/orders/order_status/${process.env.VUE_APP_PAYMENT_STATUS_PAID}`);
            }
        },
        goToShippedOrders() {
            if (this.$router.currentRoute.path !== `/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_SHIPPED}`) {
                this.$router.push(`/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_SHIPPED}`);
            }
        },
        goToDeliveredOrders() {
            if (this.$router.currentRoute.path !== `/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_DELIVERED}`) {
                this.$router.push(`/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_DELIVERED}`);
            }
        },
        goToCancelledOrders() {
            if (this.$router.currentRoute.path !== `/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_CANCELLED}`) {
                this.$router.push(`/orders/order_status/${process.env.VUE_APP_ORDER_STATUS_CANCELLED}`);
            }
        },
        goToOrderStatusHistory() {
            if (this.$router.currentRoute.path !== '/orders/order_status_history') {
                this.$router.push('/orders/order_status_history');
            }
        },
        goToExportExcel() {
            if (this.$router.currentRoute.path !== '/excel/export_excel') {
                this.$router.push('/excel/export_excel');
            }
        },
        goToCoupons() {
            if (this.$router.currentRoute.path !== '/coupons') {
                this.$router.push('/coupons');
            }
        },
        goToDocs() {
            if (this.$router.currentRoute.path !== '/docs') {
                this.$router.push('/docs');
            }
        },
        goToImages() {
            if (this.$router.currentRoute.path !== '/images') {
                this.$router.push('/images');
            }
        }
    }
}
</script>

<style>
</style>