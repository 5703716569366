<template>
    <div>
        <h1>403 Unauthorized</h1>
        <p>您将在 {{ countdown }} 秒后被重定向到主页。</p>
    </div>
</template>

<script>
export default {
    name: 'UnauthorizedPage',
    data() {
        return {
            countdown: 5,
        };
    },
    mounted() {
        const intervalId = setInterval(() => {
            if (this.countdown > 0) {
                this.countdown--;
            } else {
                clearInterval(intervalId);
                this.$router.push('/home');
            }
        }, 1000);
    },
};
</script>