

<template>
    <div>
        <div class="order-section">
            <h2>订单详情</h2>
            <el-table :data="orderData" border>
                <el-table-column prop="order_id" label="订单ID" flex="1"></el-table-column>
                <el-table-column prop="alias_order_id" label="运单号" fixed></el-table-column>
                <el-table-column prop="user_id" label="用户ID" fixed></el-table-column>
                <el-table-column prop="total_weight" label="总重量(kg)" flex="1"></el-table-column>
                <el-table-column prop="total_volume" label="总体积(m3)" flex="1"></el-table-column>
                <el-table-column prop="price" label="价格" flex="1"></el-table-column>
                <el-table-column prop="total_declared_value" label="申报总价值" flex="1"></el-table-column>
                <el-table-column prop="number_of_boxes" label="合箱数" flex="1"></el-table-column>
                <el-table-column prop="order_status" label="订单状态" flex="1"></el-table-column>
                <el-table-column prop="updated_at" label="更新时间" flex="1" :formatter="row => (row.updated_at)"></el-table-column>
                <el-table-column prop="created_at" label="创建时间" flex="1" :formatter="row => (row.created_at)"></el-table-column>
                <el-table-column prop="signature_timestamp" label="签收时间" flex="1" :formatter="row => (row.signature_timestamp)"></el-table-column>
                <el-table-column prop="sender_address" label="寄件人地址" flex="1"></el-table-column>
                <el-table-column prop="sender_mobile" label="寄件人电话" flex="1"></el-table-column>
                <el-table-column prop="receiver" label="收件人" flex="1"></el-table-column>
                <el-table-column prop="receiver_address" label="收件人地址" flex="1"></el-table-column>
                
                <el-table-column prop="receiver_state" label="收件人州" flex="1"></el-table-column>
                <el-table-column prop="receiver_postcode" label="收件人邮编" flex="1"></el-table-column>
                <el-table-column prop="receiver_mobile" label="收件人手机" flex="1"></el-table-column>
                <el-table-column prop="receiver_email" label="收件人邮箱" flex="1"></el-table-column>
                <el-table-column prop="shipping_method" label="运输方式" flex="1"></el-table-column>
                <!-- Add more columns as needed -->
            </el-table>
        </div>
        <div class="order-section">
            <h2>订单物品</h2>
            <el-table :data="orderItemsData" border>
                <el-table-column prop="item_id" label="物品ID" flex="1"></el-table-column>
                <el-table-column prop="alias_order_id" label="运单号" fixed></el-table-column>
                <el-table-column prop="tracking_id" label="跟踪ID" flex="1"></el-table-column>
                <el-table-column prop="item_category" label="物品类目"></el-table-column>
                <el-table-column prop="item_description" label="物品描述"></el-table-column>
                <el-table-column prop="is_online" label="是否网购" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.is_online === true ? '✅' : '❌' }}
                </template>
                </el-table-column>
                <el-table-column prop="is_pickup_required" label="是否上门收件" flex="1">
                    <template slot-scope="scope">
                        {{ scope.row.is_pickup_required === true ? '✅' : '❌' }}
                    </template>
                </el-table-column>
                <el-table-column prop="pickup_address" label="上门收件地址"></el-table-column>
                <el-table-column prop="declared_value" label="申报价值" flex="1"></el-table-column>
                <el-table-column prop="weight" label="重量(kg)"></el-table-column>
                <el-table-column prop="volume" label="体积(m3)"></el-table-column>
                <el-table-column prop="length" label="长度(m)"></el-table-column>
                <el-table-column prop="width" label="宽度(m)"></el-table-column>
                <el-table-column prop="height" label="高度(m)"></el-table-column>
                <!-- Add more columns as needed -->
            </el-table>
        </div>
        <div class="history-section">
            <div class="order-status-history-section">
                <h2>订单状态历史</h2>
                <el-timeline>
                    <el-timeline-item
                        v-for="(item, index) in sortedOrderStatusHistoryData"
                        :key="index"
                        :timestamp="(item.updated_at)"
                        :color="item.color"
                        :icon="item.icon"
                    >
                        <h4>{{ item.order_status }}</h4>
                        <p>最后操作员: {{ item.operator_name }}</p>
                    </el-timeline-item>
                </el-timeline>
            </div>
            <div class="payment-status-history-section">
                <h2>支付状态历史</h2>
                <el-timeline>
                    <el-timeline-item
                        v-for="(item, index) in sortedPaymentStatusHistoryData"
                        :key="index"
                        :timestamp="(item.updated_at)"
                        :color="item.color"
                        :icon="item.icon"
                    >
                        <h4>{{ item.payment_status }}</h4>
                        <p>最后操作员: {{ item.operator_name }}</p>
                        <p>支付方式: {{ item.payment_method }}</p>
                        <p>已付金额: {{ item.paid_amount }}</p>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </div>
    </div>
</template>

<script>
import { getOrdersByOrderId, getOrderStatusHistoryByOrderId, getPaymentStatusHistoryByOrderId, getOrderItemsByOrderId } from '../../utils/orders';
import { formatLocalDate } from '@/utils/time_utils';

export default {
    props: ['orderId'],
    data() {
        return {
            orderData: [],
            orderStatusHistoryData: [],
            paymentStatusHistoryData: [],
            orderItemsData: []
        };
    },
    computed: {
        sortedOrderStatusHistoryData() {
            const data = [...this.orderStatusHistoryData].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            data.forEach((item, index) => {
                item.icon = index === 0 ? '' : 'el-icon-more';
                item.color = item.order_status === '已取消' ? 'red' : (index === 0 ? 'green' : 'grey');
            });
            return data;
        },

        sortedPaymentStatusHistoryData() {
            const data = [...this.paymentStatusHistoryData].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            data.forEach((item, index) => {
                item.icon = index === 0 ? '' : 'el-icon-more';
                item.color = (index === 0 ? 'green' : 'grey');
            });
            return data;
        },
    },

    async created() {
        const orderResponse = await getOrdersByOrderId(1, 1, this.orderId);
        this.orderData = orderResponse.data;
        const orderStatusHistoryResponse = await getOrderStatusHistoryByOrderId(this.orderId);
        this.orderStatusHistoryData = orderStatusHistoryResponse.data;
        const paymentStatusHistoryResponse = await getPaymentStatusHistoryByOrderId(this.orderId);
        this.paymentStatusHistoryData = paymentStatusHistoryResponse.data;
        const orderItemResponse = await getOrderItemsByOrderId({orderId:this.orderId})
        this.orderItemsData = orderItemResponse.data;
    },
    // ...
    methods:{
        formatLocalDate
    }
}
</script>

<style scoped>
.history-section {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 20px;
  width: 100%;
}
</style>