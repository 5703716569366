import instance from './axios.config';


  
export async function searchCoupons({
    coupon_id = null,
    user_id = null,
    valid_from = null,
    valid_to = null,
    discount_type = null,
    discount_value_from = null,
    discount_value_to = null,
    min_spend_requirement_from = null,
    min_spend_requirement_to = null,
    max_discount_from = null,
    max_discount_to = null,
    new_customer_only = null,
    repeated_usage_allowed = null,
    limit = null,
    offset = null
}) {
    try {
        // 构建查询参数
        let params = new URLSearchParams();
        if (coupon_id) params.append('coupon_id', coupon_id);
        if (user_id) params.append('user_id', user_id);
        if (valid_from) params.append('valid_from', valid_from);
        if (valid_to) params.append('valid_to', valid_to);
        if (discount_type) params.append('discount_type', discount_type);
        if (discount_value_from) params.append('discount_value_from', discount_value_from);
        if (discount_value_to) params.append('discount_value_to', discount_value_to);
        if (min_spend_requirement_from) params.append('min_spend_requirement_from', min_spend_requirement_from);
        if (min_spend_requirement_to) params.append('min_spend_requirement_to', min_spend_requirement_to);
        if (max_discount_from) params.append('max_discount_from', max_discount_from);
        if (max_discount_to) params.append('max_discount_to', max_discount_to);
        if (new_customer_only) params.append('new_customer_only', new_customer_only);
        if (repeated_usage_allowed) params.append('repeated_usage_allowed', repeated_usage_allowed);
        if (limit) params.append('limit', limit);
        if (offset) params.append('offset', offset);

        const response = await instance.get(`/coupons/coupon`, {
            params
        });

        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error search coupons:", error.response.data.error);
        throw new Error(error.response.data.error);
    }
}

export async function createNewCoupon({ coupon_id, coupon_object}) {
    try {
        const response = await instance.put(`/coupons/create/${coupon_id}`, coupon_object);

        // 处理响应
        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error create order:", error);
        // 错误处理逻辑
        throw new Error(error.response.data.error);
    }
}

export async function updateExistingCoupon({ coupon_id, coupon_object}) {
    try {
        const response = await instance.post(`/coupons/update/${coupon_id}`, coupon_object);
        if (response.status === 200){
            return response.data;
        }
    } catch (error) {
        throw new Error(error.response.data.error);
    }
}



