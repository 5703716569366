import { format, utcToZonedTime } from 'date-fns-tz';

export function formatLocalDate(date) {
    if (!date) {
        return null;
    }
    const timeZone = 'Asia/Shanghai';
    const zonedDate = utcToZonedTime(date, timeZone);
    return format(zonedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone });
}

export function convertToMelbourneTime(date) {
    if (!date) {
        return null;
    }
    // 创建一个新的 Date 对象，它表示墨尔本时间的当前日期和时间
    let melbourneTime = new Date(date.toLocaleString("en-US", {timeZone: "Asia/Shanghai"}));

    // 将时间设置为0点0分
    melbourneTime.setHours(0, 0, 0, 0);

    // 返回墨尔本时间的0点
    return melbourneTime;
}
