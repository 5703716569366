import instance from '@/utils/axios.config';

export async function logOut() {
    try {
    
        const response = await instance.get(`/auth/logout`, {});

        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("登陆过期, 自动登出: ", error);
    }
}

// localStorage.js
export function getRole() {
    const role = localStorage.getItem('role');
    const currentUser = localStorage.getItem('user_name');
  
    return { role, currentUser };
  }