import instance from './axios.config';
  
export async function searchScannedItems({ tracking_id = null, user_id = null, item_status = null, items_id = null, scanned_from = null, scanned_to = null, is_sensitive_good = null, page = null, limit = null }  ) {
    try {
        // 构建查询参数
        let params = new URLSearchParams();
        if (tracking_id) params.append('tracking_id', tracking_id);
        if (user_id) params.append('user_id', user_id);
        if (item_status) params.append('item_status', item_status);
        if (items_id) params.append('items_id', items_id);
        if (scanned_from) params.append('scanned_from', scanned_from);
        if (scanned_to) params.append('scanned_to', scanned_to);
        if (is_sensitive_good) params.append('is_sensitive_good', is_sensitive_good);
        if (page) params.append('page', page);
        if (limit) params.append('limit', limit);
        const response = await instance.get(`/items/scanned_items/items`, {
            params
        });

        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        if (error.response.status === 404) {
            throw new Error(`${error.message}: 入库物品不存在`);
        }
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }
}

// 从user_input_items表中查询可以扫描的items

export async function searchScannableItems({ tracking_id = null, user_id = null, page = null, limit = null  }  ) {
    try {
        // 构建查询参数
        let params = new URLSearchParams();
        if (tracking_id) params.append('tracking_id', tracking_id);
        if (user_id) params.append('user_id', user_id);
        if (page) params.append('page', page);
        if (limit) params.append('limit', limit);

        const response = await instance.get(`/items/user_input_items/items`, {
            params
        });

        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        if (error.response.status === 404) {
            if (!tracking_id) throw new Error(`${error.message}: 用户 ${user_id}没有可以扫描的物品`);
            if (!user_id) throw new Error(`${error.message}: 跟踪ID ${tracking_id}没有可以扫描的物品`);
        }
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }
}

export async function insertScannedItems({itemData}) {
    
    try {
        const response = await instance.post(`/items/scanned_items/insert`, itemData);
        if (response.status === 200){
            return response.data;
        }
    } catch (error) {
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }
}

export async function updateScannedItems({itemId, itemData}) {
    
    try {
        const response = await instance.put(`/items/scanned_items/update/${itemId}`, itemData);
        if (response.status === 200){
            return response.data;
        }
    } catch (error) {
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }
}

export async function deleteScannedItems(itemId) {
    try {
        await instance.delete(`/items/scanned_items/delete/${itemId}`);
    } catch (error) {
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }
}