<template>
  <div class="failureBody">
    <div class="failureDiv">
      <div class="failure-content">
        <el-icon class="icon" name="error"></el-icon>
        <h1>操作失败</h1>
        <p>您将在 {{ countdown }} 秒后被重定向到登录页面。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultFailure",
  data() {
    return {
      countdown: 5,
    };
  },
  mounted() {
    const intervalId = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(intervalId);
        this.$router.push('/login');
      }
    }, 1000);
  },
};
</script>

<style scoped>
.failureBody {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #B3C0D1;
}
.failureDiv {
    position: absolute;
    top: 40%;
    left: 50%;
    margin-top: -200px;
    margin-left: -250px;
    width: 450px;
    height: 600px;
    background: #fff;
    border-radius: 5%;
}
.failure-content {
    width: 400px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px;
    text-align: center;
}
.icon {
    color: red;
    font-size: 50px;
}
</style>