import Vue from 'vue';
import Router from 'vue-router';
import AdminHome from './components/AdminHome.vue';
import AdminUsers from './components/admins/AdminUsers.vue';
import UserHome from './components/users/UserHome.vue';
import ScannedItems from './components/items/ScannedItems.vue';
import UserInputItems from './components/items/UserInputItems.vue';
import ReviewOrders from './components/orders/ReviewOrders.vue';
import CancelledOrders  from './components/orders/CancelledOrders.vue';
import ConfirmedOrders from './components/orders/ConfirmedOrders.vue';
import PackedOrders from './components/orders/PackedOrders.vue';
import UnpaidOrders from './components/orders/UnpaidOrders.vue';
import PaidOrders from './components/orders/PaidOrders.vue';
import ShippedOrders from './components/orders/ShippedOrders.vue';
import DeliveredOrders from './components/orders/DeliveredOrders.vue';
import OrderStatusHistory from './components/orders/OrderStatusHistory.vue';
import OrderStatusHistoryPage from './components/detailPage/OrderStatusHistoryPage.vue';
import OrderPdfPage from './components/detailPage/OrderPdfPage.vue';


import AdminLogin from './components/auth/AdminLogin.vue';
import AdminRegister from './components/auth/AdminRegister.vue';
import ForgetPassword from './components/auth/ForgetPassword.vue';
import ResetPassword from './components/auth/ResetPassword.vue';

import DefaultSuccess from './components/error_pages/DefaultSuccess.vue';
import DefaultFailure from './components/error_pages/DefaultFailure.vue';
import UnauthorizedPage from './components/error_pages/UnauthorizedPage.vue';
import VerifyRegister from './components/auth/VerifyRegister.vue';
import VerifyResetPassword from './components/auth/VerifyResetPassword.vue';

import QRcode from './components/QRcode.vue';
import SsePage from './components/SsePage.vue';

import ExportExcel from './components/excel/ExportExcel.vue';

import Coupons from './components/coupons/Coupons.vue';
import Docs from './components/docs/Docs.vue';
import Images from './components/docs/Images.vue';

Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: '/login' 
  },
  {
    path: '/sse',
    name: 'sse', // 将根 URL 重定向到 /login
    component: SsePage
  },
  {
    path: '/login',
    name: 'login',
    component: AdminLogin
  },
  {
    path: '/home',
    name: 'home',
    component: AdminHome,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/users',
    name: 'users',
    component: UserHome,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/admin_users',
    name: 'admin_users',
    component: AdminUsers,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders/order_status_history',
    name: 'order_status_history_by_order_id',
    component: OrderStatusHistory,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders/details/:orderId',
    name: 'OrderStatusHistoryPage',
    component: OrderStatusHistoryPage,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders/invoice/:orderId',
    name: 'OrderPdfPage',
    component: OrderPdfPage,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/excel/export_excel',
    name: 'ExportExcel',
    component: ExportExcel,
    meta: { requiresAuth: true },
  },
  {
    path: '/coupons',
    name: 'Coupons',
    component: Coupons,
    meta: { requiresAuth: true },
  },

  {
    path: '/docs',
    name: 'Docs',
    component: Docs,
    meta: { requiresAuth: true },
  },

  {
    path: '/images',
    name: 'Images',
    component: Images,
    meta: { requiresAuth: true },
  },

  // 动态路由匹配
  {
    path: '/items/:items_table',
    name: 'dynamic_item_table',
    meta: { requiresAuth: true },
    props: (route) => {
      const items_table = route.params.items_table;
      if (items_table === 'scanned-items') {
        return { component: ScannedItems };
      } else if (items_table === 'user-input-items') {
        return { component: UserInputItems };
      } else {
        // Default component
        return { component: AdminHome };
      }
  },
  component: {
    functional: true,
    render(h, { props }) {
      return h(props.component);
    },
  },
},

// 动态路由匹配
  {
    path: '/orders/order_status/:order_status',
    name: 'dynamic_order_status',
    meta: { requiresAuth: true },
    props: (route) => {
      const order_status = route.params.order_status;
      if (order_status === '待审核') {
        return { component: ReviewOrders };
      } else if (order_status === '已确认') {
        return { component: ConfirmedOrders };
      } else if (order_status === '已取消') {
        return { component: CancelledOrders };
      } else if (order_status === '已合箱') {
        return { component: PackedOrders };
      } else if (order_status === '待支付') {
        return { component: UnpaidOrders };
      } else if (order_status === '已支付') {
        return { component: PaidOrders };
      } else if (order_status === '已发货') {
        return { component: ShippedOrders };
      } else if (order_status === '已送达') {
        return { component: DeliveredOrders };
      } else {
        // Default component
        return { component: AdminHome };
      }
    },
      component: {
        functional: true,
        render(h, { props }) {
          return h(props.component);
        },
      },
  },

{
    path: '/AdminRegister',
    name: "AdminRegister",
    component: AdminRegister
},
{
  path: '/ForgetPassword',
  name: 'ForgetPassword',
  component: ForgetPassword
},
{
  path: '/ResetPassword/:token',
  name: 'ResetPassword',
  component: ResetPassword,
  props: true
},
{
  path: '/DefaultSuccess',
  name: 'DefaultSuccess',
  component: DefaultSuccess
},
{
  path: '/DefaultFailure',
  name: 'DefaultFailure',
  component: DefaultFailure
},

{
  path: '/sign-up-verification',
  name: 'VerifyRegister',
  component: VerifyRegister,
},

{
  path: '/qrcode/order-history',
  name: 'ORDER_HISTORY_QRCODE',
  component: QRcode,
},

{
  path: '/reset-password-verification',
  name: 'VerifyResetPassword',
  component: VerifyResetPassword,
},

{
  path: '/unauthorized',
  component: UnauthorizedPage,
},

  // 其他路由配置...
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const debouncedNext = debounce(next, 300); // 500毫秒内只能执行一次next函数

  const isAuthenticated = localStorage.getItem('token'); // 检查是否已登录
  const isLoginPage = to.path === '/login';
  const role = Number(localStorage.getItem('role')); // 获取用户角色

  if (isAuthenticated && isLoginPage && from.path !== '/home') {
    debouncedNext('/home'); 
  } else if (!isAuthenticated && to.matched.some(record => record.meta.requiresAuth)) {
    debouncedNext('/login');
  } else if ((to.path === '/admin/admin_users' || to.path === '/excel/export_excel' ) && role < 200) {
    debouncedNext('/unauthorized'); // Redirect to the Unauthorized page
  } else {
    debouncedNext(); 
  }
});

export default router;