<template>
    <div>
        <div class="search-row">
        <el-button type="danger" icon="el-icon-delete" @click="clearSearchResult">清除搜索</el-button>
        <el-button type="primary" icon="el-icon-search" @click="searchUsers">搜索</el-button>
        <el-input v-model="search.user_id" placeholder="用户ID" clearable ></el-input>
        <el-input v-model="search.user_name" placeholder="用户名" clearable></el-input>
        <el-input v-model="search.first_name" placeholder="名" clearable></el-input>
        <el-input v-model="search.last_name" placeholder="姓" clearable></el-input>
        <el-input v-model="search.mobile_phone" placeholder="电话" clearable></el-input>
        </div>
        <div class="table-container">
            <el-table :data="filteredDatatable"
                            :header-cell-style="{background:'#f2f5fc', color:'#555'}" height="70vh" border>
                <el-table-column type="index" label="序号" fixed/>
                <el-table-column prop="user_id" label="用户ID" flex="1">
                    <template slot-scope="scope">
                        {{ scope.row.user_id }}
                    </template>
                </el-table-column>
                <el-table-column prop="open_id" label="小程序用户ID" flex="1">
                    <template slot-scope="scope">
                        {{ scope.row.open_id }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="union_id" label="公众号用户ID" flex="1">
                    <template slot-scope="scope">
                        {{ scope.row.union_id }}
                    </template>
                </el-table-column>                                -->
                <el-table-column prop="user_name" label="用户名" flex="1">
                    <template slot-scope="scope">
                        {{ scope.row.user_name }}
                    </template>
                </el-table-column>
                <el-table-column prop="first_name" label="姓名" flex="1">
                    <template slot-scope="scope">
                        {{ scope.row.first_name }}{{ scope.row.last_name }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="last_name" label="姓" flex="1">
                    <template slot-scope="scope">
                        {{ scope.row.last_name }}
                    </template>
                </el-table-column> -->
                <el-table-column prop="mobile_phone" label="电话" flex="1">
                    <template slot-scope="scope">
                        {{ scope.row.mobile_phone }}
                    </template>
                </el-table-column>
                <el-table-column label="地址" flex="1">
                    <template slot-scope="scope">
                        <el-button size="small" type="success" @click="searchUserAddressById(scope.row.user_id)">查看用户地址</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作" flex="1">
                    <template slot-scope="scope">
                        <el-button size="small" icon="el-icon-edit" type="success" @click="editUser(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        

        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>

        <el-dialog title="查看用户地址" :visible.sync="showUserAddressDialog" :height="'10%'" width="70%">
            <el-table :data="sortedAddresses" border>
                <el-table-column label="地址类型">
                    <template slot-scope="{ row }">
                        {{ row.address_type === 'sender' ? '寄件人' : '收件人' }}
                    </template>
                </el-table-column>
                <el-table-column prop="address_id" label="地址id"></el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="full_address" label="地址"></el-table-column>
                <!-- <el-table-column prop="unit_number" label="单元号"></el-table-column>
                <el-table-column prop="street_number" label="街道号"></el-table-column>
                <el-table-column prop="street_name" label="街道名"></el-table-column>
                <el-table-column prop="suburb" label="郊区"></el-table-column> -->
                <!-- <el-table-column prop="city" label="城市"></el-table-column> -->
                <el-table-column prop="state" label="州"></el-table-column>
                <el-table-column prop="country" label="国家"></el-table-column>
                <el-table-column prop="postcode" label="邮编"></el-table-column>
                <el-table-column prop="updated_at" label="更新时间" flex="1" :formatter="row => (row.updated_at)"></el-table-column>
                <el-table-column prop="created_at" label="创建时间" flex="1" :formatter="row => (row.created_at)"></el-table-column>
                <el-table-column prop="is_default" label="是否默认">
                    <template slot-scope="{ row }">
                        <el-tag v-if="row.is_default" type="success">是</el-tag>
                        <el-tag v-else type="info">否</el-tag>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog title="编辑用户" :visible.sync="showEditDialog" :close-on-click-modal="false">
            <el-form :model="editingUser" :rules="rules" ref="form">
                <el-form-item label="名" prop="first_name">
                    <el-input v-model="editingUser.first_name" disabled></el-input>
                </el-form-item>
                <el-form-item label="姓" prop="last_name">
                    <el-input v-model="editingUser.last_name" disabled></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="mobile_phone">
                    <el-input v-model="editingUser.mobile_phone"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showEditDialog = false">取消</el-button>
                <el-button type="primary" @click="updateUser">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import instance from '@/utils/axios.config';
    import { searchUserAddress } from '../../utils/users';
    import { formatLocalDate } from '@/utils/time_utils'; 

    export default{
        name:"UserHomeTable",
        data() {

            return {
                originalDatatable: [],
                limit: 10,
                page: 1,
                total: 0,
                users: [],
                editingUser: {
                    // first_name: '',
                    // last_name: '',
                    mobile_phone: ''
                },
                search: {
                    user_id: '',
                    user_name: '',
                    first_name: '',
                    last_name: '',
                    mobile_phone: ''
                },
                showEditDialog: false,
                showUserAddressDialog: false,
                userAddresses: [],
                rules: {              
                    // first_name: [
                    //     { required: true, message: '请输入名', trigger: 'blur' },
                    //     { pattern: /^[A-Za-z0-9]+$/, message: '名只能包含字母和数字', trigger: 'blur' }
                    // ],
                    // last_name: [
                    //     { required: true, message: '请输入姓', trigger: 'blur' },
                    //     { pattern: /^[A-Za-z0-9]+$/, message: '姓只能包含字母和数字', trigger: 'blur' }
                    // ],
                    mobile_phone: [
                        { required: true, message: '请输入电话号码', trigger: 'blur' },
                        { pattern: /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[8,9])\d{8}$/, message: '无效的电话号码，正确的格式应为13、14、15、16、17、18、19开头的11位数字', trigger: 'blur' }
                    ]
                }

            };
        },
        computed: {
            filteredDatatable() {
                let result = this.originalDatatable;
                Object.keys(this.search).forEach(key => {
                    if (this.search[key]) {
                        let searchString = this.search[key].replace('*', '.*'); // 将 * 替换为 .*
                        let regex = new RegExp('^' + searchString, 'i'); // 创建正则表达式，'i' 表示不区分大小写
                        result = result.filter(user => regex.test(String(user[key])));
                    }
                });
                return result;
            },
            sortedAddresses() {
                // 创建 userAddresses 的副本
                let addresses = [...this.userAddresses];
                console.log(addresses);

                return addresses.sort((a, b) => {
                    // 把 is_default 为 true 的地址排在前面
                    if (a.is_default && !b.is_default) {
                        return -1;
                    }
                    if (!a.is_default && b.is_default) {
                        return 1;
                    }

                    // 如果两个地址的 is_default 属性相同，那么按照 updated_at 降序排列
                    return new Date(b.updated_at) - new Date(a.updated_at);
                });
            }
        },
        watch: {
            search: {
                handler() {
                    // 检查 search 对象的所有属性是否都为空
                    const isEmpty = Object.values(this.search).every(value => value === '');
                    if (isEmpty) {
                        this.loadData(); // 如果所有属性都为空，调用 getload 方法
                    }
                },
                deep: true // 深度观察 search 对象的所有属性
            }
        },        
    
        methods:{
            formatLocalDate,
            handleSizeChange(val) {
                this.limit = val;
                this.page = 1; // Reset to first page when page size changes
                this.loadData();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.loadData();
            },
            loadData() {
                instance.get(`/users/users/all`, {
                    params: {
                        page: this.page,
                        limit: this.limit
                    }
                }).then(response => {
                    this.originalDatatable = response.data.data; // 将从后端获取的所有用户存储在 originalDatatable 中
                    this.total = response.data.data.length === 0 ? 0 : parseInt(response.data.data[0].number_of_rows);
                }).catch(error => {
                    console.error(error);
                    // 处理令牌过期或其他错误，例如提示用户重新登录
                });
            },

            editUser(row) {
                this.editingUser = Object.assign({}, row);
                this.showEditDialog = true;
            },

            updateUser() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        const user = this.editingUser;

                        instance.post(`/users/update/user_id/${user.user_id}`, {
                            mobile_phone: user.mobile_phone
                        }, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).then(response => {
                            // 处理响应
                            if (response.data.status === 200) {
                                // 更新成功操作
                                this.$message({
                                    message: `更新成功`,
                                    type: 'success'
                                });  
                                this.showEditDialog = false;
                                this.loadData();
                            }
                        }).catch(error => {
                            // 错误处理
                            console.error(error);
                            // 根据error.response.status显示相应的错误信息
                            this.$message({
                                message: `更新失败: ${error.response.data.error}`,
                                type: 'error'
                            });                    
                        });
                    } else {
                        this.$message({
                            message: '请检查输入',
                            type: 'warning'
                        });
                        return false;
                    }
                });
            },
            searchUsers() {
                const searchCopy = Object.assign({}, this.search);

                instance.get(`/users/users`,  {
                    params:{
                    user_id: searchCopy.user_id,
                    user_name: searchCopy.user_name,
                    first_name: searchCopy.first_name,
                    last_name: searchCopy.last_name,
                    mobile_phone: searchCopy.mobile_phone

                }
            })
                .then(response => {
                this.originalDatatable = response.data.data;
                this.total = response.data.data.length === 0 ? 0 : parseInt(response.data.data[0].number_of_rows);
                this.$message.success(`搜索成功, 返回 ${this.total} 条结果`)
                })
                .catch(error => {
                this.$message({
                    message: `搜索失败: ${error.response.data.error}`,
                    type: 'error'
                });
                // 处理错误，例如提示用户重新登录
                this.originalDatatable = [];

                });
            },
            clearSearchResult() {
                this.search = {
                    user_id: '',
                    user_name: '',
                    mobile_phone: '',
                    first_name: '',
                    last_name: ''
                };
                this.page = 1;
                this.loadData();
            },
            async searchUserAddressById(userId){
                // 获取用户地址
                try {
                    const response = await searchUserAddress({user_id: userId});
                    this.$message.success(`获取用户地址成功!`);
                    this.userAddresses = response.data;
                    this.showUserAddressDialog = true;

                } catch (error) {
                    this.$message.error(`获取用户地址失败! ${error.message}`);
                }
           
            },

        },

        
        beforeMount(){
            this.loadData();
        }
    }

</script>

<style scoped>

.search-row {
    display: flex;
    flex-wrap: nowrap; /* 阻止元素换行 */
    align-items: center;
    gap: 10px; /* 为元素添加间隙 */
    margin-bottom: 20px;
    max-width: 100%; /* 限制宽度为屏幕的一半 */
}

.el-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.table-container {
    max-height: 80vh;  /* 你可以根据需要调整这个值 */
    overflow-x: auto; /* 显示横向滚动条 */
    overflow-y: auto; 
}
</style>