<template>
  <div>
    <h1>Orders</h1>
    <div>
      <h2>Pending Orders</h2>
      <ul>
        <li v-for="order in pendingOrders" :key="order.order_id">
          {{ order.order_id }}
        </li>
      </ul>
    </div>
    <div>
      <h2>Paid Orders</h2>
      <ul>
        <li v-for="order in paidOrders" :key="order.order_id">
          {{ order.order_id }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pendingOrders: [],
      paidOrders: []
    };
  },
  created() {
    const eventSource = new EventSource('http://localhost:8000/events');

    eventSource.onmessage = event => {
      const order = JSON.parse(event.data);

      if (order.status === 'pending') {
        this.pendingOrders.push(order);
      } else if (order.status === 'paid') {
        const index = this.pendingOrders.findIndex(o => o.order_id === order.order_id);
        if (index !== -1) {
          this.pendingOrders.splice(index, 1);
          this.paidOrders.push(order);
        }
      }
    };
  }
}
</script>