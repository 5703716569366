import instance from './axios.config';
 

export async function getAllUsers(page, limit, payment_status, order_id = null, user_id = null) {
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('limit', limit);

        if (order_id){
            params.append('order_id', order_id);
        }
        if (user_id){
            params.append('user_id', user_id);
        }

        const response = await instance.get(`/orders/orders/payment_status/${payment_status}`, {
        params
        });

        if (response.status === 200) {
            return response.data.data;
        } 

    } catch (error) {
        throw new Error(error.response.data.error);
    }
}

export async function searchUserAddress({user_id, address_type = null, is_default = null}) {
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        if (address_type && ['sender', 'receiver'].includes(address_type.toLowerCase())){
            params.append('address_type', address_type);
        }
        if (is_default && ['true', 'false'].includes(is_default.toLowerCase())){
            params.append('is_default', is_default);
        }

        const response = await instance.get(`/users/user_id/${user_id}/address`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        throw new Error(error.response.data.error);
    }
}

