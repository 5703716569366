import instance from './axios.config';
  
export async function searchOrders({ order_id = null, alias_order_id = null, user_id = null, order_status = null, payment_status = null, created_from = null, created_to = null, page = null, limit = null }) {
    try {
        // 构建查询参数
        let params = new URLSearchParams();
        if (order_id) params.append('order_id', order_id);
        if (alias_order_id) params.append('alias_order_id', alias_order_id);
        if (user_id) params.append('user_id', user_id);
        if (order_status) params.append('order_status', order_status);
        if (payment_status) params.append('payment_status', payment_status);
        if (created_from) params.append('created_from', created_from);
        if (created_to) params.append('created_to', created_to);
        if (page) params.append('page', page);
        if (limit) params.append('limit', limit);

        const response = await instance.get(`/orders/orders/orders`, {
            params
        });

        
        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        if (error.response.status === 404) {
            throw new Error(`${error.message}: 订单不存在`);
        }
        throw error
    }
}


export async function getOrdersByOrderStatus(page, limit, order_status, is_paid = null) {
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('limit', limit);
        params.append('is_paid', is_paid);
    
        const response = await instance.get(`/orders/orders/order_status/${order_status}`, {
        params
        });

        if (response.status === 200) {
            return response.data.data;
        } 

    } catch (error) {
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }
}

export async function getOrdersByPaymentStatus(page, limit, payment_status, order_id = null, user_id = null) {
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('limit', limit);

        if (order_id){
            params.append('order_id', order_id);
        }
        if (user_id){
            params.append('user_id', user_id);
        }

        const response = await instance.get(`/orders/orders/payment_status/${payment_status}`, {
        params
        });

        if (response.status === 200) {
            return response.data.data;
        } 

    } catch (error) {
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }
}



export async function getOrdersByOrderId(page, limit, orderId) {
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('limit', limit);
        console.log(page, limit)

        const response = await instance.get(`/orders/orders/order_id/${orderId}`, {
        params
        });

        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }
}

export async function getOrderItemsByOrderId({page, limit, orderId}) {
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        if (page) params.append('page', page);
        if (limit) params.append('limit', limit);
        console.log(page, limit, orderId)

        const response = await instance.get(`/orders/order_items/order_id/${orderId}`, {
        params
        });

        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        throw new Error(`${error.message}: ${error.response.data.error}`); 
    }
}


export async function completeOrder(orderId, userId) {
    console.log("completeOrder",orderId, userId)
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        params.append('user_id', userId);

        // 发送请求
        const response = await instance.post(`/orders/complete-order/order_id/${orderId}`, null, {
            params
        });
        // 处理响应
        console.log(response.data.message);

        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error ship order:", error);
        // 错误处理逻辑
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }
}


export async function updateInTransitOrder({orderId, userId, old_status, new_status, alias_order_id}) {
    console.log("updateInTransitOrder",orderId, userId, old_status, new_status)
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        params.append('user_id', userId);
        params.append('alias_order_id', alias_order_id);

        // 发送请求
        const response = await instance.post(`/orders/update-trivial-order-status/order_id/${orderId}/old_status/${old_status}/new_status/${new_status}`, null, {
            params
        });
        // 处理响应
        console.log(response.data.message);

        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error ship order:", error);
        // 错误处理逻辑
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }
}


export async function confirmPayment(orderId, userId) {
    console.log("confirmPayment",orderId, userId)
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        params.append('user_id', userId);

        // 发送请求
        const response = await instance.put(`/payments/payment-succeeded/order_id/${orderId}`, null, {
            params
        });
        // 处理响应
        console.log(response.data.message);

        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error confirming payment:", error);
        // 错误处理逻辑
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }    

}


export async function confirmOrder(orderId, userId) {
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        params.append('user_id', userId);

        // 发送请求
        const response = await instance.post(`/orders/confirm-order/order_id/${orderId}`, null, {
            params
        });
        // 处理响应
        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error confirm order:", error);
        // 错误处理逻辑
        throw new Error(error.response.data.error);
    }
}


export async function deleteOrder(orderId, userId) {
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        params.append('user_id', userId);

        // 发送请求
        const response = await instance.post(`/orders/cancel-order/order_id/${orderId}`, null, {
            params
        });

        // 处理响应
        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error delete order:", error);
        // 错误处理逻辑
        throw new Error(error.response.data.error);
    }
}

export async function packOrder(orderId, userId) {
    console.log("pack-order",orderId, userId)
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        params.append('user_id', userId);

        // 发送请求
        const response = await instance.post(`/orders/pack-order/order_id/${orderId}`, null, {
            params
        });
        // 处理响应
        console.log(response.data.message);

        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error pack order:", error);
        // 错误处理逻辑
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }
}

export async function changeOrderStatus({orderId, userId, aliasOrderId, old_status, new_status}) {
    console.log("changeOrderStatus",orderId, userId, aliasOrderId, old_status, new_status)
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        params.append('user_id', userId);
        params.append('alias_order_id', aliasOrderId)
    
   
        // 发送请求
        const response = await instance.post(`/orders/change-order-status/order_id/${orderId}/old_status/${old_status}/new_status/${new_status}`, null, {
            params
        });
        // 处理响应
        console.log(response.data.message);

        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error ship order:", error);
        // 错误处理逻辑
        throw new Error(`${error.message}: ${error.response.data.error}`);
    }
}


export async function getMostRecentOrderStatusHistory({ order_id = null, user_id = null, order_status = null, limit = null }) {
    console.log("getMostRecentOrderStatusHistory", order_id, user_id, order_status, limit)
    try {
        const params = new URLSearchParams();

        if (order_id!== null) params.append('order_id', order_id);
        if (user_id!== null) params.append('user_id', user_id);
        if (order_status!== null) params.append('order_status', order_status);
        if (limit!== null) params.append('limit', limit);

        // 发送请求
        const response = await instance.get(`/orders/order_status_history/most-recent-status`, {
            params
        });

        // 处理响应
        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error delete order:", error);
        // 错误处理逻辑
        throw new Error(error.response.data.error);
    }
}

export async function getOrderStatusHistoryByOrderId(orderId, order_status = null) {
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        if (order_status){       
            params.append('order_status', order_status);
        }
        // 发送请求
        const response = await instance.get(`/orders/order_status_history/order_id/${orderId}`, null, { 
            params
        });

        // 处理响应
        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error delete order:", error);
        // 错误处理逻辑
        throw new Error(error.response.data.error);
    }
}

export async function getOrderStatusHistoryByUserIdAndOrderId(userId, orderId) {
    try {
      const response = await instance.get(`/orders/order_status_history/user_id/${userId}/order_id/${orderId}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error("Error getting order status history by user ID and order ID:", error);
      throw new Error(error.response.data.error);
    }
  }
  

export async function getPaymentStatusHistoryByOrderId(orderId, payment_status = null, payment_method = null) {
    try {
        // 构建查询参数
        const params = new URLSearchParams();
        if (payment_status){       
            params.append('payment_status', payment_status);
        }
        if (payment_method){       
            params.append('payment_method', payment_method);
        }

        // 发送请求
        const response = await instance.get(`/payments/payment_status_history/order_id/${orderId}`, null, { 
            params
        });

        // 处理响应
        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error delete order:", error);
        // 错误处理逻辑
        throw new Error(error.response.data.error);
    }
}

export function calculatePrice(total_weight, total_volume, shipping_method) {
    // Determine which constants to use
    let volumeWeightRatio;
    let pricePerKg;


    if (shipping_method === process.env.VUE_APP_SHIPPING_METHOD_AIR || shipping_method === process.env.VUE_APP_SHIPPING_METHOD_AIR_SENSITIVE) {
        volumeWeightRatio = process.env.VUE_APP_MEASURE_VOLUME_WEIGHT_RATIO_AIR;
        pricePerKg = shipping_method === process.env.VUE_APP_SHIPPING_METHOD_AIR_SENSITIVE ? process.env.VUE_APP_MEASURE_PRICE_PER_KG_AIR_SENSITIVE: process.env.VUE_APP_MEASURE_PRICE_PER_KG_AIR;
    } else {
        volumeWeightRatio = process.env.VUE_APP_MEASURE_VOLUME_WEIGHT_RATIO_SEA;
        pricePerKg = shipping_method === process.env.VUE_APP_SHIPPING_METHOD_SEA_SENSITIVE ? process.env.VUE_APP_MEASURE_PRICE_PER_KG_SEA_SENSITIVE : process.env.VUE_APP_MEASURE_PRICE_PER_KG_SEA;
    }

    // Calculate volume weight
    let volumeWeight = parseFloat((total_volume / volumeWeightRatio).toFixed(3));
    console.log("volumeWeightRatio", volumeWeightRatio)
    console.log("pricePerKg", pricePerKg)
    // Determine which weight to use
    let weightToUse = Math.max(total_weight, volumeWeight);
    console.log("weightToUse", weightToUse)
    // Calculate the price
    let price = weightToUse * pricePerKg;
    console.log("price", price.toFixed(0))
    return parseFloat(price.toFixed(0));
}


// 客服创建新订单
export async function createNewOrder(orderObject) {
    try {
        // 发送请求
        const response = await instance.post(`/orders/create-order`, orderObject, {
        });

        // 处理响应
        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error("Error create order:", error);
        // 错误处理逻辑
        throw new Error(error.response.data.error);
    }

}

export function concateOrderAddress (address_info) {
    // // 获取国家
    // const country = address_info.country;
    // // 如果是中国地址
    // if (country === 'China') {
    //     return `${address_info.state}, ${address_info.city}, ${address_info.suburb}, ${address_info.street_name}, ${address_info.street_number}, ${address_info.unit_number}, ${address_info.country}`;
    // }
    // // 如果其他国家地址
    // return address_info.unit_number 
    //     ? `${address_info.unit_number}/${address_info.street_number} ${address_info.street_name}, ${address_info.suburb}, ${address_info.state}, ${address_info.postcode}, ${address_info.country}`
    //     : `${address_info.street_number} ${address_info.street_name}, ${address_info.suburb}, ${address_info.state}, ${address_info.postcode}, ${address_info.country}`;

    console.log(`${address_info.address}, ${address_info.state}, ${address_info.postcode}, ${address_info.country}`)
    return `${address_info.address}, ${address_info.state}, ${address_info.postcode}, ${address_info.country}`;
}

export async function getOrderPdf(orderId) {

    try {
        // 发送请求
        const response = await instance.get(`/orders/invoice/order_id/${orderId}`);

        // 处理响应
        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        console.error(`Error rendering PDF for order ${orderId}:`, error);
        // 错误处理逻辑
        throw new Error(error.response.data.error);
    }


}