<template>
    <div>
        <h1>订单历史</h1>
        <el-table v-if="orderHistory" :data="orderHistory" style="width: 100%" border>
            <el-table-column prop="index" label="步骤" width="180">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column prop="record_id" label="记录ID" width="180"></el-table-column>
            <el-table-column prop="order_id" label="订单ID" width="180"></el-table-column>
            <el-table-column prop="user_id" label="用户ID" width="180"></el-table-column>
            <el-table-column prop="order_status" label="订单状态" width="180"></el-table-column>
            <el-table-column prop="updated_at" label="更新时间" width="180"></el-table-column>
        </el-table>
        <div v-else>
            <p>加载中...</p>
        </div>
    </div>
</template>

<style scoped>
.order-history {
    list-style-type: none;
    padding: 0;
}

.order-history li {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.step-number {
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
}

.order-details p {
    margin: 0;
}
</style>

<script>

import instance from '@/utils/axios.config';

export default {
    name: 'OrderHistory',
    data() {
        return {
            orderHistory: null,
        };
    },
    async created() {
        const orderId = this.$route.query.order_id;
        // Fetch the order history from your backend API
        const response = await instance.get(`/orders/qrcode/order-history?order_id=${orderId}`);
        this.orderHistory = response.data.data;
    },
};
</script>