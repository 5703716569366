<template>
  <div class="successBody">
    <div class="successDiv">
      <div class="success-content">
        <el-icon class="icon" name="success"></el-icon>
        <h1>操作成功</h1>
        <p>您将在 {{ countdown }} 秒后被重定向到登录页面。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultSuccess",
  data() {
    return {
      countdown: 5,
    };
  },
  mounted() {
    const intervalId = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(intervalId);
        this.$router.push('/login');
      }
    }, 1000);
  },
};
</script>

<style scoped>
.successBody {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #B3C0D1;
}
.successDiv {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-top: -200px;
  margin-left: -250px;
  width: 450px;
  height: 600px;
  background: #fff;
  border-radius: 5%;
}
.success-content {
  width: 400px;
  height: 250px;
  position: absolute;
  top: 25px;
  left: 25px;
  text-align: center;
}
.icon {
  color: green;
  font-size: 50px;
}
</style>