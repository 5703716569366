<template>
    <div style = "display: flex; line-height: 60px;">

    <div style = "margin-top: 8px;">
        <i :class="icon" style="font-size: 25px;cursor: pointer;" @click = "collapse"></i>
    </div>
    
    <div style="flex: 1;text-align: center;font-size: 34px;">
        <span> {{ pageTitle }}</span>
    </div>
    <el-dropdown style="cursor: pointer;" trigger="click">
    <span class="dropdown-link">
        {{ username }}<i class="el-icon-arrow-down" style="margin-left: 5px"></i>
    </span>
    <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="toUser">个人中心</el-dropdown-item>
        <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
    </el-dropdown-menu>
</el-dropdown>
    </div>
</template>

<script>
    import { logOut } from '../utils/auth';

    export default{
        name:"MainHeader",

        props:{
            icon:String,
            username: String
        },
        methods:{
            toUser() {
                this.$router.push('/home');
            },
            async logout(){
                // 向服务器发送注销请求
                try {
                    const response = await logOut();
                    if (response.status === 200) {
                        this.$message({
                            type: 'success',
                            message: '注销成功'
                        });
                    }
                } catch (error) {
                    this.$message({
                            type: 'error',
                            message: '登陆过期, 自动注销'
                        });
             
                }
                // 清除localStorage中的认证信息
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('user_name');
                localStorage.removeItem('role');
                
                // 重定向到登录页面
                this.$router.push('/login');
            },
            collapse(){
                this.$emit('doCollapse')
            }
        },
        computed: {
            pageTitle() {
                if (this.$route.path.startsWith('/orders/order_status/')) {
                    return this.$route.params.order_status + '订单';
                } 
                if (this.$route.path.startsWith('/items/')) {
                    if (this.$route.params.items_table === 'scanned-items') {
                        return '已入库物品';
                    } else {
                        return '用户申报物品';
                    }
                }  
                if (this.$route.path.startsWith('/admin/admin_users')){
                    return '管理员列表'
                }
                if (this.$route.path.startsWith('/user/users')){
                    return '小程序用户列表'
                }
                if (this.$route.path.startsWith('/orders/order_status_history')){
                    return '订单状态历史'
                }
                if (this.$route.path.startsWith('/excel/export_excel')) {
                    return '导出Excel';
                }
                if (this.$route.path.startsWith('/coupons')) {
                    return '编辑优惠券';
                }
                if (this.$route.path.startsWith('/docs')) {
                    return '编辑公告';
                }
                if (this.$route.path.startsWith('/images')) {
                    return '编辑/上传图片';
                }
                else {
                    return '欢迎来到仓库管理系统';
                }
            },
        },
    }

</script>

<style scoped>

</style>