import instance from '../axios.config';

export async function getCollections({collection_name, queryParams = null, limit = null, page = null}) {
    console.log(limit, page, queryParams, collection_name)
    try {
        // 构建查询参数
        const params = new URLSearchParams();

        if (page) {
            params.append('page', page);
        }
        if (limit) {
            params.append('limit', limit);
        }

        if (queryParams) {
            for (const [key, value] of Object.entries(queryParams)) {
                params.append(key, value);
            }
        }

        const response = await instance.get(`/documents/query/collections/${collection_name}`, {
            params
        });

        if (response.status === 200) {
            return response.data;
        } 

    } catch (error) {
        if (error.response.status === 404) {
            throw new Error(`${error.message}: 订单不存在`);
        }
        throw error
    }
}

export async function insertRecord({collection_name, _id, new_record}) {
    console.log(collection_name, _id, JSON.stringify(new_record))
    try {
        if (!collection_name) {
            throw new Error('缺少参数collection_name');
        }

        if (!new_record) {
            throw new Error('缺少请求体new_record');
        }

        const response = await instance.post(`/documents/insert/collections/${collection_name}`, new_record);

        if (response.status === 200) {
            return response;
        } 

    } catch (error) {
        if (error.response.status === 404) {
            throw new Error(`${error.message}: 插入新文档失败`);
        }
        throw error
    }

}


export async function updateRecord({collection_name, _id, new_record}) {
    console.log(collection_name, _id, JSON.stringify(new_record))
    try {
        // 构建查询参数
        const params = new URLSearchParams();

        if (!_id) {
            throw new Error('缺少参数_id');  
        }
        if (!new_record) {
            throw new Error('缺少请求体new_record');
        }

        params.append('_id', _id);

        const response = await instance.post(`/documents/update/collections/${collection_name}`, new_record, {
            params
        });

        if (response.status === 200) {
            return response;
        } 

    } catch (error) {
        if (error.response.status === 404) {
            throw new Error(`${error.message}: 文档${_id}不存在`);
        }
        throw error
    }

}

export async function deleteRecord({collection_name, _id, key = null}) {

    try {
        // 构建查询参数
        const params = new URLSearchParams();

        if (!_id) {
            throw new Error('缺少参数_id');  
        }

        params.append('_id', _id);

        if (collection_name !== process.env.VUE_APP_COLLECTION_NAME_DOCS) {
            if (!key) {
                throw new Error('当前所对应的文件类型缺少参数key (COS路径), 无法删除');
            }
            params.append('key', key);
        }

        const response = await instance.delete(`/documents/delete/collections/${collection_name}`, {
            params
        });

        if (response.status === 200) {
            return response;
        } 

    } catch (error) {
        if (error.response.status === 404) {
            throw new Error(`${error.message}: 文档${_id}不存在`);
        }
        throw error
    }

}