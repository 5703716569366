<template>
    <div class="forgetPasswordBody">
      <div class="forgetPasswordDiv">
        <div class="forgetPassword-content">
          <h1 class="forgetPassword-title">忘记密码</h1>
          <el-form :model="forgotPasswordForm" ref="forgotPasswordForm" label-width="100px" :rules="rules">
            <el-form-item label="用户名" prop="user_name">
            <el-input v-model="forgotPasswordForm.user_name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
              <el-input v-model="forgotPasswordForm.email" autocomplete="off"></el-input>
          </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForgotPassword">提交</el-button>
              <el-button type="text" @click="goBack">返回</el-button> <!-- Add this line -->
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import axios from 'axios';
import instance from '@/utils/axios.config';
  
  export default {
    name: "ForgetPassword",
    data() {
      return {
        forgotPasswordForm: {
          user_name: 'admin',
          email: 'admin3@example.com'
        },
        rules: {
                user_name: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                email: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur'] }
                ]
        }
      };
    },
    methods: {
      goBack() {
            this.$router.push('/login');
      },
      async submitForgotPassword() {
        this.$refs.forgotPasswordForm.validate(async (valid) => {
          if (!valid) {
            this.$message.error('请检查您的输入');
            return;
          }
          try {
            // 复制一份数据，防止修改原数据
            const inputForm = Object.assign({}, this.forgotPasswordForm);
            console.log(inputForm);
            await instance.post(`/auth/forget-password`, inputForm, {
              headers: {
                'Content-Type': 'application/json'
              }
            });
            this.$message.success('重置密码的链接已发送到您的邮箱');
          } catch (error) {
            if (error.response && error.response.status === 404) {
              this.$message.error(`用户不存在: ${error.response.data.error}`);
              return;
            }
            this.$message.error(`发送失败: ${error.response.data.error}` );
          }
        });
      }
    }
  };
  </script>
  
  <style scoped>
    .forgetPasswordBody {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #B3C0D1;
    }
    .forgetPasswordDiv {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -200px;
        margin-left: -250px;
        width: 450px;
        height: 330px;
        background: #fff;
        border-radius: 5%;
    }
    .forgetPassword-title {
        margin: 20px 0;
        text-align: center;
    }
    .forgetPassword-content {
        width: 400px;
        height: 250px;
        position: absolute;
        top: 25px;
        left: 25px;
    }
</style>